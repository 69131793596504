import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";

import ResourceApi from "../../services/resource";

import { useXMLTemplate } from "../../context-providers/XMLTemplate";
import { ExaminationContext } from "../../context-providers/Examination";

import {
  useXMLDocumentPrinter,
  XMLDocument,
} from "../../components/XMLDocument";

function buildMultipartForm(html, json) {
  // Build a form with the HTML content as blob
  const formData = new FormData();
  const htmlBlob = new Blob([html], { type: "text/html" });
  formData.append("html", htmlBlob);
  if (json) {
    const jsonBlob = new Blob([JSON.stringify(json)], { type: "text/html" });
    formData.append("json", jsonBlob);
  }

  return formData;
}

function blueprintKey(documentType) {
  switch (documentType) {
    case "report":
      return "report_template";
    case "medical_history":
      return "medical_history_template";
    default:
      throw new Error(`Unknown document type: ${documentType}`);
  }
}

export default function ReportWeb() {
  const { examId } = useParams();
  const location = useLocation();
  const documentType =
    new URLSearchParams(location.search).get("document_type") || "report";
  const examinationContext = useContext(ExaminationContext);
  const XMLPrinter = useXMLDocumentPrinter({page: documentType});
  const { reportDataOptions, placeholders, stable, loaded } = useXMLTemplate();
  const [placeholderStabilityTimeout, setPlaceholderStabilityTimeout] =
    useState(1);
  const [base64Images, setBase64Images] = useState([]);
  const reportContentRef = useRef(null);

  const templateBlueprint =
    examinationContext.debugTemplate ||
    reportDataOptions?.[blueprintKey(documentType)]?.blueprint;
  const renderReportHtml = useCallback(
    async () =>
      await XMLPrinter({
        base64Images: [],
        templateBlueprint,
      }),
    [templateBlueprint]
  );

  useEffect(() => {
    /* Load placeholders for report HTML */
    renderReportHtml();
  }, [renderReportHtml]);

  useEffect(() => {
    setPlaceholderStabilityTimeout(
      setTimeout(() => setPlaceholderStabilityTimeout(null), 1000)
    );
    return () => clearTimeout(placeholderStabilityTimeout);
  }, [placeholders, setPlaceholderStabilityTimeout]);

  useEffect(() => {
    examinationContext.loadExamination(examId);
  }, [examId]);

  useEffect(() => {
    if (placeholderStabilityTimeout !== null) return;
    if (stable === false) return;
    if (loaded === false) return;
    document.body.setAttribute("ready-to-print", "true");
  }, [!!placeholderStabilityTimeout, stable]);

  const writeEditReportHtml = async () => {
    /*
     * Fetching the XMLDocument content.
     * There might be better way of doing it but for the moment it is the smoother I can think of
     */
    const HTML = reportContentRef.current.getElementsByClassName(
      "exam-report-container"
    )[0].outerHTML;
    return ResourceApi.writeDocumentHtml(
      examinationContext.examination.id,
      "edit",
      documentType,
      buildMultipartForm(HTML)
    );
  };

  const writePrintReportHtml = async () => {
    const HTML = await renderReportHtml();
    return ResourceApi.writeDocumentHtml(
      examinationContext.examination.id,
      "print",
      documentType,
      buildMultipartForm(HTML)
    );
  };

  const writeDocumentHtml = async () => {
    const resPrint = await writePrintReportHtml();
    const resEdit = await writeEditReportHtml();
    return [resEdit, resPrint];
  };

  window.writeDocumentHtml = writeDocumentHtml;

  return (
    <div ref={reportContentRef} className="exam-report-content">
      <XMLDocument
        templateBlueprint={templateBlueprint}
        page={documentType}
        showErrors={false}
        submitClicked={false}
        reportMode="edit"
        base64Images={base64Images}
        setBase64Images={setBase64Images}
      />
    </div>
  );
}
