import { renderToStaticMarkup } from "react-dom/server";
import ReportTemplateHeading from "../Heading";
import ReportTemplateBlock from "../Block";
import ReportTemplateSpacer from "../Spacer";

function ReportTemplateGraphs({
  props,
  children,
  reportMode = "print",
  apiVersion,
}) {
  const columnNumber = props.columns || 1;

  const groupedChildren = children
    .filter((child) => {
      const staticChild = renderToStaticMarkup(child);
      return (
        child?.props?.type?.startsWith("measurement-curve") &&
        !!staticChild &&
        !staticChild.includes("measurement-curve empty") &&
        (!staticChild.includes("measurement-curve not-visible") ||
          reportMode === "edit")
      );
    })
    .reduce((grouped, child, index) => {
      const groupedIndex = Math.floor(index / columnNumber);
      grouped[groupedIndex] = grouped[groupedIndex] || [];
      grouped[groupedIndex].push(child);
      return grouped;
    }, []);

  const isEmpty = groupedChildren.length === 0;

  return isEmpty ? (
    false
  ) : (
    <div className={`graphs-wrapper ${isEmpty ? "is-empty" : ""}`}>
      <div className="avoid-break">
        {props.label && (
          <>
            <ReportTemplateHeading props={{}} apiVersion={apiVersion}>
              {props.label}
            </ReportTemplateHeading>
            <ReportTemplateSpacer size="s" />
          </>
        )}
        {!!groupedChildren[0].length && (
          <ReportTemplateGraphsLine
            key={`graph-line-0`}
            columns={columnNumber}
            apiVersion={apiVersion}
          >
            {groupedChildren[0]}
          </ReportTemplateGraphsLine>
        )}
      </div>
      {groupedChildren.slice(1).map((line, index) => {
        return (
          <ReportTemplateGraphsLine
            key={`graph-line-${index}`}
            columns={columnNumber}
            apiVersion={apiVersion}
          >
            {line}
          </ReportTemplateGraphsLine>
        );
      })}
    </div>
  );
}
export default ReportTemplateGraphs;

function ReportTemplateGraphsLine({ children, columns, apiVersion }) {
  return (
    <div className="graphs-line">
      <ReportTemplateBlock
        props={{ layout: "columns" }}
        apiVersion={apiVersion}
      >
        {children}
        {Array.from("0".repeat(columns - children.length)).map((_, index) => (
          <div key={`empty-${index}`} />
        ))}
      </ReportTemplateBlock>
    </div>
  );
}
