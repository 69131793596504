import { computeBMI } from "../../services/examination";

/*
 * @description Insert the BMI in the medical history when applicable
 * @param {Object} medicalHistory: the current medicalHistory
 * @param {Object} examinationContext
 * @returns {Object} examinationContext with BMI
 */
export function insertBMIinMedicalHistory(
  medicalHistory,
  examinationContext,
  currentLanguage
) {
  let mh = structuredClone(medicalHistory);
  const weight =
    examinationContext.examination.medical_history?.[
      "medicalexam.mother.weight"
    ]?.value;
  const height =
    examinationContext.examination.medical_history?.[
      "medicalexam.mother.height"
    ]?.value;
  if (weight && height) {
    const bmiValue = computeBMI(weight, height).toFixed(2);
    if (mh.some((m) => m.text_id === "medicalexam.mother.bmi")) {
      mh = mh.map((m) => ({
        ...m,
        raw_value:
          m.text_id === "medicalexam.mother.bmi" && !m.raw_value
            ? bmiValue
            : m.raw_value,
      }));
    } else {
      const weightindex = mh.findIndex(
        (m) => m.text_id === "medicalexam.mother.weight"
      );
      mh = [
        ...mh.slice(0, weightindex + 1),
        {
          label:
            examinationContext.medicalHistoryItems?.["medicalexam.mother.bmi"]
              ?.label[currentLanguage],
          text_id: "medicalexam.mother.bmi",
          raw_value: computeBMI(weight, height).toFixed(2),
          // TODO: add is_risky value
        },
        ...mh.slice(weightindex + 1),
      ];
    }
  }
  return mh;
}

/*
 * @description Sort the medical history items
 * Try to sort the medical history items by the order key,
 * if not available sort by text_id to group by categories
 * @param {Array} medicalHistory: the current medicalHistory
 * @returns {Array} sorted medicalHistory
 */
export function sortMedicalHistoryItems(medicalHistory) {
  const mh = structuredClone(medicalHistory);
  return mh.sort((a, b) => {
    const aOrder = a.order || 0;
    const bOrder = b.order || 0;
    const diff = aOrder - bOrder;
    if (diff === 0) {
      return a.text_id.localeCompare(b.text_id);
    }
    return diff;
  });
}
