import React, { isValidElement, useEffect } from "react";
import { withTranslation } from "react-i18next";
/* Config */
import { ChecklistItemStatus } from "../../../../config";
/* Atoms */
import Icon from "../../../../atoms/Icon/Icon";
import ReportTemplateBlock from "../Block";
import ReportTemplateLabel from "../Label";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const ReportTemplateChecklist = ({
  t: __,
  props,
  children,
  checklistItems = [],
  groups = [],
  fetus = 0,
  getPlaceholderWithProps,
  checkCondition,
  setEditChecklistDialogIsOpen = () => { },
  updateComponentChecklistAssoc = () => { },
  updateAutogeneratedChecklistComments = () => { },
  apiVersion
}) => {
  const componentUID = props.id || "fetal_anatomy";

  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const checklistItemsOfFetus = checklistItems.map((items) => items[fetus]).filter(item => !!item);
  const allowedSlugs = [
    ...props.items?.split("|").map(slug => `${slug}`.trim() || "").filter(slug => !!slug),
    ...(componentUID === "fetal_anatomy" ? checklistItemsOfFetus.filter(item => !item.active).map(item => item.slug) : []), // add items coming from optional views only to the fetal anatomy checklist
  ];

  const normalizeStatus = (status) => {
    if (!status) return "";
    if (ChecklistItemStatus[status]) return status;
    return Object.entries(ChecklistItemStatus[status] || {}).find(([key, value]) => value.synonyms?.includes(status))?.[0];
  }

  const groupElements = Array.isArray(children) ? children.filter(child => isValidElement(child) && child.props?.type === "group") : [];

  const itemsToDisplay = checklistItemsOfFetus
    .filter(item => item && item.visible && allowedSlugs.includes(item.slug))
    .sort((a, b) => {
      if (a.order && b.order) return a.order - b.order;
      return a.label[currentLanguage] < b.label[currentLanguage] ? -1 : 1
    });

  /* Remove assoc only if the component is unmounted */
  useEffect(() => {
    return () => {
      updateComponentChecklistAssoc(componentUID, []);
    }
  }, []);

  /* Update the assoc between the current component and the checklist items in the XML context
   *  - We take the allowedSlugs list and we verify all provided slugs are corresponding to existing checklist items
   */
  const checklistAssoc = checklistItemsOfFetus.filter(item => item && allowedSlugs.includes(item.slug)).map(item => item.slug).sort();
  useEffect(() => {
    updateComponentChecklistAssoc(componentUID, checklistAssoc);
  }, [componentUID, checklistAssoc.join("|")]);


  const groupCommentsSlugPrefix = `checklist.${componentUID === "fetal_anatomy" ? "" : componentUID + "." // for backward compatibility we keep fetal_anatomy empty
    }group.`;

  const updateAutogeneratedItemComments = () => {
    let comments = [];
    if (!!children && Array.isArray(children)) {
      children.filter(child => child?.props?.type === "if").forEach(child => {
        const matchesConditions = checkCondition({ ...child?.props }, fetus, getPlaceholderWithProps);
        if (matchesConditions) {
          const valueElements = Array.isArray(child?.props?.children) ? child?.props?.children?.filter(child => child?.props?.type === "value") : [];
          comments = [...comments, ...valueElements.map(value => ({
            data: value.props.data,
            attribute: value.props.attribute,
            content: value.props.children
          }))];
        }
      });
    }
    updateAutogeneratedChecklistComments(fetus, componentUID, comments);
  }

  useEffect(() => {
    updateAutogeneratedItemComments();
  }, [checklistItemsOfFetus]);

  return (!!itemsToDisplay.length &&
    <div className="checklist checklist_v2">
      {groupElements.map((group, index) => {
        const groupComments = Object.fromEntries(groups.map(g => [g.slug, getPlaceholderWithProps({
          data: `${groupCommentsSlugPrefix}${g.slug}.${normalizeStatus(group.props.status ?? group.props.props.status)}`
        })?.[fetus]?.comment]));

        return (
          <ReportTemplateChecklistGroup
            key={group.props.status || group.label || group.props.label || index}
            props={{ ...group.props, status: normalizeStatus(group.props.status ?? group.props.props.status) }}
            componentUID={componentUID}
            fetus={fetus}
            items={itemsToDisplay.filter(item => item.status === normalizeStatus(group.props.status ?? group.props.props.status))}
            allowedSlugs={allowedSlugs}
            groups={groups}
            groupComments={groupComments}
            setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
            apiVersion={apiVersion}
          />
        );
      })}
    </div>
  );
}

const ReportTemplateChecklistGroup = withTranslation()(({
  t: __,
  i18n: { language: currentLanguage },
  props,
  componentUID,
  fetus,
  items,
  allowedSlugs,
  groups,
  groupComments,
  setEditChecklistDialogIsOpen,
  apiVersion
}) => {
  const hasNotes = groups.some(group => !!groupComments[group.slug]);
  const printable = !!items.filter(item => item.status !== "not_applicable").length || !!groupComments.length || hasNotes;

  return <div className={`checklist_group ${printable ? "" : "not-printable"}`}>
    <label>{props.label || props.props.label}</label>
    {groups.map(group => {
      const groupItems = items.filter(onlyUnique).filter(item => item.group_id === group.id);
      const hasGroupNotes = !!groupComments[group.slug];
      return (!groupItems.length && !hasGroupNotes) ? false : (
        <div key={group.id} onClick={() => setEditChecklistDialogIsOpen({ componentUID, slug: group.slug, allowedSlugs, fetus })}>
          <ReportTemplateBlock props={{ layout: "columns", "auto-hide": "true" }} apiVersion={apiVersion}>
            <ReportTemplateBlock props={{ width: "20%" }} apiVersion={apiVersion}>
              <ReportTemplateLabel props={{}} apiVersion={apiVersion}>
                {group.name}
              </ReportTemplateLabel>
            </ReportTemplateBlock>
            <ReportTemplateBlock apiVersion={apiVersion}>
              {/* Here we intersperse items with "," so we need to create a bigger array */}
              {
                !!groupItems.length && [...new Array((groupItems.length - 1) * 2 + 1)].map((_, index) => {
                  /* if we are on a odd index it means we are on the intersperser */
                  const item = groupItems[Math.floor(index / 2)];
                  if (index % 2 === 1)
                    return <React.Fragment key={'checklist_item_comma_' + item.id}>,&nbsp; </React.Fragment>;

                  return <span
                    key={'checklist_item_' + item.id}
                    className="checklist_item has-printable-value"
                  >
                    <span style={{ whiteSpace: "pre" }}>{item.label?.[currentLanguage]}</span>
                    {!!item.comment && <span className="checklist_comment">({item.comment})</span>}
                    {item.children?.length && (
                      <span className="children">
                        {item.children.map(child =>
                          <span key={child.id}>
                            <Icon name="right" />
                            {child.label?.[currentLanguage]}
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                })
              }
              {hasGroupNotes && <div className="has-printable-value">{groupComments[group.slug]}</div>}
            </ReportTemplateBlock>
          </ReportTemplateBlock>
        </div>
      );
    })}
    {!items.length && !hasNotes && (
      <div className="nothing-to-display" onClick={() => setEditChecklistDialogIsOpen({ componentUID, allowedSlugs, fetus })}>
        <ReportTemplateBlock props={{ layout: "columns", "auto-hide": "true" }}>
          <ReportTemplateBlock props={{ width: "20%" }}>
          </ReportTemplateBlock>
          <ReportTemplateBlock>
            {__("checklistItems.nothingToDisplay")}
          </ReportTemplateBlock>
        </ReportTemplateBlock>
      </div>
    )}
  </div>
});

export default withTranslation()(ReportTemplateChecklist);
