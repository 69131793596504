import React from "react";
import LoginView from "./LoginView";

const Login = () => {
  return (
    <div data-theme="dark" className={`theme-dark`}>
      <LoginView />
    </div>
  );
};

export default Login;
