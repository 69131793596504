import { useContext, useState, useEffect, useMemo } from "react";

import { withTranslation } from "react-i18next";

import ButtonBack from "../../atoms/ButtonBack/ButtonBack";

import { ExaminationContext } from "../../context-providers/Examination";
import { MeasurementsContext } from "../../context-providers/Measurements";

import TextInput from "../../atoms/TextInput/TextInput";
import NumericInput from "../../atoms/NumericInput/NumericInput";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import Tabs from "../../atoms/Tabs/Tabs";
import Button from "../../atoms/Button/Button";
import NotVisibleOverlay from "../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

import { convertValueToSelectedUnit } from "../../unitConverter";
import { getNiceGestionalAgeFromDays } from "../../services/examination";
import { getPercentileValueString } from "../../services/measurements";

import "./MeasurementsDialog.css";
import { isNullOrUndefined } from "../../utils";

const EDIT = "edit";
const PATIENT = "patient";

const getDefaultFromManageMeasurementsIsOpen = (
  manageMeasurementsIsOpen,
  measurementsContext
) => {
  let defaultFetus = 1;
  let defaultTab, defaultSubtab;

  if (Array.isArray(manageMeasurementsIsOpen)) {
    defaultFetus = manageMeasurementsIsOpen[1];
    const group =
      measurementsContext.grouping.find(
        (group) => group.measurement_slug === manageMeasurementsIsOpen[0]
      ) || {};
    defaultTab = group.section;
    defaultSubtab = group.subsection;
  }

  return {
    defaultFetus,
    defaultTab,
    defaultSubtab,
  };
};

const Measurements = ({
  t: __,
  setManageMeasurementsIsOpen,
  manageMeasurementsIsOpen,
}) => {
  const onClickCancel = () => {
    setManageMeasurementsIsOpen(false);
  };

  const examinationContext = useContext(ExaminationContext);
  const measurementsContext = useContext(MeasurementsContext);
  const { defaultFetus, defaultTab, defaultSubtab } =
    getDefaultFromManageMeasurementsIsOpen(
      manageMeasurementsIsOpen,
      measurementsContext
    );

  const numberOfFetuses = examinationContext.examination.nb_fetus;

  const fetusDropdownOptions = useMemo(
    () =>
      Array(examinationContext.examination.nb_fetus)
        .fill()
        .map((_, idx) => ({ label: idx + 1, value: idx + 1 })),
    [numberOfFetuses]
  );

  const updateMeasurement = (measurementId, identifier) => (data) => {
    data.fetus = identifier ? "identifier" : currentFetus;
    data.measurement_id = measurementId;
    measurementsContext.updateMeasurements(data);
  };

  const tabsOptions = Object.keys(measurementsContext.formattedGrouping)
    ?.map((groupSlug) =>
      measurementsContext.findMeasurementGroupLabel(groupSlug)
    )
    ?.sort((a, b) => a.order - b.order);

  const [currentTab, setCurrentTab] = useState(
    defaultTab ?? tabsOptions[0]?.value
  );
  const [currentSubTab, setCurrentSubTab] = useState("");
  const subTabsOptions = useMemo(() => {
    return Object.keys(
      measurementsContext?.formattedGrouping?.[currentTab] || {}
    )
      ?.map((groupSlug) =>
        measurementsContext.findMeasurementGroupLabel(groupSlug)
      )
      ?.sort((a, b) => a.order - b.order);
  }, [currentTab]);

  useEffect(() => {
    setCurrentSubTab(defaultSubtab ?? subTabsOptions[0]?.value);
  }, [currentTab, subTabsOptions]);

  const [currentFetus, setCurrentFetus] = useState(defaultFetus);

  useEffect(() => {
    setCurrentFetus(
      measurementsContext.findMeasurementGroupLabel(currentTab).fetal
        ? currentFetus === PATIENT
          ? 1
          : currentFetus
        : PATIENT
    );
  }, [currentTab]);

  const onChangeTab = (newTab) => {
    setCurrentTab(newTab);
  };

  const measurementsToDisplay = useMemo(() => {
    // Flatten sub-sub-sections into an array of slugs and titles
    const subGroup =
      measurementsContext.formattedGrouping?.[currentTab]?.[currentSubTab];
    if (!subGroup) return [];
    if (Object.prototype.hasOwnProperty.call(subGroup, "_measurements")) {
      const measurements = subGroup._measurements.map(
        ({ slug, identifier }) => ({ type: "measurement", slug, identifier })
      );
      return measurements;
    } else {
      const measurements = [];
      const titles = Object.keys(subGroup || {})
        .map(measurementsContext.findMeasurementGroupLabel)
        ?.sort((a, b) => a.order - b.order);
      for (const { label, slug: titleSlug } of titles) {
        measurements.push({ type: "title", title: label });
        for (const { slug, identifier } of subGroup?.[titleSlug]
          ?._measurements) {
          measurements.push({ type: "measurement", slug, identifier });
        }
      }
      return measurements;
    }
  }, [currentSubTab]);

  const isAssignedDatingSet = !!examinationContext?.examination?.dating?.value;

  return (
    <>
      <div className="modal-background" onClick={onClickCancel} />
      <div className="exam-report-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          <ButtonBack onClick={onClickCancel} />
          <div className="exam-report-dialog-header_title">
            <h2>{__("measurements.manageMeasurements")}</h2>
            <Tabs
              options={tabsOptions}
              value={currentTab}
              onChange={onChangeTab}
            />
          </div>

          <ButtonBack icon="close" onClick={onClickCancel} />
        </div>
        <div className="exam-report-dialog-body column-direction">
          <div className="measurements-management">
            <div className="measurements-header">
              {currentFetus !== PATIENT && numberOfFetuses > 1 && (
                <div className="fetus-selection">
                  <h3>{__("measurements.fetusSelection")}:</h3>
                  <div>
                    <SelectInput
                      value={currentFetus}
                      options={fetusDropdownOptions}
                      onChange={setCurrentFetus}
                    />
                  </div>
                </div>
              )}
              <div className="subtabs-wrapper">
                <h6>{__("measurements.selectGroup")}:</h6>
                <Tabs
                  options={subTabsOptions}
                  value={currentSubTab}
                  onChange={setCurrentSubTab}
                />
              </div>
            </div>
            {!isAssignedDatingSet && (
              <div className="measurements-select-dating">
                {__("measurements.askGASelection")}
              </div>
            )}
            <div className="measurements-table">
              <div className="measurements-row column-heading">
                <div>{__("measurements.measurement")}</div>
                <div>{__("measurements.value")}</div>
                <div>{__("measurements.unit")}</div>
                <div>{__("measurements.derivation")}</div>
                <div>{__("measurements.percentile")}</div>
                <div>{__("measurements.zscore")}</div>
                <div>{__("measurements.author")}</div>
                <div>{__("measurements.gestationalAge")}</div>
                <div />
              </div>

              {measurementsToDisplay.map((measurementToDisplay, idx) => {
                if (measurementToDisplay.type === "title") {
                  return (
                    <h4 className="measurements-row inline-title" key={idx}>
                      {measurementToDisplay.title}
                    </h4>
                  );
                }

                const currentData = measurementToDisplay.identifier
                  ? measurementsContext.getIdentifierMeasurementObject(
                      measurementToDisplay.slug,
                      measurementToDisplay.identifier
                    )
                  : measurementsContext.getMeasurementObject(
                      measurementToDisplay.slug,
                      currentFetus
                    );

                return (
                  <MeasurementRow
                    fetus={currentFetus}
                    measurement={measurementToDisplay}
                    identifier={measurementToDisplay.identifier}
                    currentData={currentData}
                    updateMeasurement={updateMeasurement(
                      currentData.identifierSlug ?? measurementToDisplay.slug,
                      measurementToDisplay.identifier
                    )}
                    measurementsContext={measurementsContext}
                    key={`${measurementToDisplay.slug}-${measurementToDisplay.identifier}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Measurements);

const MeasurementRow = withTranslation()(
  ({
    t: __,
    fetus,
    currentData,
    measurement,
    identifier,
    updateMeasurement,
    measurementsContext,
  }) => {
    const [currentLabel, setCurrentLabel] = useState(currentData?.label);
    const [displayValue, setDisplayValue] = useState();
    const [currentValue, setCurrentValue] = useState(currentData?.value || "");
    const [currentVisible, setCurrentVisible] = useState(
      currentData?.visible ?? true
    );
    const [isUpdating, setIsUpdating] = useState(false)
    const [currentCurveSlug, setCurrentCurveSlug] = useState(
      currentData?.curve_slug || "none"
    );
    const [currentDerivation, setCurrentDerivation] = useState(
      currentData?.derivation
    );
    const [availableDerivations, setAvailableDerivations] = useState([]);

    const getDisplayValue = (value) => {
      if (value === "" || isNullOrUndefined(value)) return "";
      const newValue = convertValueToSelectedUnit(
        Number(value),
        storedUnit,
        displayUnit,
        decimals
      );
      return newValue;
    };

    useEffect(() => {
      setCurrentLabel(currentData?.label);
    }, [currentData?.label]);
    useEffect(() => {
      setDisplayValue(getDisplayValue(currentData?.value));
    }, [currentData?.value]);
    useEffect(() => {
      setCurrentValue(currentData?.value || "");
    }, [currentData?.value]);
    useEffect(() => {
      setCurrentVisible(currentData?.visible ?? true);
      setIsUpdating(false)
    }, [currentData?.visible]);
    useEffect(() => {
      setCurrentCurveSlug(currentData?.curve_slug || "none");
    }, [currentData?.curve_slug]);
    useEffect(() => {
      setCurrentDerivation(currentData?.derivation);
    }, [currentData?.derivation]);

    const {
      percentiles,
      availableCurveSlugs,
      displayUnit,
      storedUnit,
      decimals,
      measurementId = null,
    } = currentData || {};

    const makeDerivationLabel = (derivationSlug, value) => {
      value = convertValueToSelectedUnit(
        Number(value),
        storedUnit,
        displayUnit
      );
      const displayValue = convertValueToSelectedUnit(
        value,
        currentData?.defaultUnit,
        currentData?.units,
        decimals
      );
      return measurementsContext.makeDerivationLabel(
        derivationSlug,
        displayValue
      );
    };

    const onChangeLabel = (label) => {
      setCurrentLabel(label);
      onChange({ label });
    };

    const onChangeVisible = () => {
      const newVisible = currentData.visible === false;
      setCurrentVisible(newVisible);
      setIsUpdating(true)
      onChange({ visible: newVisible });
    };

    const onChangeDerivation = (derivation) => {
      const selectedDerivation = currentData.availableDerivations.find(
        (d) => d.derivation === derivation
      );
      const value = selectedDerivation?.value || currentValue;

      setCurrentDerivation(derivation);
      setCurrentValue(value);
      setDisplayValue(getDisplayValue(value));

      onChange({ value, derivation });
    };

    const onChangeValue = (value) => {
      setDisplayValue(value);
      if (value !== "") {
        value = convertValueToSelectedUnit(value, displayUnit, storedUnit);
      }
      const newValue = value === "" ? null : Number(value);
      const newDerivation =
        currentData.availableDerivations.find((d) => d.value === value)
          ?.derivation ?? "edit";

      setCurrentValue(newValue);
      setCurrentDerivation(newDerivation);
      return { newValue, newDerivation };
    };

    const onBlurValue = (value) => {
      const { newValue, newDerivation } = onChangeValue(value);
      onChange({ value: newValue, derivation: newDerivation });
    };

    const onChangeIdentifierValue = (value) => {
      setDisplayValue(value);

      if (value !== "") {
        value = convertValueToSelectedUnit(value, displayUnit, storedUnit);
      }

      const newValue = value === "" ? null : Number(value);

      setCurrentValue(newValue);

      return newValue;
    };

    const onBlurIdentifierValue = (value) => {
      const newValue = onChangeIdentifierValue(value);
      onChange({ identifierValue: { [measurementId]: { value: newValue } } });
    };

    const onChangeCurve = (curve) => {
      setCurrentCurveSlug(curve);
      onChange({ curve_slug: curve });
    };

    const onChange = (newValues) => {
      let updates = {};

      if (newValues.label !== undefined) updates.label = newValues.label;
      if (newValues.value !== undefined && newValues.derivation === "edit") {
        updates.values = { [EDIT]: newValues.value };
      }
      if (newValues.identifierValue !== undefined) {
        updates = { ...updates, ...newValues.identifierValue };
      }
      if (newValues.derivation !== undefined) {
        updates.derivation = newValues.derivation;
      }
      if (newValues.visible !== undefined) updates.visible = newValues.visible;
      if (newValues.curve_slug !== undefined) {
        updates.curve_slug = newValues.curve_slug;
      }

      updateMeasurement(updates);
    };

    useEffect(() => {
      let derivations = Object.values(
        currentData?.availableDerivations || {}
      ).map(({ derivation, value }) => {
        const label = makeDerivationLabel(derivation, value);
        return { label, value: derivation };
      });
      if (currentDerivation === "edit") {
        if (currentValue === null) {
          derivations = derivations.filter((d) => d.value === "edit");
        } else {
          const edit = derivations.find((d) => d.value === "edit");
          if (edit) edit.label = makeDerivationLabel("edit", currentValue);
          else {
            derivations.push({
              label: makeDerivationLabel("edit", currentValue),
              value: "edit",
            });
          }
        }
      }
      setAvailableDerivations(derivations);
    }, [currentValue, currentDerivation]);

    const curveSlugOptions = [
      { label: "-", value: "none" },
      ...(availableCurveSlugs?.map((slug) => ({
        label: slug.split(".").pop().toUpperCase(),
        value: slug,
      })) || []),
    ];

    const estimatedGa = currentData.estimatedGa?.value
      ? getNiceGestionalAgeFromDays(__, currentData.estimatedGa?.value)
      : "-";

    return (
      <div
        className={`measurements-row ${
          currentVisible === false ? "not-visible" : ""
        }`}
        id={`measurement-row-${measurement.slug}`}
      >
        {/* Label */}
        <div className="label">
          {/* TODO: Currently labels for identifier measurements are not editable. */}
          {identifier ? (
            <div className="identifier">
              {currentLabel} - {identifier}
            </div>
          ) : (
            <TextInput
              value={currentLabel}
              onChange={onChangeLabel}
              onBlur={onChangeLabel}
              fullwidth={true}
            />
          )}
        </div>

        {/* Value */}
        <div>
          <NumericInput
            value={displayValue}
            onChange={identifier ? onChangeIdentifierValue : onChangeValue}
            onBlur={identifier ? onBlurIdentifierValue : onBlurValue}
            step={1 / 10 ** decimals}
            showPlusMinus={false}
          />
        </div>

        {/* Unit */}
        <div>{displayUnit === "null" ? "" : displayUnit}</div>

        {/* Derivations */}
        <div>
          {availableDerivations.length > 0 ? (
            <span className="derivation-dropdown">
              <SelectInput
                value={currentDerivation}
                options={availableDerivations}
                onChange={onChangeDerivation}
              />
            </span>
          ) : (
            <span>-</span>
          )}
        </div>

        {/* Percentile */}
        <div className="border-left">
          {getPercentileValueString(
            percentiles?.[currentCurveSlug]?.percentile,
            __
          )}
        </div>

        {/* Z-Score */}
        <div>
          {!isNullOrUndefined(percentiles?.[currentCurveSlug]?.zscore)
            ? `${percentiles?.[currentCurveSlug]?.zscore.toFixed(1)}`
            : "-"}
        </div>

        {/* Available Authors */}
        <div>
          {availableCurveSlugs?.length > 0 ? (
            <span className="derivation-dropdown">
              <SelectInput
                value={currentCurveSlug}
                options={curveSlugOptions}
                onChange={onChangeCurve}
              />
            </span>
          ) : (
            <span>-</span>
          )}
        </div>

        {/* GA */}
        <div className="border-left">{estimatedGa}</div>

        {/* Visibility */}
        <div className={`border-left ${isUpdating ? 'disable-click' : 'visibility' }`}>
          <Button
            icon={currentVisible === false ? "eye-off" : "eye"}
            size="input"
            color="grey"
            onClick={onChangeVisible}
            disabled={isUpdating}
          />
        </div>
        {currentVisible === false && (
          <NotVisibleOverlay onClick={onChangeVisible} />
        )}
      </div>
    );
  }
);
