import ReportTemplateGaTable_v1_0 from "./GaTable/v1.0.js";
import ReportTemplateGaTable_v1_1 from "./GaTable/v1.1.js";
import ReportTemplateGaTable_v2_0 from "./GaTable/v2.0.js";

export default function ReportTemplateGaTable(props) {
  const { apiVersion } = props;
  if (apiVersion === "1.0") {
    return ReportTemplateGaTable_v1_0(props);
  } else if (apiVersion === "1.1") {
    return ReportTemplateGaTable_v1_1(props);
  } else if (apiVersion === "2.0") {
    return ReportTemplateGaTable_v2_0(props);
  }
  return null;
}
