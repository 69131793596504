import { useState, useMemo } from "react";
import { withTranslation } from "react-i18next";

/* Utils */
import {
  getNiceGestionalAgeFromDays,
  computeEstimatedDeliveryDate,
} from "../../../../../services/examination";
import {
  slugDateObtainedMapping,
  conceptionDateOptions,
  eddOptions,
  prevUsOptions,
} from "../gaConstants";

/* Atoms */
import InlineInput from "../../../../../atoms/InlineEditing/InlineInput/InlineInput";
import Icon from "../../../../../atoms/Icon/Icon";
import LoaderInline from "../../../../../atoms/LoaderInline/LoaderInline";
import InlineDropdown from "../../../../../atoms/InlineEditing/InlineDropdown/InlineDropdown";
import NumericInput from "../../../../../atoms/NumericInput/NumericInput";
import InlineMultiSelect from "../../../../../atoms/InlineEditing/InlineMultiSelect/InlineMultiSelect";
import GaInput from "../../../../../atoms/GaInput/GaInput";
import NotVisibleOverlay from "../../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

const NONE = "none";

const NonEditableGaItem = ({
  t: __,
  data,
  gaItemProps,
  placeholdersHelper,
  reportDataOptions,
  reportMode,
  timezone,
  pregnancyLengthInDays,
}) => {
  const {
    currentExamDate,
    updateRow,
    updatingAssignedGa,
    setUpdatingAssignedGa,
    fetusDropdownOptions,
    showFetusDropDown,
    splitSlug,
    appPreferences,
    print,
    onEndEditing,
    currentLanguage,
    datingMethod,
    canEdit,
  } = gaItemProps;

  const preferredDateFormat = appPreferences?.date_format;

  const conceptionDateOptionsTranslated = useMemo(
    () => conceptionDateOptions.map((o) => ({ ...o, label: __(o.label) })),
    [conceptionDateOptions]
  );
  const eddOptionsTranslated = useMemo(
    () =>
      eddOptions.map((o) => ({
        ...o,
        label: __(o.label),
        description: __(o.description),
      })),
    [eddOptions]
  );
  const prevUsOptionsTranslated = useMemo(
    () =>
      prevUsOptions.map((o) => ({
        ...o,
        label: __(o.label),
        description: __(o.description),
      })),
    [prevUsOptions]
  );

  if (!data) return false;
  const { slug, fetus, visible } = data;
  const { method, standard } = splitSlug(slug);

  const getDefaultLabel = () => {
    if (method === NONE) return "";
    let defaultLabel = datingMethod(slug)?.label?.[currentLanguage] || slug;

    if (standard !== NONE) {
      const standardLabel =
        reportDataOptions?.dating_standards?.[standard]?.[currentLanguage] ||
        false;
      defaultLabel += standardLabel ? ` (${standardLabel})` : "";
    }
    return defaultLabel;
  };

  const getPlaceholderData = () => {
    const label = data.label || getDefaultLabel();
    const patientValue = datingMethod(slug)?.patient_value;
    const placeholder = placeholdersHelper.selectedValue(slug, data.fetus);
    if (!placeholder) {
      return { ...data, label, patientValue };
    } else {
      return {
        ...placeholder,
        ...data,
        label,
        patientValue,
      };
    }
  };

  const {
    is_assigned: isAssigned = false,
    value = false,
    date_obtained: dateObtained = undefined,
    rowId,
    label,
    ...placeholder
  } = getPlaceholderData();

  if (print && slug === "ga.conception_date" && placeholder.conception_method)
    return null;
  if (print && !value) return null;

  const onChange = (valueToUpdate) => (value) => {
    updateRow({ [valueToUpdate]: value }, rowId);
  };

  const [loading, setLoading] = useState(false);

  const onChangeField = (fieldName) => async (value) => {
    const slug = `episode.${fieldName}`;
    setLoading(true);
    await onEndEditing(
      slug,
      placeholdersHelper.editSelectedDataValue(
        slug,
        { value },
        data.fetus,
        "user"
      )
    );
    setLoading(false);
  };

  const onClickAssigned = async () => {
    /* TODO reimplement the transition */
    setUpdatingAssignedGa(slug, data.fetus);
  };

  const showAsAssigned = isAssigned && !updatingAssignedGa;

  let extraOptions;
  switch (slug) {
    case "ga.lmp":
      extraOptions = (
        <>
          {print ? (
            <>
              {/*
                            {placeholders["episode.cycle_length"]?.value ?
                                <div className="ga-item-extra-info-text not-printable">
                                    Cycle length:
                                </div> : ""
                            }
                            <div>{placeholders["episode.cycle_length"]?.value} {placeholders["episode.cycle_length"]?.value ? __("report.editGa.days") : ""}</div>
                        */}
            </>
          ) : (
            <>
              <div className="ga-item-extra-info-text">
                {__("report.gatable.cycleLength")}
                {__(":")}
              </div>
              <div>
                <NumericInput
                  value={
                    placeholdersHelper.selectedValue("episode.cycle_length")
                      ?.value
                  }
                  onChange={onChangeField("cycle_length")}
                  active={canEdit}
                />
              </div>
              <div
                className="ga-item-extra-info-text"
                style={{ paddingLeft: "1rem" }}
              >
                {__("report.editGa.days")}
              </div>
            </>
          )}
        </>
      );
      break;
    case "ga.conception_date":
      extraOptions = (
        <>
          <div className="ga-item-extra-info-text">
            {__("report.gatable.conceptionMethod")}
            {__(":")}
          </div>
          <div>
            <InlineDropdown
              value={
                placeholdersHelper.selectedValue("episode.conception_method")
                  ?.value
              }
              options={conceptionDateOptionsTranslated}
              printable={true}
              onChange={onChangeField("conception_method")}
              active={canEdit}
            />
          </div>
        </>
      );
      break;
    case "ga.embryo_transfer":
      extraOptions = (
        <>
          <div className="ga-item-extra-info-text">
            {__("report.gatable.ivfEt")}
            {__(":")}
          </div>

          {print ? (
            <div>
              {
                placeholdersHelper.selectedValue("episode.embryo_transfer_day")
                  ?.value
              }{" "}
              {placeholdersHelper.selectedValue("episode.embryo_transfer_day")
                ?.value
                ? __("report.editGa.days")
                : ""}
            </div>
          ) : (
            <>
              <div>
                <NumericInput
                  value={
                    placeholdersHelper.selectedValue(
                      "episode.embryo_transfer_day"
                    )?.value
                  }
                  onChange={onChangeField("embryo_transfer_day")}
                  active={canEdit}
                />
              </div>
              <div
                className="ga-item-extra-info-text"
                style={{ paddingLeft: "1rem" }}
              >
                {__("report.editGa.days")}
              </div>
            </>
          )}
        </>
      );
      break;
    case "ga.prev_ultrasound":
      extraOptions = (
        <>
          <div className="ga-item-extra-info-text">
            {__("report.gatable.basedUpon")}
            {__(":")}
          </div>

          <div className="margin-right-2">
            <InlineMultiSelect
              value={
                placeholdersHelper.selectedValue(
                  "episode.prev_ultrasound_option"
                )?.value
              }
              options={prevUsOptionsTranslated}
              printable={visible}
              multiple={false}
              fullwidth={true}
              onChange={onChangeField("prev_ultrasound_option")}
              active={canEdit}
            />
          </div>
          <div>
            {placeholdersHelper.selectedValue("episode.prev_ultrasound_option")
              ?.value === "ga" ? (
              print ? (
                getNiceGestionalAgeFromDays(
                  __,
                  Math.floor(
                    placeholdersHelper.selectedValue(
                      "episode.prev_ultrasound_ga"
                    )?.value
                  )
                )
              ) : (
                <GaInput
                  value={
                    placeholdersHelper.selectedValue(
                      "episode.prev_ultrasound_ga"
                    )?.value
                  }
                  onChange={onChangeField("prev_ultrasound_ga")}
                  active={canEdit}
                />
              )
            ) : (
              <>
                <NumericInput
                  value={
                    placeholdersHelper.selectedValue(
                      "episode.prev_ultrasound_biometry_value"
                    )?.value
                  }
                  onChange={onChangeField("prev_ultrasound_biometry_value")}
                  active={canEdit}
                  suffix="mm"
                  step={0.1}
                />
              </>
            )}
          </div>
        </>
      );
      break;
    case "ga.edd":
      extraOptions = (
        <div>
          <InlineMultiSelect
            value={
              placeholdersHelper.selectedValue("episode.edd_methods")?.value
            }
            options={eddOptionsTranslated}
            printable={visible}
            multiple={true}
            fullwidth={true}
            onChange={onChangeField("edd_methods")}
            active={canEdit}
          />
        </div>
      );
      break;
    default:
      extraOptions = <></>;
  }

  return (
    <div
      className={`ga-item ${visible ? "is-visible" : "not-visible"} ${
        showFetusDropDown ? "show-fetus" : ""
      }`}
    >
      {/* Fetus */}
      {showFetusDropDown && (
        <div>
          <span className="ga-item-fetus-number">
            {fetusDropdownOptions?.[fetus]?.label || "-"}
          </span>
        </div>
      )}

      {/* Custom Label */}
      <div>
        <InlineInput
          value={label}
          format={"string"}
          printable={visible}
          onChange={onChange("label")}
          appPreferences={appPreferences}
          active={canEdit}
        />
      </div>

      {/* Scan Date */}
      <div className="ga-item-center">
        <InlineInput
          value={dateObtained}
          format="date"
          printable={visible}
          onChange={onChangeField(slugDateObtainedMapping[slug])}
          appPreferences={appPreferences}
          active={canEdit}
        />
      </div>

      {/* Extra data */}
      <div className="ga-item-non-editable-details">{extraOptions}</div>

      {/* Current GA */}
      <div className="ga-item-center">
        {loading
          ? `${__("common.loading")}...`
          : value
          ? getNiceGestionalAgeFromDays(__, Math.floor(value))
          : print
          ? ""
          : __("report.gatable.unavailable")}
      </div>

      {/* EDD */}
      <div className="ga-item-center">
        {loading
          ? "..."
          : computeEstimatedDeliveryDate(
              value,
              currentExamDate,
              preferredDateFormat,
              timezone,
              pregnancyLengthInDays
            )}
      </div>

      {/* Assigned */}
      {!print && (
        <div
          className="ga-item-assign ga-item-center"
          onClick={() => (value ? onClickAssigned() : null)}
        >
          {updatingAssignedGa ? (
            <LoaderInline />
          ) : (
            <input type="radio" readOnly checked={showAsAssigned} />
          )}
        </div>
      )}
      {/* Editing options */}
      {visible && reportMode === "edit" && (
        <div className="exam-report-editing-options">
          <div onClick={() => onChange("visible")(!visible)}>
            <Icon name={visible ? "eye" : "eye-off"} />
          </div>
        </div>
      )}
      {!visible && reportMode === "edit" && (
        <NotVisibleOverlay onClick={() => onChange("visible")(!visible)} />
      )}
    </div>
  );
};

export default withTranslation()(NonEditableGaItem);
