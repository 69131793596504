/* React */
import XMLToReact from "xml-to-react";
import { useState, useEffect } from "react";

/* Components */
import ReportTemplateBlock from "./template/Block";
import ReportTemplateChecklist from "./template/Checklist";
import ReportTemplateContent from "./template/Content";
import ReportTemplateDocument from "./template/Document";
import ReportTemplateHeader from "./template/Header";
import ReportTemplateHeading from "./template/Heading";
import ReportTemplateMeasurementCurve from "./template/MeasurementCurve";
import ReportTemplateMeasurementCurveLegend from "./template/MeasurementCurveLegend";
import ReportTemplateGaTable from "./template/GaTable";
import ReportTemplateBiometryTable from "./template/BiometryTable";
import ReportTemplateBiometryItem from "./template/BiometryItem";
import ReportTemplatePageBreak from "./template/PageBreak";
import ReportTemplateSpacer from "./template/Spacer";
import ReportTemplatePageFooter from "./template/PageFooter";
import ReportTemplatePageHeader from "./template/PageHeader";
import ReportTemplateLabel from "./template/Label";
import ReportTemplateLogo from "./template/Logo";
import ReportTemplateTextBlock from "./template/TextBlock";
import ReportTemplateMedicalHistory from "./template/MedicalHistory";
import ReportTemplateValue from "./template/Value";
import ReportTemplateCondition from "./template/Condition";
import ReportTemplateIf from "./template/If";
import ReportTemplateElse from "./template/Else";
import ReportTemplateImage from "./template/Image";
import ReportTemplateImages from "./template/Images";
import ReportTemplatePrivate from "./template/Private";
import ReportTemplateDropdown from "./template/Dropdown";
import ReportTemplateOption from "./template/Option";
import ReportTemplateFetus from "./template/Fetus";
import ReportTemplateTable from "./template/Table";
import ReportTemplateGraphs from "./template/Graphs";
import ReportTemplateFetalGrowthTable from "./template/FetalGrowthTable";
import ReportTemplateEmpty from "./template/Empty";
import ReportTemplateSearch from "./template/Search";
import IdentifierBiometry from "./template/IdentifierBiometry";

const templateTagToType = {
  snippet: ReportTemplateEmpty,
  section: ReportTemplateEmpty,
  block: ReportTemplateBlock,
  checklist: ReportTemplateChecklist,
  column: ReportTemplateEmpty,
  content: ReportTemplateContent,
  "labelled-content": ReportTemplateContent,
  document: ReportTemplateDocument,
  dropdown: ReportTemplateDropdown,
  fetus: ReportTemplateFetus,
  option: ReportTemplateOption,
  condition: ReportTemplateCondition,
  if: ReportTemplateIf,
  else: ReportTemplateElse,
  image: ReportTemplateImage,
  images: ReportTemplateImages,
  label: ReportTemplateLabel,
  logo: ReportTemplateLogo,
  graphs: ReportTemplateGraphs,
  group: ReportTemplateEmpty,
  "measurement-curve": ReportTemplateMeasurementCurve,
  "measurement-curve-legend": ReportTemplateMeasurementCurveLegend,
  "ga-table": ReportTemplateGaTable,
  "biometry-table": ReportTemplateBiometryTable,
  "biometry-item": ReportTemplateBiometryItem,
  "identifier-biometry": IdentifierBiometry,
  "fetal-growth-table": ReportTemplateFetalGrowthTable,
  header: ReportTemplateHeader,
  heading: ReportTemplateHeading,
  "page-break": ReportTemplatePageBreak,
  private: ReportTemplatePrivate,
  search: ReportTemplateSearch,
  spacer: ReportTemplateSpacer,
  table: ReportTemplateTable,
  textblock: ReportTemplateTextBlock,
  "medical-history": ReportTemplateMedicalHistory,
  value: ReportTemplateValue,
  "page-footer": ReportTemplatePageFooter,
  "page-header": ReportTemplatePageHeader,
}

const supportedTags = [
  "snippet",
  "section",
  "block",
  "checklist",
  "column",
  "content",
  "labelled-content",
  "document",
  "dropdown",
  "fetus",
  "option",
  "condition",
  "if",
  "else",
  "image",
  "images",
  "label",
  "logo",
  "graphs",
  "group",
  "measurement-curve",
  "measurement-curve-legend",
  "ga-table",
  "biometry-table",
  "biometry-item",
  "identifier-biometry",
  "fetal-growth-table",
  "header",
  "heading",
  "page-break",
  "private",
  "search",
  "spacer",
  "table",
  "textblock",
  "medical-history",
  "value",
  "page-footer",
  "page-header",
];

const applyProps = (tree, templateTypeToProps, everyOneProps) => {
  if (Array.isArray(tree))
    return tree.map((subTree) => applyProps(subTree, templateTypeToProps, everyOneProps))
  if (tree === null)
    return null
  if (tree === undefined)
    return undefined
  if (tree.$$typeof) {
    if (!templateTypeToProps[tree.props.type]) {
      console.warn("Unknown report element", tree.props.type)
      return tree
    }
    const { children: treeChildren, ...treeProps } = tree.props
    const props = { ...templateTypeToProps[tree.props.type](treeProps), ...everyOneProps }
    const children = applyProps(treeChildren, templateTypeToProps, everyOneProps)
    if (Array.isArray(children))
      return { ...tree, props: { ...props, children } }
    if (children === null || children === undefined)
      return { ...tree, props }
    return { ...tree, props: { ...props, children } }
  }
  if (typeof (tree) === 'string')
    return tree
  console.error("Unexpect tree element", tree)
  return tree
}

const applyType = (tree, templateTagToType) => {
  if (Array.isArray(tree))
    return tree.map((subTree) => applyType(subTree, templateTagToType))
  if (tree === null)
    return null
  if (tree === undefined)
    return undefined
  if (tree.$$typeof) {
    const { children: treeChildren } = tree.props
    const children = applyType(treeChildren, templateTagToType)
    if (!templateTagToType[tree.type]) {
      console.warn("Unkown report element", tree.type)
      return { ...tree, props: { ...tree.props, children, type: tree.type } }
    } else {
      return { ...tree, type: templateTagToType[tree.type], props: { ...tree.props, children, type: tree.type } }
    }
  }
  if (typeof (tree) === 'string')
    return tree
  console.error("Unexpect tree element", tree)
  return tree
}

const calculateTemplateTree = (templateBlueprint) => {
  if (!templateBlueprint) return null;
  const xmlToReact = new XMLToReact(
    /* transform the list of supported tags to an object as:
     * {
     *    tag: (props) => ({type: tag, props})
     * }
     */
    supportedTags.reduce(
      (acc, tag) => ({
        ...acc,
        [tag]: (props) => ({ type: tag, props })
      }), {})
  )
  return applyType(xmlToReact.convert(templateBlueprint), templateTagToType);
}

const XMLDocument = (({
  page = "report",
  showErrors = false,
  submitClicked = false,
  setEditChecklistDialogIsOpen = () => { },
  reportMode,
  templateBlueprint,
  setManageMeasurementsIsOpen = () => { },
  base64Images = [],
  setBase64Images = () => { },
  requiredAndInvalid = null,
  setRequiredAndInvalid = () => { },
  /* contexts passed by custom hook */
  examinationContext,
  measurementsContext,
  siteFlowsConnectors,
  user,
  isFeatureFlagEnabled,
  appContext,
  BIContext,
  getHighligthedWithProps,
  getCarryForwardWithProps,
  customPlaceholders,
  setCustomPlaceholders,
  onEndEditing,
  onEndEditingChecklist,
  onEndEditingDynamicDropdown,
  onEndEditingDating,

  componentChecklistAssoc,
  updateComponentChecklistAssoc,
  removeComponentChecklistAssoc,

  loadDynamicDropdownFullTree,

  startEditingField,
  startEditing,
  editingFieldId,
  frozenHTML,

  /* Deprecated */
  placeholders,
  setAssignedGa,
  revertAssignedGa,
  updateEpisode,
  updateAutogeneratedChecklistComments,
  /* New API */
  requirePlaceholders,
  requiredPlaceholdersFromProps,
  reportDataOptions,
  apiVersion
}) => {
  /* TODO change fetusNumber and see why it has been defined */
  const fetusNumber = 1;

  const loadingProps = {
    /* New API */
    requirePlaceholders,
  };

  const everyOneProps = {
    reportMode,
    apiVersion,
    BIContext: { ...BIContext, page: page },
    reportDataOptions
  };

  const commonProps = {
    reportMode,
    user,
    page,
    appPreferences: appContext?.preferences,
    examinationContext,
    canEdit: examinationContext.canEdit,
    requiredAndInvalid,
    setRequiredAndInvalid,
    showErrors,
    submitClicked,
    onEndEditing,
    fetus: fetusNumber,
    measurementsContext,
    customPlaceholders,
    setCustomPlaceholders,
    requiredAndInvalid,
    setRequiredAndInvalid,
    ...loadingProps,
  };

  const [plainTemplateReactTree, setTemplateReactTree] = useState(null);

  const checklistItemsToDisplay = Object.entries(placeholders).filter(([key, value]) => key.startsWith("checklist.item.") && Array.isArray(value) && value.some(valuePerFetus => valuePerFetus.active)).map(item => item[1]);

  const checklistProps = {
    placeholders,
    groups: reportDataOptions?.checklist_item_groups,
    checklistItems: checklistItemsToDisplay,
    setEditChecklistDialogIsOpen,
    onEndEditingChecklist,
    componentChecklistAssoc,
    updateComponentChecklistAssoc,
    updateAutogeneratedChecklistComments
  }

  const dropdownProps = {
    loadDynamicDropdownFullTree,
    onEndEditingDynamicDropdown
  }

  const codingPropsProps = { type: "coding" };
  const codingProps = !!siteFlowsConnectors?.coding ? { props: codingPropsProps, placeholders: requiredPlaceholdersFromProps(codingPropsProps) } : null;

  const templateTypeToProps = {
    snippet: (props) => ({ props, type: "snippet", ...commonProps }),
    section: (props) => ({ props, type: "section", ...commonProps }),
    block: (props) => ({ props, ...commonProps }),
    checklist: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...checklistProps, ...commonProps }),
    column: (props) => ({ props, type: "column", showOptions: false, ...commonProps }),
    content: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), highlight: getHighligthedWithProps(props), ...commonProps }),
    "labelled-content": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), highlight: getHighligthedWithProps(props), ...commonProps }),
    document: (props) => ({ props, startEditing, frozenHTML, codingProps: { props: codingProps, placeholders: requiredPlaceholdersFromProps(codingProps), ...commonProps, ...everyOneProps }, ...commonProps }),
    dropdown: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), highlight: getHighligthedWithProps(props), ...commonProps, ...dropdownProps }),
    fetus: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...commonProps }),
    group: (props) => ({ props }),
    option: (props) => ({ props, type: "option", ...commonProps }),
    /*
     * Note a condition is uniquely identified by its body as there is no data attributed to it.
     * At the moment conditions receive data for all conditions in the report.
     * Performances can be increased if we add a unique ID to each condition.
     */
    condition: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...commonProps }),
    if: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), type: "if", ...commonProps }),
    else: (props) => ({ props, type: "else", ...commonProps }),
    image: (props) => ({ props }),
    images: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), base64Images, setBase64Images, ...loadingProps }),
    label: (props) => ({ props }),
    logo: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...loadingProps }),
    graphs: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...commonProps }),
    group: (props) => ({ props }),
    "measurement-curve": (props) => ({ props, type: "measurement-curve", placeholders: requiredPlaceholdersFromProps(props), editingFieldId, startEditingField, ...commonProps }),
    "measurement-curve-legend": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), type: "measurement-curve-legend", ...commonProps }),
    "ga-table": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), onEndEditingDating, setAssignedGa, revertAssignedGa, updateEpisode, ...commonProps }),
    "biometry-table": (props) => ({ props, ...commonProps }),
    "biometry-item": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), editingFieldId, startEditingField, setManageMeasurementsIsOpen, ...commonProps }),
    "fetal-growth-table": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...commonProps }),
    header: (props) => ({ props }),
    heading: (props) => ({ props }),
    "page-break": (props) => ({ props }),
    private: (props) => ({ props }),
    search: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), carryForward: getCarryForwardWithProps(props), ...commonProps }),
    spacer: (props) => ({ props }),
    table: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), carryForward: getCarryForwardWithProps(props, "table"), showOptions: false, ...dropdownProps, ...commonProps }),
    textblock: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), highlight: getHighligthedWithProps(props), ...commonProps }),
    "medical-history": (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), displayRisks: isFeatureFlagEnabled("sonio.risk"), ...loadingProps }),
    value: (props) => ({ props, placeholders: requiredPlaceholdersFromProps(props), ...commonProps }),
    "page-footer": (props) => ({ props }),
    "page-header": (props) => ({ props }),
    "identifier-biometry": (props) => ({ props, placeholders, ...commonProps }),
  }

  useEffect(() => {
    if (!templateBlueprint) return;

    setTemplateReactTree(calculateTemplateTree(templateBlueprint));
  }, [templateBlueprint]);

  if (reportMode === 'edit') {
    const templateReactTree = applyProps(plainTemplateReactTree, templateTypeToProps, everyOneProps)
    return templateReactTree;
  }
  if (reportMode === 'print') {
    if (!templateBlueprint) return null;
    const templateReactTree = applyProps(calculateTemplateTree(templateBlueprint), templateTypeToProps, everyOneProps);
    return templateReactTree;
  }
});

export default XMLDocument;
