import React, { useContext } from 'react';
import Icon from '../../atoms/Icon/Icon';
import { useExamination } from '../../context-providers/Examination';
import { AppContext } from "../../context-providers/App";
import ResourceApi from '../../services/resource';

export default function Examination({ examination, openChapter, setOpenChapter }) {
  const appContext = useContext(AppContext)
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useExamination();
  let previousViewId = false;
  let sameViewIdCount = 0;
  const reportVersion = examinationContext.examination.report_version;

  const updateExamination = (attrs) => {
    /* Do not pass via the examination context as we need to be sure the info goes straight to the backend before reloading the page */
    ResourceApi.updateExamination(examination.id, attrs)
      .then(() => window.location.reload())
  }



  return (
    <div className={`debug-section ${openChapter === "Examination" ? "open" : "closed"}`}>
      <h2 onClick={() => setOpenChapter(chapter => chapter === "Examination" ? false : "Examination")}>Examination <Icon name="down" /></h2>
      {openChapter === "Examination" && (
        <div className="debug-subsection">
          {!examination
            ? (<div>No open exams</div>)
            : (
              <>
                <div className="mb-small text-medium">
                  <h3>Report Version</h3>
                  <select id="report-version" style={{ color: "black" }} value={reportVersion} onChange={(e) => updateExamination({ report_version: e.target.value })}>
                    <option value="1.0.0">1.0.0</option>
                    <option value="2.0.0">2.0.0</option>
                  </select>
                </div>
                <h3>Association Dictionary</h3>
                <table>
                  <tbody>
                    {appContext.viewsDictionary.sort((a, b) => a.instance_view_id - b.instance_view_id).map(term => {
                      const viewId = term.instance_view_id;
                      sameViewIdCount = viewId === previousViewId ? sameViewIdCount + 1 : 0;
                      const label = previousViewId === viewId ? '' : examinationContext.instanceViews?.[viewId]?.label[currentLanguage];
                      previousViewId = viewId;
                      return <React.Fragment key={`${viewId}-${sameViewIdCount}`}>
                        {label ? <tr className="spacer" key={`${viewId}-spacer`}><td colSpan="2" /></tr> : null}
                        <tr key={`${viewId}-${term.text_on_img}`}>
                          {label ? <th>{label}</th> : <td></td>}
                          <td>{term.text_on_img}</td>
                        </tr>
                      </React.Fragment>
                    })}
                  </tbody>
                </table>
              </>
            )
          }
        </div>
      )}
    </div>
  )
}
