import { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../../../atoms/Icon/Icon";
import GaItem from "./v2.0/GaItem";
import AssignedGaItem from "./v2.0/AssignedGaItem";
import NonEditableGaItem from "./v2.0/NonEditableGaItem";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

const NONE = "none";
const nonEditableGaSlugs = [
  "lmp",
  "conception_date",
  "embryo_transfer",
  "edd",
  "prev_ultrasound",
];

const splitSlug = (method) => {
  if (!method) return false;
  const [_, m, s] = method.split(".");
  return { method: m, standard: s || NONE };
};

const makeSlug = (method, standard) =>
  `ga.${method}` + (standard !== NONE ? `.${standard}` : "");

const ReportTemplateGaTableBody = (fullProps) => {
  const {
    t: __,
    i18n: { language: currentLanguage },
    props,
    placeholders,
    reportDataOptions,
    setAssignedGa,
    appPreferences,
    revertAssignedGa,
    setRequiredAndInvalid,
    showErrors,
    reportMode,
    onEndEditing,
    templateLabels,
    templateMethods,
    availableSlugs,
    apiVersion,
    canEdit,
  } = fullProps;
  const print = reportMode === "print";
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const numberOfFetuses = placeholdersHelper.numberOfFetuses;
  const availableStandards = reportDataOptions?.dating_standards;

  const methodsWithStandards = useMemo(() => {
    const value = availableSlugs.reduce((acc, slug) => {
      const { method, standard } = splitSlug(slug);
      if (!Object.hasOwn(acc, method)) acc[method] = [];
      acc[method].push({
        value: standard,
        label: availableStandards?.[standard]?.[currentLanguage],
      });
      return acc;
    }, {});

    value[NONE] = [
      { value: NONE, label: availableStandards?.[NONE]?.[currentLanguage] },
    ];
    return value;
  }, [availableSlugs, availableStandards]);

  const datingMethod = useCallback(
    (slug) => {
      return reportDataOptions?.dating_methods?.[slug];
    },
    [reportDataOptions?.dating_methods]
  );

  // TODO: feels a bit hacky, we need better labels in the DB
  const methodsDropdownOptions = useMemo(
    () =>
      Object.entries(methodsWithStandards)
        .sort((a, b) => (a[0] >= b[0] ? 1 : -1))
        .map(([method, standards]) => {
          const slug = makeSlug(method, standards[0]?.value);
          const option = {
            value: method,
            label: datingMethod(slug)?.label?.[currentLanguage],
          };
          if (!option.label) option.invisible = true;
          return option;
        })
        .filter(({ value }) => !nonEditableGaSlugs.includes(value)),
    [methodsWithStandards, currentLanguage]
  );

  const { "column-labels": columnLabels, "assigned-label": assignedLabel } =
    props;

  const [updatingAssignedGa, doSetUpdatingAssignedGa] = useState(false);
  const setUpdatingAssignedGa = (slug, fetus) => {
    doSetUpdatingAssignedGa((updatingAssignedGa) => {
      if (updatingAssignedGa) return updatingAssignedGa;
      const update_guid = placeholders[slug].update_guid;
      onEndEditing(
        slug,
        placeholdersHelper.editSelectedDataValue(
          slug,
          { is_assigned: true },
          fetus,
          "user"
        )
      );
      return { slug, update_guid };
    });
  };

  useEffect(() => {
    /* If we have received an update on the placeholders we updated the GA on, we can say the GA has been updated */
    if (
      updatingAssignedGa &&
      placeholders[updatingAssignedGa.slug].update_guid !==
        updatingAssignedGa.update_guid
    ) {
      doSetUpdatingAssignedGa(false);
    }
  }, [
    updatingAssignedGa &&
      placeholders[updatingAssignedGa.slug].update_guid !==
        updatingAssignedGa.update_guid,
  ]);

  const defaultFetusFromSlug = (slug, currFetus = false) => {
    if (datingMethod(slug)?.patient_value) return 0;
    return currFetus || 1;
  };

  const makeDatingRow = (fetus, slug, label = false, rowId = false) => {
    rowId = rowId || Math.random().toString(16).slice(2);
    const visible = true;
    return { fetus, slug, rowId, visible, label };
  };

  const makeDefaultLmpAndAssignedRows = () => {
    const rows = nonEditableGaSlugs.map((gaSlug) =>
      makeDatingRow(0, `ga.${gaSlug}`, false, gaSlug)
    );
    const assignedRow = {
      label: assignedLabel || __("report.gatable.assignedDating"),
      rowId: "assigned",
      visible: true,
    };
    return [...rows, assignedRow];
  };

  const [stateBackup, setStateBackup] = useState();

  const getDataToDisplay = () => {
    const value = placeholdersHelper.selectedValue("dating.table", 0)?.value;
    if (value) {
      return value;
    } else if (stateBackup) {
      return stateBackup;
    } else {
      const rows = templateMethods.flatMap((method, idx) => {
        const slug = `ga.${method}`;
        const label = templateLabels[idx] || false;
        if (defaultFetusFromSlug(slug) === 0)
          return [makeDatingRow(0, slug, label)];
        return [...Array(numberOfFetuses).keys()].map((fetus) =>
          makeDatingRow(fetus + 1, slug, label)
        );
      });

      return [...rows, ...makeDefaultLmpAndAssignedRows()];
    }
  };

  // TODO initialize this state inside a useEffect once the rendering of reportData is fixed
  const displayData = getDataToDisplay();

  const defaultColumnLabels = [
    __("report.gatable.label"),
    __("report.gatable.scanDate"),
    __("report.gatable.method"),
    __("report.gatable.standard"),
    __("report.gatable.currentValue"),
    __("report.gatable.EDD"),
    "",
  ];

  const columnLabelsArray = columnLabels?.split("|") || [];
  for (let i = 0; columnLabelsArray[i]; i++) {
    defaultColumnLabels[i] = columnLabelsArray[i];
  }

  const fetusDropdownOptions = [...Array(numberOfFetuses + 1).keys()].map(
    (fetus) => ({
      value: fetus,
      label: placeholdersHelper.selectedValue("fetus.name", fetus)?.value,
    })
  );
  const showFetusDropDown = numberOfFetuses > 1;

  const updateGaTable = async (value) => {
    await onEndEditing("dating.table", [
      { value: { value }, source: "user", selected: true },
    ]);
  };

  const updateRow = (updatedValues, rowId) => {
    const idxToUpdate = displayData.findIndex((row) => row.rowId === rowId);
    if (idxToUpdate === -1) return;
    const copy = JSON.parse(JSON.stringify(displayData));
    copy[idxToUpdate] = { ...copy[idxToUpdate], ...updatedValues };
    setStateBackup(copy);
    updateGaTable(copy);
  };

  const addRow = () => {
    const slug = "ga.none";
    const fetus = defaultFetusFromSlug(slug);
    const newData = [...displayData, makeDatingRow(fetus, slug)];
    updateGaTable(newData);
  };

  const removeRow = (rowId) => {
    const idxToRemove = displayData.findIndex((row) => row.rowId === rowId);
    if (idxToRemove === -1) return;
    const copy = JSON.parse(JSON.stringify(displayData));
    copy.splice(idxToRemove, 1);
    updateGaTable(copy);
  };

  /* TODO make this as a useMemo and only have static data in it */
  const examinationDate = placeholdersHelper.selectedValue(
    "examination.date",
    0
  )?.value;
  const gaItemProps = {
    datingMethod,
    currentExamDate: examinationDate,
    methodsWithStandards,
    methodsDropdownOptions,
    fetusDropdownOptions,
    showFetusDropDown,
    updateRow,
    removeRow,
    setAssignedGa,
    updatingAssignedGa,
    setUpdatingAssignedGa,
    splitSlug,
    makeSlug,
    defaultFetusFromSlug,
    appPreferences,
    currentLanguage,
    print,
    reportMode,
    onEndEditing,
    revertAssignedGa,
    canEdit,
  };

  const assignedValue = placeholdersHelper.selectedValue(
    "ga.assigned.value",
    0
  )?.value;
  const invalidValue = () => !assignedValue;

  useEffect(() => {
    if (setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue()
          ? prevState.add("ga_table")
          : prevState.delete("ga_table");
        return prevState;
      });
    }
  }, [assignedValue, setRequiredAndInvalid]);

  const examId = placeholdersHelper.selectedValue("examination.id", 0)?.value;
  const assignedExam = placeholdersHelper.selectedValue(
    "ga.assigned.exam",
    0
  )?.value;
  const assignedFetus = placeholdersHelper.selectedValue(
    "ga.assigned.fetus",
    0
  )?.value;
  const assignedMethod = placeholdersHelper.selectedValue(
    "ga.assigned.method",
    0
  )?.value;
  const dateObtained =
    assignedExam === examId
      ? examinationDate
      : reportDataOptions.previous_exams?.[assignedExam]?.examination_date;

  const timezone = placeholdersHelper.selectedValue("site.timezone", 0)?.value;

  const gaData = {
    slug: assignedMethod,
    assignedOnDifferentExam: assignedExam !== examId,
    examId: assignedExam,
    fetus: assignedFetus,
    value: assignedValue,
    dateObtained,
    isAssigned: true,
  };
  const selectedAt = placeholdersHelper.selectedValue(
    "ga.assigned.selected_at",
    0
  )?.value;
  const prevUsMeasurement = placeholdersHelper.selectedValue(
    "episode.prev_ultrasound_option",
    0
  )?.value;
  const conceptionMethod = placeholdersHelper.selectedValue(
    "episode.conception_method",
    0
  )?.value;
  const conceptionDate = placeholdersHelper.selectedValue(
    "episode.conception_date",
    0
  )?.value;

  return (
    <>
      <div
        className={`ga-table ${numberOfFetuses > 1 ? "multi-fetal" : ""} ${
          invalidValue() && showErrors ? "required-error" : ""
        }`}
      >
        <div
          className={`ga-item column-heading ${
            showFetusDropDown ? "show-fetus" : ""
          }`}
        >
          {defaultColumnLabels.map((label, index) => {
            return (
              <div
                key={index}
                className={index === 0 && showFetusDropDown ? "double-col" : ""}
              >
                {label || <>&nbsp;</>}
              </div>
            );
          })}
        </div>

        <NonEditableGaItem
          apiVersion={apiVersion}
          data={displayData.find((row) => row.rowId === "lmp")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          placeholdersHelper={placeholdersHelper}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={timezone}
          pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
        />
        <NonEditableGaItem
          apiVersion={apiVersion}
          data={displayData.find((row) => row.rowId === "conception_date")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          placeholdersHelper={placeholdersHelper}
          gaItemProps={gaItemProps}
          value={conceptionDate}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={timezone}
          pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
        />

        {["ivf", "gift_zift"].includes(conceptionMethod) && (
          <NonEditableGaItem
            apiVersion={apiVersion}
            data={displayData.find((row) => row.rowId === "embryo_transfer")}
            reportDataOptions={reportDataOptions}
            placeholders={placeholders}
            placeholdersHelper={placeholdersHelper}
            gaItemProps={gaItemProps}
            reportMode={reportMode}
            onEndEditing={onEndEditing}
            timezone={timezone}
            pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
          />
        )}

        <NonEditableGaItem
          apiVersion={apiVersion}
          data={displayData.find((row) => row.rowId === "edd")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          placeholdersHelper={placeholdersHelper}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={timezone}
          pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
        />
        <NonEditableGaItem
          apiVersion={apiVersion}
          data={displayData.find((row) => row.rowId === "prev_ultrasound")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          placeholdersHelper={placeholdersHelper}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={timezone}
          pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
        />

        {displayData
          .filter((d) => ![...nonEditableGaSlugs, "assigned"].includes(d.rowId))
          .map((data, index) => {
            return (
              <GaItem
                apiVersion={apiVersion}
                key={index}
                assignedGa={gaData}
                data={data}
                placeholders={placeholders}
                placeholdersHelper={placeholdersHelper}
                gaItemProps={gaItemProps}
                reportMode={reportMode}
                onEndEditing={onEndEditing}
                timezone={timezone}
                canEdit={canEdit}
                pregnancyLengthInDays={
                  reportDataOptions.pregnancy_length_in_days
                }
              />
            );
          })}

        <AssignedGaItem
          apiVersion={apiVersion}
          data={displayData.find((d) => d.rowId === "assigned")}
          placeholders={placeholders}
          placeholdersHelper={placeholdersHelper}
          gaData={gaData}
          selectedAt={selectedAt}
          gaItemProps={gaItemProps}
          prevUsMeasurement={prevUsMeasurement}
          conceptionMethod={conceptionMethod}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={timezone}
          pregnancyLengthInDays={reportDataOptions.pregnancy_length_in_days}
          reportDataOptions={reportDataOptions}
        />
        {reportMode === "edit" && (
          <div className="ga-item add-row" onClick={addRow}>
            <div className="plus-icon">
              <Icon name="add" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const WithTranslationReportTemplateGaTableBody = withTranslation()(
  ReportTemplateGaTableBody
);

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateGaTable({
  props,
  apiVersion,
  placeholders,
  ...otherProps
}) {
  /* If the placeholder is not loaded put an empty array */
  const availableSlugs =
    placeholders["ga.estimations"] && placeholders["fetus.order"]
      ? new PlaceholdersHelper({
          ...otherProps,
          apiVersion,
          props,
          placeholders,
        }).selectedValue("ga.estimations", 0)?.value
      : [];

  const requiredEstimationPlaceholders = availableSlugs;

  const templateLabels = props.labels?.split("|") || [];
  const templateMethods = props.methods?.split("|") || [];

  // No need to make them uniq as PlaceholderLoader will take care of it
  const requiredPlaceholders = [
    "dating.table",
    "patient.nb_fetuses",
    "episode.lmp_date",
    "episode.cycle_length",
    "episode.conception_date",
    "episode.conception_method",
    "episode.embryo_transfer_day",
    "episode.prev_ultrasound_option",
    "episode.prev_ultrasound_ga",
    "episode.prev_ultrasound_exam_date",
    "episode.prev_ultrasound_biometry_value",
    "episode.edd_methods",
    "episode.estimated_delivery_date",
    "episode.embryo_transfer_date",
    "episode.prev_ultrasound_exam_date",
    "ga.estimations",
    "ga.none",
    "ga.assigned.exam",
    "ga.assigned.fetus",
    "ga.assigned.method",
    "ga.assigned.value",
    "ga.assigned.selected_at",
    "examination.id",
    "examination.date",
    "fetus.name",
    "fetus.order",
    "site.timezone",
    ...templateMethods.map((t) => `ga.${t}`),
    ...requiredEstimationPlaceholders,
  ];

  return (
    <PlaceholderLoader
      Component={WithTranslationReportTemplateGaTableBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      templateLabels={templateLabels}
      templateMethods={templateMethods}
      availableSlugs={availableSlugs}
      apiVersion={apiVersion}
      props={props}
      {...otherProps}
    />
  );
}
