import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

function logoUrlFromSite(siteId) {
  return `/api/v2/site/${siteId}/header`;
}

function ReportTemplateLogoBody(fullProps) {
  const { props } = fullProps;
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const siteId = placeholdersHelper.selectedValue("site.id")?.value;
  const hasLogo = placeholdersHelper.selectedValue("site.has_logo")?.value;

  const url = props?.src ? props.src : logoUrlFromSite(siteId);
  const image = !!url && hasLogo ? <img src={url} /> : null;

  return <div className="logo">{image}</div>;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateLogo({
  props,
  placeholders,
  ...otherProps
}) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateLogoBody}
      placeholders={placeholders}
      requiredPlaceholders={["site.id", "fetus.order", "site.has_logo"]}
      props={props}
      {...otherProps}
    />
  );
}
