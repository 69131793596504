import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import Icon from "../Icon/Icon";
import LoaderInline from "../LoaderInline/LoaderInline";

const Button = ({
  variant = "contained", // contained, outline, link, contained-shadow, dim, text
  size = "medium", // full-width | medium | small | input
  icon = "",
  iconAfter = "",
  label = "",
  color = "blue",
  fontSize = "",
  hidden = false,
  hint = "",
  disabled = false,
  isLoading = false,
  onClick = () => { },
  onClickDisabled = () => { },
  ...props
}) => {
  const fontClass = fontSize !== "" ? `fontsize-${fontSize}` : ""
  const hiddenClass = hidden ? 'hidden' : ''

  const gapLeftClass = (!!iconAfter && !!label) ? "gap-left" : "";
  const gapRightClass = (!!icon && !!label) ? "gap-right" : "";

  const onClickHandler = (e) => !disabled ? onClick(e) : onClickDisabled(e);

  return (
    <button
      className={`button ${size} ${variant} ${gapLeftClass} ${gapRightClass} color-${color} ${fontClass} ${hiddenClass}`}
      data-disabled={disabled}
      onClick={onClickHandler}
      {...props}
    >
      {!!icon && (<Icon name={icon} />)}
      {(!!isLoading) && <><LoaderInline />&nbsp;</>}
      {!!label && (<span>{label}</span>)}
      {!!hint && (<span className="hint">{hint}</span>)}
      {!!iconAfter && (<Icon name={iconAfter} />)}
    </button>
  );
};

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
};
