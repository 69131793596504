import { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { defaultLabel } from "../../../../services/measurements";
import { convertValueToSelectedUnit } from "../../../../unitConverter";

import Icon from "../../../../atoms/Icon/Icon";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

import BiometryGraph from "../../../BiometryGraph";

import { PlaceholdersHelper } from "../../placeholders";

import {
  measurementCurvePlaceholderId,
  measurementCurve,
  measurementCruveSlug,
  measurementCurveRequiredPlaceholders,
  isMeasurementCurveEmpty,
  measurementCurveData,
} from "../../utils";

import PlaceholderLoader from "../../PlaceholderLoader";
import EditBiometry from "../../../../pages/ExaminationReport/editors/EditBiometry";

const ReportTemplateMeasurementCurveBody = (fullProps) => {
  const {
    t: __,
    i18n: { language: currentLanguage },
    props,
    placeholders,
    editingFieldId,
    endEditingField,
    startEditingField,
    reportMode,
    onEndEditing,
    reportDataOptions,
    apiVersion,
    parentShowOptions,
  } = fullProps;

  const fieldId = measurementCurvePlaceholderId(props);
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const visible = placeholdersHelper.curveVisible(fieldId);
  const curveSlug = measurementCruveSlug(props, placeholders, apiVersion);
  const curve = measurementCurve(
    props,
    placeholders,
    reportDataOptions,
    apiVersion
  );
  const {
    storedUnit,
    displayUnit,
    yAxis,
    xAxis,
    isAtRisk,
    allMeasurements,
    fetus,
    placeholder: rawPlaceholder,
  } = measurementCurveData(props, placeholders, reportDataOptions, apiVersion);

  const placeholder = rawPlaceholder ?? {};
  const [isEditing, setIsEditing] = useState(false);
  // TODO understand this line as originalPlaceholder should be an array
  const showOptions =
    reportMode === "edit" &&
    (parentShowOptions ?? placeholders[fieldId]?.showOptions ?? true);

  const { data, percentile = "%", decimals = "2", units: userUnits } = props;
  const graph_visible = placeholdersHelper.curveVisible(fieldId);
  const isEditable = placeholdersHelper.editable(fieldId);

  const [measurementId, bodyStructureId, lateralityId] =
    props.data.split(/[./]/);
  const editedLabel =
    placeholder.edited_label?.[currentLanguage] ?? placeholder.edited_label;
  const label =
    editedLabel ||
    defaultLabel(
      measurementId,
      bodyStructureId,
      lateralityId,
      reportDataOptions.labels,
      currentLanguage
    );

  useEffect(() => {
    if (editingFieldId !== data) setIsEditing(false);
  }, [editingFieldId, data]);

  const onClose = useCallback(
    (_fieldId, data) => {
      data.graph_visible = data.visible_graph;

      onEndEditing(
        fieldId,
        placeholdersHelper.editSelectedDataValue(fieldId, data, null, "user")
      );
      endEditingField();
    },
    [onEndEditing, endEditingField]
  );

  const placeholderEditor = useCallback(() => {
    const measurementId = fieldId;
    const data = {
      editingGraph: true,
      decimals,
      userUnits,
      defaultUnit: storedUnit,
      curveSlug,
      availableCurveSlugs: placeholder.available_cruve_slugs,
      availableDerivations: placeholder.available_derivations,
      label,
      visible_graph: visible,
    };
    return (
      <EditBiometry
        id={measurementId}
        fetus={fetus || "patient"}
        data={{ ...placeholder, ...data }}
        reportDataOptions={reportDataOptions}
        close={onClose}
      />
    );
  }, [fieldId, fetus, placeholder, onClose, visible]);

  if (!allMeasurements?.length || !allMeasurements[0]?.length) return false;
  const toggleEditing = () => {
    if (isEditable && editingFieldId !== data) {
      startEditingField(data, props);
      setIsEditing(true);
    }
  };

  const toggleVisible = (e) => {
    e.stopPropagation();
    const { value: _, ...selectedDatum } =
      placeholdersHelper.selectedDatum(fieldId);
    onEndEditing(
      `measurement.${props.data}`,
      [{ ...selectedDatum, graph_visible: !graph_visible }],
      { BIContext: { component: "measurement-curve" } }
    );
  };

  return isMeasurementCurveEmpty(
    props,
    placeholders,
    reportDataOptions,
    apiVersion
  ) ? (
    <div className="measurement-curve empty" />
  ) : (
    /* don't change "measurement-curve not-visible" order because it impacts on visibility inside the <graphs> component */
    <div
      className={`
      exam-report-editing-field measurement-curve ${
        graph_visible ? "is-visible" : "not-visible"
      }
      ${graph_visible ? "has-printable-value" : ""}
      ${isEditable ? "is-editable" : ""}
      ${isEditing && editingFieldId === data ? "editing" : ""}
    `}
      onClick={toggleEditing}
    >
      <div className="measurement-curve-label">{label}</div>
      <div
        className={`measurement-curve-values ${isAtRisk ? "highlight" : ""}`}
      >
        <div className="measurement-curve-value">
          {placeholder.value && (
            <>
              {convertValueToSelectedUnit(
                Number(placeholder.value),
                storedUnit,
                displayUnit
              ).toFixed(Number(decimals))}{" "}
              {displayUnit}
            </>
          )}
        </div>
        <div className="measurement-curve-percentile">
          {placeholder.sonio_percentile && (
            <>
              {percentile === "%" && (
                <>
                  <span className="biometry-item-percentile-value percent">
                    {placeholder.sonio_percentile || "< 1"}
                  </span>
                  {placeholder.abbreviation}
                </>
              )}
              {percentile === "zscore" && (
                <>
                  <span className="biometry-item-percentile-value zscore">
                    {placeholder.sonio_zscore?.toFixed(2)}
                  </span>
                  &nbsp;{__("report.zscore")}
                </>
              )}
            </>
          )}
          <span className="measurement-curve-growth-standard">
            {curveSlug.slice(curveSlug.lastIndexOf(".") + 1).toUpperCase()}
          </span>
        </div>
      </div>
      <div className="measurement-curve-graph">
        {/* As BiometryGraph is an heavy component we load it with lazyness (timeout) after the whole report is loaded */}
        <BiometryGraph
          lazy={reportMode === "edit" ? 1000 : false}
          xAxis={xAxis}
          yAxis={yAxis}
          equations={curve}
          measurements={allMeasurements}
          atRisk={isAtRisk}
        />
      </div>
      {editingFieldId === data && isEditing && placeholderEditor()}
      {graph_visible && showOptions && (
        <div className="content-options exam-report-editing-options">
          <div onClick={toggleVisible}>
            <Icon name={graph_visible ? "eye" : "eye-off"} />
          </div>
        </div>
      )}
      {!graph_visible && reportMode === "edit" && (
        <NotVisibleOverlay onClick={toggleVisible} />
      )}
    </div>
  );
};

const WithTranslationReportTemplateMeasurementCurveBody = withTranslation()(
  ReportTemplateMeasurementCurveBody
);

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateMeasurementCurve({
  props,
  placeholders,
  reportDataOptions,
  apiVersion,
  ...otherProps
}) {
  const requiredPlaceholders = measurementCurveRequiredPlaceholders(
    props,
    placeholders,
    reportDataOptions,
    apiVersion
  );

  return (
    <PlaceholderLoader
      Component={WithTranslationReportTemplateMeasurementCurveBody}
      placeholders={placeholders}
      props={props}
      requiredPlaceholders={requiredPlaceholders}
      reportDataOptions={reportDataOptions}
      apiVersion={apiVersion}
      {...otherProps}
    />
  );
}
