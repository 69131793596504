import { useEffect, useState } from "react";
import InlineInput from "../../../../atoms/InlineEditing/InlineInput/InlineInput";
import Icon from "../../../../atoms/Icon/Icon";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

import { isNullOrUndefined } from "../../../../utils";
import { MeasurementDefaultUnits } from "../../../../config";
import { convertValueToSelectedUnit } from "../../../../unitConverter";

export default function ReportTemplateContent({
  defaultKey = "",
  props,
  placeholder: originalPlaceholder,
  getPlaceholderWithProps,
  multiSelectValue = false, // defined when used from inside <table>
  appPreferences,
  setRequiredAndInvalid,
  showErrors,
  onEndEditing = () => {},
  showOptions = null,
  fetus: fetusNumber,
  canEdit = false,
  reportMode = "edit",
  measurementsContext,
  highlight = false,
  BIContext: InitialBIContext,
}) {
  let placeholder, fetus;
  showOptions =
    reportMode === "edit" &&
    (showOptions ?? originalPlaceholder?.showOptions ?? true);

  const {
    data,
    label,
    width = null,
    required = null,
    align = null,
    decimals = null,
    custom,
    unit: userUnits,
  } = props ?? {};

  let isMeasurement = false;
  if (props.data?.startsWith("measurement.")) {
    fetus =
      (getPlaceholderWithProps({ data: "fetus.number" })?.value || [])[
        Number(props.fetus ?? fetusNumber ?? 1)
      ] ?? -1;
  } else {
    fetus = Number(props.fetus ?? fetusNumber ?? 1);
  }
  if (Array.isArray(originalPlaceholder) || data?.startsWith("measurement.")) {
    placeholder = originalPlaceholder[fetus];
    isMeasurement = true;
  } else {
    placeholder = {
      ...originalPlaceholder,
      value: Array.isArray(originalPlaceholder.value)
        ? originalPlaceholder.value[fetus]
        : originalPlaceholder.value,
    };
    isMeasurement = true;
  }
  const [editing, setEditing] = useState(false);
  const [autofocusCounter, setAutofocusCounter] = useState(0);

  const { units: reportUnits } = placeholder ?? {};
  let { visible = true } = placeholder ?? {};
  if (visible === null || !!highlight) visible = true;
  const fieldId = custom === "true" ? `custom.${data}` : data;

  const measurementSlug = isMeasurement ? data?.split(".")?.[1] : false;
  const isMultiUnits = userUnits?.includes("."); // eg. feet.inches or lbs.oz
  const displayUnit =
    userUnits ||
    reportUnits ||
    (measurementSlug &&
      measurementsContext?.labels?.measurement?.[measurementSlug]?.units) ||
    "";
  const defaultUnit =
    (measurementSlug &&
      MeasurementDefaultUnits[
        measurementsContext?.labels?.measurement?.[measurementSlug]?.type
      ]) ||
    reportUnits ||
    displayUnit;

  const value = convertValueToSelectedUnit(
    placeholder?.value,
    defaultUnit,
    displayUnit,
    decimals
  );

  const getDisplayedValue = () => {
    let dispValue = value;

    if (multiSelectValue) {
      dispValue = placeholder?.value?.[multiSelectValue];
    }

    if (props.attribute) {
      return multiSelectValue
        ? dispValue?.[props.attribute]
        : placeholder?.[props.attribute];
    }

    return dispValue;
  };

  const style = {};

  if (width) {
    style.minWidth = `min(${width}, 100%)`;
    style.maxWidth = `min(${width}, 100%)`;
  }

  if (align) style.textAlign = align;

  const labelStyle = {};
  if (props["label-width"]) {
    labelStyle.width = props["label-width"];
    labelStyle.minWidth = props["label-width"];
  }

  const isRequired = () => {
    return reportMode === "edit" && required === "true" ? (
      <span className="required">*</span>
    ) : (
      false
    );
  };

  const invalidValue = () => {
    return !value;
  };

  const isMultiValue = () => {
    return (
      placeholder?.format === "multiple" ||
      (!placeholder?.format &&
        placeholder?.value &&
        typeof placeholder?.value === "object")
    );
  };

  useEffect(() => {
    if (required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ? prevState.add(fieldId) : prevState.delete(fieldId);
        return prevState;
      });
    }
  }, [value, setRequiredAndInvalid]);

  const saveChange = (updates) => {
    setTimeout(setEditing(false), 200);
    if (!props.data) return false;

    if (
      !isNullOrUndefined(updates.value) &&
      (props.data.startsWith("measurement.") || displayUnit !== defaultUnit)
    ) {
      if (isMultiUnits && !Array.isArray(updates.value))
        updates.value = [updates.value, 0];
      /* If the input is:
       * - multi unit
       * - one element returned is empty
       * - the others are 0 we should clear the input
       */
      if (
        isMultiUnits &&
        Array.isArray(updates.value) &&
        updates.value.some((v) => v === "" || isNullOrUndefined(v)) &&
        updates.value.every((v) => v === "" || isNullOrUndefined(v) || v === 0)
      ) {
        updates.value = "";
      } else {
        updates.value = convertValueToSelectedUnit(
          updates.value,
          displayUnit,
          defaultUnit
        );
      }
      if (Array.isArray(updates.value))
        updates.value = updates.value?.join(" ");
      if (props.data.startsWith("measurement.")) updates.derivation = "edit";
    }
    if (fetus !== null) updates.fetus = fetus === 0 ? "patient" : fetus;

    const id = props.data;
    const custom = props.custom === "true";

    if (
      isMultiValue() &&
      multiSelectValue &&
      !isNullOrUndefined(updates.value)
    ) {
      const newValue = placeholder.value;
      const attr = props.attribute || value;
      if (newValue?.[multiSelectValue]) {
        newValue[multiSelectValue][attr] = updates.value;
      }
      updates = { value: newValue };
    }

    if (fieldId.startsWith("custom.") && !isNullOrUndefined(updates.value)) {
      const newUpdatesValue = Array.isArray(originalPlaceholder.value)
        ? originalPlaceholder.value || ""
        : [originalPlaceholder.value || "", originalPlaceholder.value || ""];
      newUpdatesValue[fetus] = updates.value;
      updates.value = newUpdatesValue;
      delete updates.fetus;
    }

    const BIContext = {
      ...InitialBIContext,
      component: "content",
      format: placeholder?.format,
    };
    onEndEditing(id, { ...updates, fetus }, custom, { BIContext });
  };

  const focusOnInput = () => {
    setAutofocusCounter((c) => ++c);
  };

  return (
    <div
      key={defaultKey}
      className={`
        content-wrapper exam-report-editing-field
        ${
          (!isMultiUnits && !isNullOrUndefined(value) && value !== "") ||
          (isMultiUnits && value.some((v) => !!v))
            ? visible
              ? "has-printable-value"
              : ""
            : "not-printable"
        }
        ${
          required === "true" && invalidValue() && showErrors
            ? "required-error"
            : ""
        }
        ${!label || props.inline ? "is-inline" : "is-block"}
        ${visible ? "is-visible" : "not-visible"}
        ${align ? "align-" + align : ""}
        ${props?.data ? "is-editable" : ""}
        ${editing ? "is-editing" : ""}
      `}
      style={style}
    >
      {!!label && (
        <div className="label" style={labelStyle} onClick={focusOnInput}>
          {label} {isRequired()}
        </div>
      )}
      <span className={`${highlight ? "highlight-field" : ""}`}>
        {isMultiUnits ? (
          <>
            <div
              className="content-text exam-report-editing-value"
              data-editing={editing === "value"}
            >
              <InlineInput
                autofocus={autofocusCounter}
                value={getDisplayedValue()?.[0] ?? ""}
                format={placeholder?.format}
                decimals="0"
                printable={visible}
                onStartEditing={() => setEditing("value")}
                onChange={(newValue) =>
                  saveChange({ value: [newValue, value?.[1] || 0] })
                }
                appPreferences={appPreferences}
                active={canEdit}
              />
            </div>
            {displayUnit ? " " + displayUnit.split(".")[0] : ""}
            &nbsp;&nbsp;&nbsp;
            <span
              className={`${
                Number(
                  Number(getDisplayedValue()?.[1]).toFixed(props.decimals || 8)
                )
                  ? ""
                  : "not-printable"
              }`}
            >
              <div
                className="content-text exam-report-editing-value"
                data-editing={editing === "value"}
              >
                <InlineInput
                  value={getDisplayedValue()?.[1] ?? ""}
                  format={placeholder?.format}
                  decimals={props.decimals}
                  printable={visible}
                  onStartEditing={() => setEditing("value")}
                  onChange={(newValue) =>
                    saveChange({ value: [value?.[0] || 0, newValue] })
                  }
                  appPreferences={appPreferences}
                  active={canEdit}
                />
              </div>
              {displayUnit ? " " + displayUnit.split(".")[1] : ""}
            </span>
          </>
        ) : (
          <>
            <div
              className="content-text exam-report-editing-value"
              data-editing={editing === "value"}
            >
              <InlineInput
                autofocus={autofocusCounter}
                value={getDisplayedValue()}
                format={placeholder?.format}
                decimals={props.decimals}
                printable={visible}
                onStartEditing={() => setEditing("value")}
                onChange={(value) =>
                  saveChange(
                    !isMultiValue() && props.attribute
                      ? { [props.attribute]: value }
                      : { value }
                  )
                }
                appPreferences={appPreferences}
                active={canEdit}
              />
            </div>
            {displayUnit ? " " + displayUnit : ""}
          </>
        )}
        {!label && isRequired()}
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ""}>
            <Icon name={highlight.icon} />
          </span>
        )}
        {!highlight &&
          canEdit &&
          visible &&
          reportMode === "edit" &&
          showOptions && (
            <div className="content-options exam-report-editing-options">
              <div onClick={() => saveChange({ visible: !visible })}>
                <Icon name={visible ? "eye" : "eye-off"} />
              </div>
            </div>
          )}
      </span>
      {!visible && reportMode === "edit" && (
        <NotVisibleOverlay onClick={() => saveChange({ visible: !visible })} />
      )}
    </div>
  );
}
