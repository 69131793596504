import ReportTemplateMedicalHistory_v1_0 from './MedicalHistory/v1.0.js';
import ReportTemplateMedicalHistory_v1_1 from './MedicalHistory/v1.1.js';
import ReportTemplateMedicalHistory_v2_0 from './MedicalHistory/v2.0.js';

export default function ReportTemplateMedicalHistory(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateMedicalHistory_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateMedicalHistory_v1_1(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateMedicalHistory_v2_0(props);
  }
  return null;
};
