import React, { useState, useEffect, useCallback } from "react";

import { propsToTag } from "./utils";
import { isNullOrUndefined } from "../../utils";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function PlaceholderLoader({
  requirePlaceholders,
  requiredPlaceholders,
  placeholders,
  Component,
  props,
  reportMode,
  onEndEditing: contextOnEndEditing,
  ...otherProps
}) {
  if (!Array.isArray(requiredPlaceholders))
    throw new Error(
      `requiredPlaceholders must be an array of placeholders ID.\nError in ${propsToTag(
        props
      )}`
    );
  const [requiringPlacehodlers, setRequiringPlaceholders] = useState(false);

  requiredPlaceholders = (requiredPlaceholders || []).filter(onlyUnique).sort();

  const [localPlaceholders, setLocalPlaceholders] = useState(
    placeholders ?? {}
  );

  useEffect(() => {
    setLocalPlaceholders((localPlaceholders) => {
      const entries = Object.entries(placeholders)
        .filter(([_, v]) => !isNullOrUndefined(v))
        .map(([key, placeholder]) => {
          if (placeholder.update_guid === localPlaceholders[key]?.update_guid) {
            /* the placeholder has not yet been updated by the backend */
            return [key, localPlaceholders[key]];
          } else {
            /* the placeholder has been updated by the backend */
            return [key, placeholder];
          }
        });
      return Object.fromEntries(entries);
    });
  }, [
    Object.entries(placeholders)
      .filter(([_, v]) => !isNullOrUndefined(v))
      .map(([key, { update_guid }]) => `${key}-${update_guid}`)
      .sort()
      .join("|"),
  ]);

  const placeholdersLoaded = requiredPlaceholders.every(
    (placeholder) => !!localPlaceholders[placeholder]
  );
  const toLoadPlaceholders = requiredPlaceholders.join("|");

  const onEndEditing = useCallback(
    (slug, newPlaceholders, _opts = {}) => {
      setLocalPlaceholders((localPlaceholders) => {
        const nonUpdatedData = (localPlaceholders[slug]?.data || []).filter((datum) => {
          return !newPlaceholders.find(
            (d) =>
              d.examination_fetus_id === datum.examination_fetus_id &&
              d.source === datum.source
          );
        });
        const updatedData = newPlaceholders.map((d) => {
          const oldDatum = (localPlaceholders[slug]?.data || []).find(
            (datum) =>
              d.examination_fetus_id === datum.examination_fetus_id &&
              d.source === datum.source
          );
          return { ...(oldDatum ?? {}), ...d };
        });
        return {
          ...localPlaceholders,
          [slug]: {
            ...localPlaceholders[slug],
            data: [...nonUpdatedData, ...updatedData],
          },
        };
      });
      contextOnEndEditing(slug, newPlaceholders, (_opts = {}));
    },
    [setLocalPlaceholders, contextOnEndEditing]
  );

  // TODO we can hide that behind a custom hook
  useEffect(() => {
    /* useEffect is not called in printing mode. This is added just to be explicit */
    if (reportMode === "print") return;
    /* Placeholder is not loaded yet */
    if (!placeholdersLoaded && requiringPlacehodlers !== toLoadPlaceholders) {
      setRequiringPlaceholders(toLoadPlaceholders);
      requirePlaceholders(props, requiredPlaceholders);
      return;
    }
    /* Placeholder is now loaded */
    if (placeholdersLoaded && !!requiringPlacehodlers) {
      setRequiringPlaceholders(false);
    }
  }, [toLoadPlaceholders, placeholders]);
  /* If we are in print mode, useEffect is not called, we need to call this our self */
  if (reportMode === "print" && !placeholdersLoaded) {
    requirePlaceholders(props, requiredPlaceholders);
  }

  // TODO - Add a loading spinner
  if (!placeholdersLoaded) return null;

  return (
    <Component
      {...otherProps}
      reportMode={reportMode}
      requirePlaceholders={requirePlaceholders}
      placeholders={localPlaceholders}
      props={props}
      onEndEditing={onEndEditing}
    />
  );
}
