import { useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
/* Atoms */
import InlineMultiSelect from "../../../../atoms/InlineEditing/InlineMultiSelect/InlineMultiSelect";
import Icon from "../../../../atoms/Icon/Icon";
/* Utils */
import { isNullOrUndefined } from "../../../../utils";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import { placeholderIdFromProps } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

function ReportTemplateDropdownBody(fullProps) {
  const {
    children,
    props,
    setRequiredAndInvalid,
    loadDynamicDropdownFullTree,
    showErrors,
    highlight = false,
    onEndEditing = () => {},
    showOptions: parentShowOptions = null,
    reportMode,
    canEdit = false,
    BIContext: InitialBIContext,
    placeholders,
    setPlaceholderDefaultValue,
  } = fullProps;
  const [editing, setEditing] = useState(false);

  // TODO understand how we will pass the infor

  const fieldId = placeholderIdFromProps(props);
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const isDynamic = placeholdersHelper.isDynamicDropdown(fieldId);
  const isMultiple = placeholdersHelper.isMultiple(fieldId);
  const selectedDatum = placeholdersHelper.selectedDatum(fieldId);
  const visible = highlight ? true : selectedDatum?.visible ?? true;
  const preventUpdatesWhenHidden =
    placeholdersHelper.preventUpdatesWhenHidden(fieldId);

  const attribute = placeholdersHelper?.attribute(fieldId);
  const value = placeholdersHelper.selectedValue(fieldId)?.[attribute];
  const format = placeholdersHelper.format(fieldId);
  const BIContext = {
    ...InitialBIContext,
    component: isDynamic ? "dynamic-dropdown" : "dropdown",
    format,
  };

  const onCloseHandler = () => {
    setTimeout(() => setEditing(false), 200);
    window.removeEventListener("click", onCloseHandler, false);
  };

  useEffect(() => {
    if (editing === "value") {
      window.removeEventListener("click", onCloseHandler, false);
      window.addEventListener("click", onCloseHandler, false);
    }
  }, [editing]);

  // showOptions is only used for fetus.sex. So for the moment it will not be part of PlaceholdersHelper
  const showOptions =
    reportMode === "edit" &&
    (parentShowOptions ?? placeholders[fieldId]?.showOptions ?? true);

  const onFieldMouseOver = (_e) => {
    if (isDynamic) {
      loadDynamicDropdownFullTree && loadDynamicDropdownFullTree(fieldId);
    }
  };

  const getOptionById = (id, tree) => {
    if (!tree || !id) return false;

    let elm = tree.find(
      (node) => `${node.id}`.toLowerCase() === `${id}`.toLowerCase()
    );
    if (elm) return elm;

    for (const node of tree) {
      elm = getOptionById(id, node.tree);
      if (elm) return elm;
    }

    return elm;
  };

  const updateOption = (option, tree) => {
    if (!tree) return [];
    if (!option) return tree;

    const foundNodeIndex = tree.findIndex((node) => node.id === option.id);
    if (foundNodeIndex >= 0) {
      tree[foundNodeIndex] = { ...tree[foundNodeIndex], ...option };
    }
    tree.map((node) => ({ ...node, tree: updateOption(option, node?.tree) }));

    return tree;
  };

  const getLabel = (children) => {
    const label = renderToStaticMarkup(children);
    if (!label) return "";
    return label.replace("\n", "").replace("\r", "").trim();
  };

  // TODO Begin - we can not use a renderToStaticMarkup inside a useMemo inside a renderToStaticMarkup
  // Once the renderToStaticMarkup is removed we can add back the useMemo for perf gains

  // TODO transform this into a useMemo
  // note how for the moment it is not a function but a function call result
  const options = (() => {
    let options = [...placeholdersHelper.options(fieldId)];

    for (const option of children?.filter((c) => c?.props?.type === "option") ||
      []) {
      const alreadyExisting = getOptionById(option.props.props.value, options);

      const value =
        option.props.props.value ?? option.props.children.toString();
      const isDefault = !!option.props.props.default;
      if (alreadyExisting) {
        options = updateOption(
          {
            value,
            id: value,
            label: getLabel(option.props.children) || alreadyExisting.label,
            default: isDefault,
          },
          options
        );
      } else {
        options.push({
          value,
          id: value,
          label: getLabel(option.props.children),
          default: isDefault,
          selectable: true,
        });
      }
    }

    if (isMultiple) {
      /*
       * If it is a dynamic dropdown we enrich the options with the selected values
       * Thus we are sure it displays correctly even if the option is not loaded
       */
      Object.entries(value ?? {}).forEach(([key, value]) => {
        if (getOptionById(key, options) === false) {
          options.push(value);
        }
      });
    }

    options = options.map((option) => ({
      ...option,
      selectable: option.selectable ?? true,
    }));

    if (!isNullOrUndefined(props.default)) {
      options = options.map((option) => {
        const isDefault =
          option.id.toLowerCase() === props.default.toLowerCase();

        return {
          ...option,
          default: isDefault,
          selectable: isDefault || (option.selectable ?? true),
        };
      });
    }

    if (isMultiple) {
      options = options.filter((option) => option?.id || !option.selectable);
    }

    return options;
  })();
  // TODO END add back the useMemo
  // }, [placeholder?.tree, children]);

  const getDefaultValue = (tree) => {
    if (!tree) return [];

    let nodes = Object.fromEntries(
      tree
        .filter((node) => node.selectable && node.default)
        .map((node) => [node.id, { value: true }])
    );

    for (const node of tree) {
      nodes = { ...nodes, ...getDefaultValue(node.tree) };
    }
    return nodes;
  };

  useEffect(() => {
    let defaultValue = Object.keys(getDefaultValue(options));
    if (!isMultiple) {
      defaultValue = defaultValue[0];
    }
    setPlaceholderDefaultValue(fieldId, defaultValue);
  });

  const isValidOption = (tree, value) => {
    if (!Array.isArray(tree) || !tree.length) return [];

    let isValid = tree.some(
      (node) =>
        (isNullOrUndefined(node.selectable) || node.selectable) &&
        (node.id === value ||
          (isNullOrUndefined(node.id) && node.label === value))
    );
    if (isValid) return true;

    for (const node of tree) {
      if (node.tree) {
        isValid = isValidOption(node.tree, value);
        if (isValid) return true;
      }
    }
    return isValid;
  };

  // TODO save default value
  const getValue = () => {
    if (isMultiple) {
      return Object.keys(
        Array.isArray(value) ? {} : value || getDefaultValue(options)
      );
    }
    return isValidOption(options, value)
      ? value
      : value === 0
      ? value
      : Object.keys(getDefaultValue(options) || [])?.[0];
  };

  const valueIsEmpty = () => {
    const value = getValue();
    return Array.isArray(value) ? value.filter((v) => v).length === 0 : !value;
  };

  const saveChange = (value) => {
    if (!props.data) return false;

    let newValue = value;
    if (isMultiple) {
      const oldValue = placeholdersHelper.selectedValue(fieldId).value || {};
      newValue = Object.fromEntries(
        value.map((v) => [
          v,
          v in oldValue ? oldValue[v] : getOptionById(v, options),
        ])
      );
    }
    onEndEditing(
      fieldId,
      placeholdersHelper.editSelectedDataValue(
        fieldId,
        { [attribute]: newValue },
        null,
        "user"
      ),
      { BIContext }
    );
    onCloseHandler();
  };

  const changeVisibility = (visible) => {
    const { value: _, ...datum } = selectedDatum;
    datum.visible = visible;
    onEndEditing(fieldId, [datum], { BIContext });
  };

  const invalidValue = () => {
    const selectedValue = getValue();
    return selectedValue && typeof selectedValue === "object"
      ? !Object.keys(selectedValue).length
      : !selectedValue;
  };

  useEffect(() => {
    if (props.required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue()
          ? prevState.add(props.data)
          : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [value, setRequiredAndInvalid, children]);

  const isRequired = () => {
    return reportMode === "edit" && props.required === "true" && canEdit ? (
      <span className="required">*</span>
    ) : (
      false
    );
  };

  const labelStyle = {};
  if (props["label-width"]) {
    labelStyle.width = props["label-width"];
    labelStyle.minWidth = props["label-width"];
  }

  return (
    <div
      className={`
      dropdown-wrapper exam-report-editing-field
      ${
        !valueIsEmpty()
          ? visible
            ? "has-printable-value"
            : ""
          : "not-printable"
      }
      ${visible ? "is-visible" : "not-visible"}
      ${props.fullwidth ? "full-width" : ""}
      ${!props.label || props.inline ? "is-inline" : "is-block"}
      ${isRequired() ? "is-required" : ""}
      ${
        props.required === "true" && invalidValue() && showErrors
          ? "required-error"
          : ""
      }
    `}
      onMouseOver={onFieldMouseOver}
    >
      {!props.compact && !!props.label && (
        <div className="label" style={labelStyle}>
          {props.label} {isRequired()}
        </div>
      )}
      <span className={highlight ? "highlight-field" : ""}>
        <InlineMultiSelect
          value={getValue()}
          options={options.map((option) => ({
            ...option,
            label: option.label,
            id: option.id,
          }))}
          printable={visible}
          multiple={isMultiple}
          compactMode={props.compact || false}
          compactModeLabel={props.label || undefined}
          fullwidth={props.fullwidth || false}
          onChange={(value) => saveChange(value)}
          showRecent={props.data}
          showMostUsed={props.data}
          showSearchBar="auto"
          disabled={reportMode === "print"}
          active={canEdit}
        />
        {!props.label && isRequired()}
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ""}>
            <Icon name={highlight.icon} />
          </span>
        )}
        {!highlight &&
          canEdit &&
          visible &&
          reportMode === "edit" &&
          showOptions && (
            <div className="dropdown-options exam-report-editing-options not-printable">
              <div onClick={() => changeVisibility(!visible)}>
                <Icon name={visible ? "eye" : "eye-off"} />
              </div>
            </div>
          )}
      </span>
      {!visible && reportMode === "edit" && (
        <NotVisibleOverlay
          onClick={() => changeVisibility(true)}
          preventUpdates={preventUpdatesWhenHidden}
        />
      )}
    </div>
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateDropdown({
  props,
  placeholders,
  ...otherProps
}) {
  const fieldId = placeholderIdFromProps(props);

  return (
    <PlaceholderLoader
      Component={ReportTemplateDropdownBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, "fetus.order"]}
      props={props}
      {...otherProps}
    />
  );
}
