import { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import TextInput from "../../atoms/TextInput/TextInput";
import Icon from "../../atoms/Icon/Icon";
import ArrangeOnTop from "../../atoms/ArrangeOnTop/ArrangeOnTop";
import "./SearchBar.css";

/*
 * Puting those 2 items definition outside of the SearchBar component
 * Allow us to decorelate onBlur and onClick effect
 * I think this is due to a rerender that destroys the definition of these functions
 * that should never be redefined
 */

const LineLabel = ({ result }) => {
  if (result.icon) {
    return (
      <>
        <Icon name={result.icon} /> {result.label}
      </>
    );
  }
  return result.label;
};

const Line = withTranslation()(({ t: __, result, index, onClick }) => {
  const localOnClick = () =>
    typeof result === "object"
      ? onClick({ ...result, index })
      : onClick(result);
  return (
    <li
      className={`search-bar_result type-${result.type}`}
      onClick={localOnClick}
    >
      <span className="search-bar_result_label">
        <LineLabel result={result} />
      </span>
      <span className="search-bar_result_source">
        {result.tags?.map((tag) => (
          <span key={tag} className="search-bar_tag">
            {tag}
          </span>
        ))}
        {(result.library?.map((l) => __("searchbar.library." + l)) || []).join(
          ", "
        )}
      </span>
    </li>
  );
});

/**
 * searchKey: String
 * placeholder: String
 * results: [{type, label, key}]
 *    type: "heading" | "warning" | "normal"
 *    label: String
 *    icon: String
 *    key: String | Integer
 * onSelect: (result) => {}
 * loading: Boolean
 * opts: {
 *  onBlurDebounceDelay: Delay | default: 200
 * }
 */

const SearchBar = ({
  searchKey,
  filters,
  placeholder = "",
  onSelect,
  onBlur,
  onChange,
  loading,
  results,
  quickFilters,
}) => {
  const resultsRef = useRef(null);
  const componentRef = useRef(null);

  const [resultsAreVisible, setResultsAreVisible] = useState(false);
  const textInputRef = useRef(false);

  /* Type protection */
  if (typeof onSelect !== "function")
    throw new Error("SearchBar onSelect property expected to be a function");

  const closeDropdown = useCallback(
    (e) => {
      if (
        !resultsRef.current?.contains(e.target) &&
        !componentRef.current?.contains(e.target)
      ) {
        if (typeof onBlur === "function" && onBlur) onBlur();
        setResultsAreVisible(false);
      }
    },
    [onBlur, setResultsAreVisible]
  );

  useEffect(() => {
    window.addEventListener("click", closeDropdown);
    return () => window.removeEventListener("click", closeDropdown);
  }, [closeDropdown]);

  const onFocusHandler = () => {
    setResultsAreVisible(true);
  };

  const onChangeHandler = (value) => {
    setResultsAreVisible(true);
    if (typeof onChange === "function" && onChange) onChange(value);
  };

  const onClickResult = (result) => {
    /* onSelect return true if input needs to be closed.
     * If this is not the case we refocus the input.
     */

    if (!onSelect(result)) {
      setResultsAreVisible(true);
    } else {
      setResultsAreVisible(false);
    }
  };

  useEffect(() => {
    onChange(searchKey);
    if (searchKey) textInputRef.current.focus();
  }, [filters]);

  return (
    <div
      className="search-bar_wrapper"
      ref={componentRef}
      style={{ zIndex: resultsAreVisible ? 1001 : 10 }}
    >
      <div className="search-bar_search">
        <TextInput
          ref={textInputRef}
          fullwidth="true"
          placeholder={placeholder}
          value={searchKey}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          changeOnBlur={false}
          bounceDelay={0}
          icon="search"
          loading={loading}
        />
        <div className="search-bar_inline-cta">
          {!!searchKey && (
            <span className="search-bar_clean" onClick={() => onChange("")}>
              <Icon name="close" />
            </span>
          )}
          {quickFilters && (
            <span
              className="search-bar_quick-filters"
              onClick={() => onChange(searchKey)}
            >
              {quickFilters}
            </span>
          )}
        </div>
      </div>
      <ArrangeOnTop maxWidth={textInputRef.current?.offsetWidth}>
        <div className="search-bar_dropdown_container" ref={resultsRef}>
          <div className="search-bar_dropdown_results_wrapper">
            {resultsAreVisible && results?.length > 0 && (
              <ul
                className="search-bar_results"
                style={{ display: resultsAreVisible ? "block" : "none" }}
              >
                {results.map((result, index) => (
                  <Line
                    key={result.key || index}
                    index={index}
                    result={result}
                    onClick={onClickResult}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      </ArrangeOnTop>
    </div>
  );
};

export default SearchBar;
