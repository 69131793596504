import ReportTemplateFetus_v1_0 from './Fetus/v1.0.js';
import ReportTemplateFetus_v1_1 from './Fetus/v1.1.js';
import ReportTemplateFetus_v2_0 from './Fetus/v2.0.js';

export default function ReportTemplateFetus(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateFetus_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateFetus_v1_1(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateFetus_v2_0(props);
  }
  return null;
};
