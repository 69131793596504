import { useState, useCallback } from 'react';
/* 
 * Custom hook to collect into a state and only update the application after the delay
 * This allows to avoid too many updates when the state is updated by too many different components
 *
 * Note: using the state setter wihtout a function will immediately update the state without the timeout mechanism
 *
 * @param {any} initialState - The initial state of the collector
 * @param {number} delay - The delay in milliseconds to wait before updating the state
 * @return [state, setState] - Exact same API as useState hook
 */
export function useCollector(initialState, delay = 100) {
  const [currentState, doSetState] = useState({ live: initialState, defered: initialState, timeout: null });

  const setState = useCallback((newState) => {
    if (typeof newState === 'function') {
      clearTimeout(currentState.timeout);
      currentState.live = newState(currentState.live);
      currentState.timeout = setTimeout(() => {
        const live = currentState.live;
        doSetState((currentState) => {
          return ({ ...currentState, defered: live, live: live })
        });
      }, delay);
    } else {
      clearTimeout(currentState.timeout);
      doSetState((currentState) => ({ ...currentState, live: newState, defered: newState }));
    }
  }, [doSetState]);

  return [currentState.defered, setState];
}
