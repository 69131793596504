import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { withTranslation } from "react-i18next";
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import './UploadFilePopup.css';

const MAX_SIZE_IN_BYTES = 5e7; // 50MB ~ 50,000,000 bytes
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

const SharingUploadFilePopup = ({ onClose, onSuccesfulUpload, t: __, submit, loadNewList }) => {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const isDisable = files.length === 0 || isLoader;
  const [isDragOn, setIsDragOn] = useState(false);
  const refDragOnStatus = useRef(false);

  const removeFile = (title) => setFiles(list => list.filter((item) => item.title !== title));

  const cancelEventClick = (event) => event.stopPropagation();

  const addStatusFile = (title) => {
    setFiles(list => list.map((item) => ({
      ...item,
      ...(item.title === title ? { savedOnServer: true } : {})
    })))
  }
  const addServerError = err => setErrors(errors => [
    ...errors,
    __(`sharingPreferences.document.upload.${err}`)
  ]);

  const handleFileChange = async (e) => {
    const newFiles = Array.from(e?.target?.files) ?? [];
    refDragOnStatus.current = files.length > 0;
    let errors = []
    if (newFiles.length === 0) {
      errors = [...errors, 'File is empty'];
    }
    newFiles.forEach((file) => {
      if (file.size >= MAX_SIZE_IN_BYTES) {
        errors = [...errors, __("sharingPreferences.document.uploadTooBig")];
      }
      if (file.type && !ALLOWED_FILE_TYPES.includes(file.type)) {
        errors = [...errors, __("examination.popup.errorFileMustBe")];
      };
    });
    if (errors.length === 0) {
      setErrors([]);

      setFiles((list) => [
        ...list,
        ...newFiles
          .map(file => {
            const name = file.name;
            const title = file.name;
            return {
              file,
              title,
              name,
            }
          }) // remove duplicates
          .filter(newFile => !list.find(file => newFile.title === file.title))]);
    } else {
      setErrors(errors);
    }
    refDragOnStatus.current = false;
  }

  const submitFiles = async () => {
    setIsLoader(true);
    try {
      await submit({ files, addStatusFile, addServerError });
      loadNewList();
      setFiles(list => {
        const done = !list.find(item => !item.savedOnServer);
        if (done) {
          setTimeout(() => {
            onSuccesfulUpload();
            onClose();
          }, 300)
        }
        return list;
      });
    } catch (err) { }

    setIsLoader(false);
  }

  return (
    <>
      {createPortal( (
        <div data-theme="dark" className={`theme-dark`} onClick={cancelEventClick}>
          <div className="examination-sharing-upload-file-popup-background-container" />
          <div className="examination-sharing-upload-file-popup-container">
            <div className="examination-sharing-upload-file-popup">
              <div className="examination-review-sharing-preview-popup-close-button" onClick={onClose}>
                <Icon name="close" />
              </div>
              <h4 className="examination-sharing-upload-file-popup-title">
                {__('examination.popup.addDocuments')}
              </h4>
              <div className={`examination-sharing-upload-file-popup-drag-and-drop-box${isDragOn ? " drag-on" : ""}`}
                onDragOver={() => setIsDragOn(true)} onDrop={() => setIsDragOn(false)}>
                <Icon name="upload-file" className="upload-file-icon" />
                <p
                  className="examination-sharing-upload-file-popup-drag-and-drop-box-label"
                  dangerouslySetInnerHTML={{ __html: __('examination.popup.dragAndDropFileLabel') }}
                />
                <input
                  id="file"
                  key={files.length}
                  className="examination-sharing-upload-file-popup-drag-and-drop-input"
                  type="file"
                  multiple
                  accept={ALLOWED_FILE_TYPES.join(',')}
                  onChange={handleFileChange}
                />
              </div>
              {errors.length > 0 && errors.map((error, index) => (
                <div key={index} className={`examination-sharing-upload-file-popup-error-message-item item-${index}`}>
                  <p key={error} className={`examination-sharing-upload-file-popup-error-message item-${index}`}>{error}</p>
                </div>
              ))}
              {files.length > 0 && (
                <>
                  <p className="examination-sharing-upload-file-popup-upload-file-list-label">
                    {__('examination.sharingListWidget.uploads')}
                  </p>
                  <div className={`examination-sharing-upload-file-popup-upload-file-list${files.length > 3 ? ' padding-right' : ''}`}>
                    {files.map((file) => (
                      <div key={file.title} className="examination-sharing-upload-file-popup-attach-file">
                        <Icon name="file" />
                        <p className="examination-sharing-upload-file-popup-attach-file-name">{file.name}</p>
                        <Icon
                          name={file.savedOnServer ? 'done' : 'trash'}
                          onClick={() => removeFile(file.title)}
                          className="delete-icon"
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="examination-sharing-upload-file-popup-actions">
                <Button label={__('examination.popup.cancel')} variant="outline" onClick={onClose} />
                <Button
                  isLoading={isLoader}
                  label={__('examination.popup.confirm')}
                  disabled={isDisable}
                  onClick={submitFiles}
                />
              </div>
            </div>
          </div>
        </div>
      ), document.body)
      }
    </>)
}
export default withTranslation()(SharingUploadFilePopup);
