import { isNullOrUndefined } from "../../../../utils";

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

// Takes the current value of findings, and builds the coding suggestions object. 
export const toFindingsSuggestions = (findings) => {
  return Object.values({ ...(findings || {}) })
    .sort((a, b) => a.order - b.order)
    .filter(item => Array.isArray(item.codes_object))
    .map(({ codes_object, label }) => ({
      codes: codes_object.map(({ code, description, origin_value }) => ({
        code,
        description,
        origin_value,
        origin: "finding"
      })),
      description: label
    }));
}

// Takes the current value of indications, and builds the coding suggestions object.
export const toIndicationsSuggestions = (indications) => {
  return Object.values({ ...(indications || {}) })
    .sort((a, b) => a.order - b.order)
    .filter(item => Array.isArray(item.codes_object))
    .map(({ codes_object, label }) => ({
      codes: (Array.isArray(codes_object) ? codes_object : Object.entries(codes_object || {}))?.map(({ code, description, origin_value }) => ({
        code,
        description,
        origin_value,
        origin: "indication"
      })),
      description: label
    }));
}

/* Take returns of the Table component and makes it a code understandable data
*/
export const itemsToCodes = (items) => {
  return Object.values(items?.value || {})
    .sort((a, b) => a.order - b.order)
    .flatMap((item, itemIndex) => {
      if (!isNullOrUndefined(item.originalItem)) {
        return [item.originalItem]
      }
      if (!isNullOrUndefined(item.codes_object)) {
        return item.codes_object.map((code) => ({ ...code, id: uuidv4() }))
      }
    })
}

export const getQuestionAdditionalStatement = (trimester) => {
  /* This is a quick dirty fix for taking in count the GA in IMO search
    * We must improve this in the future with a more specified search tags
    */
  switch (trimester) {
    case "T1":
      return "first trimester"
    case "T2":
      return "second trimester"
    case "T3":
      return "third trimester"
    default:
      return ""
  }
}