import ReportTemplateContent_v1_0 from './Content/v1.0.js';
import ReportTemplateContent_v1_1 from './Content/v1.1.js';
import ReportTemplateContent_v2_0 from './Content/v2.0.js';

export default function ReportTemplateContent(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateContent_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateContent_v1_1(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateContent_v2_0(props);
  }
  return null;
};
