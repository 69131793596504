import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import useAuth from "../../../../context-providers/Auth";
import { ExaminationContext } from "../../../../context-providers/Examination";
import ResourceApi from "../../../../services/resource";
import Table from "../Table";
import Column from "../Empty";
import Value from "../Value";
import Button from "../../../../atoms/Button/Button";
import Icon from "../../../../atoms/Icon/Icon";
import {
  toFindingsSuggestions,
  toIndicationsSuggestions, 
  itemsToCodes, 
  getQuestionAdditionalStatement,
  uuidv4
} from "./utils";

const Coding = ({
  t: __,
  showErrors,
  placeholder,
  examinationContext,
  user,
  canEdit,
  isFeatureFlagEnabled,
  ...otherProps
}) => {
  const { examination, updateExamination } = useContext(ExaminationContext);
  const [defaultProceduresCodes, setDefaultProceduresCodes] = useState([]);
  const [diagnosticSuggestions, setDiagnosticSuggestions] = useState([]);

  const { siteFlowsConnectors } = useAuth();

  useEffect(() => {
    ResourceApi.getDefaultProceduresCodes(examination.id)
      .then((resp) => setDefaultProceduresCodes([{ codes: resp.data.data }]))
      .catch((e) => console.error(e))
  }, [examination.preset_id, JSON.stringify(placeholder.methods?.value)])

  useEffect(() => {
    const findingsSuggestions = toFindingsSuggestions(placeholder.findings?.value);
    const indicationsSuggestions = toIndicationsSuggestions(placeholder.indications?.value);

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(placeholder.indications),
      dropdownToList(placeholder.findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(examination?.trimester)
    )
      .then(({ data }) => setDiagnosticSuggestions(
        data.indications
          .concat(indicationsSuggestions)
          .concat(data.findings)
          .concat(findingsSuggestions)
      ))
      .catch((e) => console.error(e))
    /* TODO how do we show the user something wrong is going on with Sonio or IMO */
  }, [JSON.stringify({ indications: placeholder.indications?.value, findings: placeholder.findings?.value })])

  const filterDiagSuggestions = (suggestions, codes) => {
    const selectedCodes = codes.map(({ code }) => code);
    return Object.values(
      Object.groupBy(
        suggestions.flatMap(suggestion => suggestion.codes.map(c => ({ id: uuidv4(), ...c, origin_value: suggestion.description }))),
        ({ code }) => code
      ))
      // Only unique code for diagnostic codes
      .map(codes => {
        const origin_value = codes.filter(({ origin_value }) => origin_value).map(({ origin_value }) => origin_value).join(", ");
        return ({
          ...codes[0],
          origin_value
        })
      })
      // Remove the code if already in the dropdown
      .filter(({ code }) => !selectedCodes.includes(code));
  }

  const filterProceduresSuggestions = (suggestions, codes) => {
    const selectedCodes = Object.fromEntries(
      Object.values(
        Object.groupBy(codes, ({ code }) => code)
      )
        .map(codes => [codes[0].code, codes.length])
    );
    return Object.values(
      Object.groupBy(
        suggestions.flatMap(suggestion => suggestion.codes.map(c => ({ id: uuidv4(), ...c, origin_value: suggestion.description }))),
        ({ code }) => code
      ))
      // Remove code if already present in the table by the number of already selected codes
      // If rules suggest 3 76830 CPT and 1 76830 is already present in the table
      // We must only display 2 76830 CPT
      .flatMap((codes) => codes.slice(selectedCodes[codes[0].code] || 0));
  }

  const diagnoses_codes = examinationContext?.examination?.["diagnoses_codes"] || [];
  const procedures_codes = examinationContext?.examination?.["procedures_codes"] || [];
  const diagnosticSuggestionsFiltered = filterDiagSuggestions(diagnosticSuggestions, diagnoses_codes);
  const defaultProceduresCodesFiltered = filterProceduresSuggestions(defaultProceduresCodes, procedures_codes);

  const update = async (attributes) => {
    if (!canEdit)
      return
    return await updateExamination({
      ...attributes,
      id: examination.id,
      status: (examination.status === "draft" || examination.status === "inprogress") ? "inprogress" : "completed"
    })
  }

  const addAllSuggestedCodes = async () => {
    return await update({
      diagnoses_codes: [...examinationContext.examination?.diagnoses_codes, ...diagnosticSuggestionsFiltered],
      procedures_codes: [...examinationContext.examination?.procedures_codes, ...defaultProceduresCodesFiltered]
    })
  }

  const addCode = async (examinationAttribute, item) => {
    await update({ [examinationAttribute]: [...examinationContext.examination?.[examinationAttribute], item] });
  }

  if (!siteFlowsConnectors?.coding)
    return null

  const numberOfSuggestions = diagnosticSuggestionsFiltered.length + defaultProceduresCodesFiltered.length;

  const resetDiagnosesCodes = async () => {
    const findingsSuggestions = toFindingsSuggestions(placeholder.findings?.value);
    const indicationsSuggestions = toIndicationsSuggestions(placeholder.indications?.value);

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(placeholder.indications),
      dropdownToList(placeholder.findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(examination?.trimester)
    )
      .then(({ data }) => {

        let diagnosesCodes = data.indications
          .concat(indicationsSuggestions)
          .concat(data.findings)
          .concat(findingsSuggestions)

        const manuallyAddedCodes = examinationContext.examination?.diagnoses_codes?.filter(code => code?.origin === "manual") || [];
        return update({
          diagnoses_codes: [...filterDiagSuggestions(diagnosesCodes, []), ...manuallyAddedCodes],
          procedures_codes: [...examinationContext.examination?.procedures_codes, ...defaultProceduresCodesFiltered]
        })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="exam-report-coding">
      <div className="exam-report-coding_heading">
        <h1>{__("reportCoding.title")}</h1>
        {isFeatureFlagEnabled("sonio.coding.reorder_diagnostic_codes_btn") ?
          <Button hint={__("reportCoding.system.resetHint")} onClick={resetDiagnosesCodes} label={__("reportCoding.system.reset")} icon="reload" size="small" />
          : !!numberOfSuggestions && (<Button onClick={addAllSuggestedCodes} label={__("reportCoding.addAllSuggestedCodes", { count: numberOfSuggestions })} icon="add" size="small" />)
        }
      </div>
      {examination && (
        <>
          <CodingSection
            coding_system="diagnostic"
            examinationAttribute="diagnoses_codes"
            source="ICD10"
            codes={examinationContext?.examination?.["diagnoses_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={diagnosticSuggestionsFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            {...otherProps}
          />
          <CodingSection
            coding_system="procedure"
            examinationAttribute="procedures_codes"
            source="CPT"
            codes={examinationContext?.examination?.["procedures_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={defaultProceduresCodesFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            {...otherProps}
          />
        </>
      )}
    </div>
  )
}

export default withTranslation()(Coding);


const CodingSection = withTranslation()(({
  t: __,
  coding_system,
  examinationAttribute,
  source,
  codes = [],
  showErrors,
  mandatory,
  addCode,
  update,
  suggestions = [],
  examinationContext,
  user,
  canEdit,
  ...otherProps
}) => {

  const placeholder = {
    value: Object.fromEntries(codes?.filter(code => code).map((code, order) => [code.id || code.code + '_' + code.description, {
      value: true,
      code: code.code,
      label: code.description,
      description: code.description,
      order,
      source: code.origin,
      originalItem: code,
    }])),
  }

  const onEndEditing = (data, items) => {
    update({ [examinationAttribute]: itemsToCodes(items) })
  }

  return (
    <div className="exam-report-coding-section">
      <div className="exam-report-coding-section_title">
        <h2>{__("reportCoding.system." + coding_system)}</h2>
      </div>
      <div className="exam-report-coding-section_table">
        <Table
          {...otherProps}
          props={{ data: `coding.${coding_system}`, sortable: "true", source, examinationContext, required: `${mandatory}`, "empty-message": __("reportCoding.nothingToShow"), "search-placeholder": __("reportCoding.searchPlaceholder." + coding_system) }}
          canEdit={canEdit}
          onEndEditing={onEndEditing}
          user={user}
          placeholder={placeholder}
          examinationContext={examinationContext}
          showErrors={showErrors}
        >
          {[
            <Column key="col1" props={{ label: __("reportCoding.table.code"), width: "10%" }} type="column">
              {[
                <Value key="value1" props={{ data: `coding.${coding_system}`, attribute: "code" }} />,
              ]}
            </Column>,
            <Column key="col2" props={{ label: __("reportCoding.table.description") }} type="column">
              {[
                <Value key="value2" props={{ data: `coding.${coding_system}`, attribute: "label" }} />,
              ]}
            </Column>,
          ]}
        </Table>
        <SuggestionsSection
          suggestions={suggestions}
          addCode={addCode}
          examinationAttribute={examinationAttribute}
          coding_system={coding_system}
        />
      </div>
    </div>
  )
});

const SuggestionsSection = withTranslation()(({
  t: __,
  suggestions,
  addCode,
  examinationAttribute,
  coding_system
}) => {

  return (
    !!suggestions?.length && (
      <div className="exam-report-coding-section_suggestions">
        <h6 className="section-title">{__("reportCoding.suggestionSentence." + coding_system)}</h6>
        {suggestions?.map((suggestion, i) => (
          <div
            key={i}
            onClick={() => addCode(examinationAttribute, suggestion)}
            className="exam-report-coding-section_suggestion"
          >
            <Icon name="add" />
            <span className="code">{suggestion.code}</span>
            <span className="meta">
              <span className="description">{suggestion.description}</span>
              {!!suggestion.origin_value && suggestion.origin_value !== suggestion.description && (
                <span className="suggested">{suggestion.origin_value}</span>
              )}
            </span>
          </div>
        ))}
      </div>
    )
  );
});
