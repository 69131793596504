export const slugDateObtainedMapping = {
  "ga.lmp": "lmp_date",
  "ga.conception_date": "conception_date",
  "ga.embryo_transfer": "embryo_transfer_date",
  "ga.edd": "estimated_delivery_date",
  "ga.prev_ultrasound": "prev_ultrasound_exam_date",
};

export const conceptionDateOptions = [
  { value: "", label: "report.gatable.-" },
  { value: "bbt", label: "report.gatable.bbt" },
  { value: "iui", label: "report.gatable.iui" },
  { value: "gift_zift", label: "report.gatable.gift_zift" },
  { value: "ivf", label: "report.gatable.ivf" },
  { value: "ovulation_drugs", label: "report.gatable.ovulation_drugs" },
  { value: "aih_not_induced", label: "report.gatable.aih_not_induced" },
  { value: "aih_induced", label: "report.gatable.aih_induced" },
  { value: "aid_not_induced", label: "report.gatable.aid_not_induced" },
  { value: "aid_induced", label: "report.gatable.aid_induced" },
];

export const eddOptions = [
  {
    id: "pat",
    label: "report.gatable.pat",
    type: "node",
    tree: [],
    description: "report.gatable.pat",
    selectable: true,
    tree_ids: [],
  },
  {
    id: "pmd",
    label: "report.gatable.pmd",
    type: "node",
    tree: [],
    description: "report.gatable.pmd",
    selectable: true,
    tree_ids: [],
  },
  {
    id: "office",
    label: "report.gatable.office",
    type: "node",
    tree: [],
    description: "report.gatable.office",
    selectable: true,
    tree_ids: [],
  },
];

export const prevUsOptions = [
  {
    description: "-",
    id: "",
    label: "-",
    selectable: true,
    tree: [],
    tree_ids: [],
    type: "node",
  },
  {
    id: "first_trimester",
    label: "report.gatable.first_trimester",
    type: "category",
    tree: [
      // TODO: Add appropriate equations toe measurement_estimation and restore commented blocks
      // {
      //     description: "GS Mean",
      //     id: "t1_gs_mean",
      //     label: "GS Mean",
      //     selectable: true,
      //     tree: [],
      //     tree_ids: [],
      //     type: "node"
      // },
      {
        description: "CRL",
        id: "t1_crl",
        label: "CRL",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      {
        description: "BPD",
        id: "t1_bpd",
        label: "BPD",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      {
        description: "AC",
        id: "t1_ac",
        label: "AC",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      {
        description: "HC",
        id: "t1_hc",
        label: "HC",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      {
        description: "FL",
        id: "t1_fl",
        label: "FL",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
    ],
    description: "report.gatable.first_trimester",
    selectable: false,
    tree_ids: ["t1_crl", "t1_bpd", "t1_ac", "t1_hc", "t1_fl"],
  },
  {
    id: "second_trimester",
    label: "report.gatable.second_trimester",
    type: "category",
    tree: [
      {
        description: "BPD",
        id: "t2_bpd",
        label: "BPD",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      {
        description: "AC",
        id: "t2_ac",
        label: "AC",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      // {
      //     description: "Cerebellum tr",
      //     id: "t2_cerebellum_tr",
      //     label: "Cerebellum tr",
      //     selectable: true,
      //     tree: [],
      //     tree_ids: [],
      //     type: "node"
      // },
      {
        description: "HC",
        id: "t2_hc",
        label: "HC",
        selectable: true,
        tree: [],
        tree_ids: [],
        type: "node",
      },
      // {
      //     description: "TAD",
      //     id: "t2_tad",
      //     label: "TAD",
      //     selectable: true,
      //     tree: [],
      //     tree_ids: [],
      //     type: "node"
      // }
    ],
    description: "report.gatable.second_trimester",
    selectable: false,
    tree_ids: ["t2_bpd", "t2_ac", "t2_hc", "t2_tad"],
  },
  {
    id: "ga",
    label: "report.gatable.ga",
    description: "report.gatable.ga",
    selectable: true,
    tree: [],
    tree_ids: [],
    type: "node",
  },
];

export const prevUsOptionsLabels = {
  t1_gs_mean: "report.gatable.gsmean",
  t1_crl: "report.gatable.crl",
  t1_bpd: "report.gatable.bpd",
  t1_ac: "report.gatable.ac",
  t1_hc: "report.gatable.hc",
  t1_fl: "report.gatable.fl",
  t2_bpd: "report.gatable.bpd",
  t2_ac: "report.gatable.ac",
  t2_cerebellum_tr: "report.gatable.cerebellumTr",
  t2_hc: "report.gatable.hc",
  t2_tad: "report.gatable.tad",
  ga: "report.gatable.ga",
};
