import { useEffect, useState, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

import { useXMLTemplate } from "../../context-providers/XMLTemplate";
import { ExaminationContext } from "../../context-providers/Examination";

import { useXMLDocumentPrinter } from "../../components/XMLDocument";

import ResourceApi from "../../services/resource";
import LoaderInline from "../../atoms/LoaderInline/LoaderInline";

import "./Print.css";

export default function ReportPrint() {
  const { examId } = useParams();
  const examinationContext = useContext(ExaminationContext);
  const XMLPrinter = useXMLDocumentPrinter({page: "report"});
  const { reportDataOptions, placeholders } = useXMLTemplate();
  const [placeholderStabilityTimeout, setPlaceholderStabilityTimeout] =
    useState(1);
  const [htmlContent, setHtmlContent] = useState("");
  const [frozenReport, setFrozenReport] = useState({ state: "not_loaded" });

  const templateBlueprint =
    examinationContext.debugTemplate ||
    reportDataOptions?.report_template?.blueprint;
  const renderReportHtml = useCallback(
    async () =>
      await XMLPrinter({
        base64Images: [],
        templateBlueprint,
      }),
    [templateBlueprint, XMLPrinter]
  );

  const readFrozenReportHtml = useCallback(async () => {
    await ResourceApi.readDocumentHtml(
      examinationContext.examination.id,
      "print",
      "report"
    )
      .then((response) =>
        setFrozenReport({ state: "loaded", print: response.data })
      )
      .catch(() => setFrozenReport({ state: "error" }));
  }, [
    examinationContext.examination.id,
    examinationContext.examination.canEdit,
  ]);

  useEffect(() => {
    examinationContext.loadExamination(examId);
  }, [examId]);

  useEffect(() => {
    if (examinationContext?.examination?.id) {
      readFrozenReportHtml();
    }
  }, [examinationContext?.examination?.id]);

  useEffect(() => {
    setPlaceholderStabilityTimeout(
      setTimeout(() => setPlaceholderStabilityTimeout(null), 1000)
    );
    return () => clearTimeout(placeholderStabilityTimeout);
  }, [placeholders, setPlaceholderStabilityTimeout, frozenReport.state]);

  /* We only load the content once as it should not be updated here */
  useEffect(() => {
    if (!templateBlueprint) return;
    if (placeholderStabilityTimeout) return;
    if (frozenReport.state === "loaded") {
      setHtmlContent(frozenReport.print);
    } else if (frozenReport.state === "error") {
      renderReportHtml().then((htmlContent) => setHtmlContent(htmlContent));
    }
  }, [templateBlueprint, !placeholderStabilityTimeout, frozenReport.state]);

  useEffect(() => {
    if (placeholderStabilityTimeout) return;
    if (!htmlContent) return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    document.head.innerHTML = doc.getElementsByTagName("head")[0].innerHTML;
    document.body.innerHTML = doc.getElementsByTagName("body")[0].innerHTML;
    document.body.setAttribute("ready-to-print", "true");
  }, [htmlContent, !!placeholderStabilityTimeout]);

  const finallyReadyToPrint =
    htmlContent !== "" && !placeholderStabilityTimeout;

  return (
    <div>
      {finallyReadyToPrint ? null : (
        <div className="report-print-loader-wrapper">
          <LoaderInline />
        </div>
      )}
    </div>
  );
}
