import { formatYYYYMMDDDate } from "../../utils";
import { convertValueToSelectedUnit } from "../../unitConverter";
import { MeasurementDefaultUnits } from "../../config";

export class PlaceholdersHelper {
  constructor({
    props,
    placeholders,
    apiVersion,
    fetus,
    appPreferences,
    multiSelectValue = false,
    reportDataOptions,
  }) {
    this.props = props;
    this.placeholders = placeholders;
    this.apiVersion = apiVersion;
    this.fetus = Number(props?.fetus ?? fetus ?? 0);
    this.appPreferences = appPreferences;
    this.multiSelectValue = multiSelectValue;
    this.reportDataOptions = reportDataOptions;

    if (!placeholders["fetus.order"]) {
      throw new Error("Missing placeholder 'fetus.order'");
    }
  }

  get numberOfFetuses() {
    return getNumberOfFetuses(
      this.apiVersion,
      this.placeholders["fetus.order"]
    );
  }

  /*
   * Return the fetus ids ordered by report order
   * @returns {array} - The fetus ids ordered by report order
   *
   * Example:
   * placeholdersHelper.allOrderedFetuses;
   * // [null, 51, 55, 79]
   */
  get allOrderedFetuses() {
    return allOrderedFetuses(this.apiVersion, this.placeholders["fetus.order"]);
  }

  get fetuses() {
    return Array(this.numberOfFetuses + 1)
      .fill()
      .map((_, idx) => {
        const selectedDatum = this.selectedDatum("fetus.number", idx);
        return {
          label: this.fetusName(idx),
          id: selectedDatum?.examination_fetus_id,
          dicom_id: selectedDatum?.value?.value,
        };
      });
  }

  /*
   * Return the fetus names ordered by report order
   * @returns {array} - The fetus names ordered by report order
   *
   * Example:
   * placeholdersHelper.fetusesNames;
   * // ["Fetus 1", "Fetus 2", "Fetus 3"]
   */
  get fetusesNames() {
    return Array(this.numberOfFetuses)
      .fill()
      .map((_, idx) => this.fetusName(idx + 1));
  }

  /*
   * Return the fetus name based on the report fetus number
   * @param {number} fetus - The report fetus number
   * @returns {string} - The fetus name
   *
   * Example:
   * fetusName(0);
   * // ""
   * fetusName(1);
   * // "A"
   */
  fetusName(fetus) {
    return this.selectedValue("fetus.name", fetus)?.value;
  }

  /*
   * Return the report fetus number based on the dicom ID
   * @param {string} dicomId - The dicom ID
   * @returns {number} - The report fetus number
   */
  reportFetusIdFromDicom(dicomId) {
    const examinationFetusId = getFetusIdFromDicomId(
      this.apiVersion,
      this.placeholders["fetus.number"],
      dicomId
    );
    return this.allOrderedFetuses.indexOf(examinationFetusId);
  }

  /*
   * Return the examination fetus id based on the report fetus number
   * @param {number} fetus - The report fetus number
   * @returns {number} - The examination fetus id
   *
   * Example:
   *  examinationFetusId(0);
   *  // null
   */
  examinationFetusId(fetus) {
    return getFetusIdFromOrder(
      this.apiVersion,
      this.placeholders["fetus.order"],
      fetus
    );
  }

  data(slug, fetus = null) {
    fetus = fetus ?? this.fetus ?? 0;
    return data(
      this.apiVersion,
      this.placeholders[slug],
      this.examinationFetusId(fetus)
    );
  }

  datum(slug, fetus = null, source = null) {
    if (!source) return this.selectedDatum(slug, fetus);
    else
      return datum(
        this.apiVersion,
        this.placeholders[slug],
        this.examinationFetusId(fetus),
        source
      );
  }

  selectedDatum(slug, fetus = null) {
    fetus = Number(fetus ?? this.fetus);
    /*
     * Usually a data is either multiplied by fetuses and has no value on examination_fetus_id null
     * or has only info for examination_fetus_id null
     * We decide to reproduce this behaviour here with the eligible filter
     * If the targeted data has no information for the "fetus" we fallback to exam data
     * And if the exam has no information we fallback to the first fetus
     */
    const fallbackFetus = fetus === 0 ? 1 : 0;

    return (
      selectedDatum(
        this.apiVersion,
        this.placeholders[slug],
        this.examinationFetusId(fetus)
      ) ??
      selectedDatum(
        this.apiVersion,
        this.placeholders[slug],
        this.examinationFetusId(fallbackFetus)
      ) ?? {
        source: "default",
        value: {},
        selected: false,
        examination_fetus_id: this.examinationFetusId(fetus),
        visible: true,
        graph_visible: false,
      }
    );
  }

  preventUpdatesWhenHidden(slug, _fetus = null) {
    return this.placeholders[slug]?.preventUpdatesWhenHidden ?? false;
  }

  value(slug, fetus = null, source = null) {
    const datumValue = this.datum(slug, fetus, source)?.value;
    if (this.multiSelectValue) {
      return datumValue?.value?.[this.multiSelectValue];
    }
    return datumValue;
  }

  selectedValue(slug, fetus = null) {
    const selectedDatumValue = this.selectedDatum(slug, fetus)?.value;
    if (this.multiSelectValue) {
      return selectedDatumValue?.value?.[this.multiSelectValue];
    }

    return selectedDatumValue;
  }

  attribute(slug) {
    const supportPropsAttribute =
      this.multiSelectValue !== false ||
      this.format(slug) === "checklist.item" ||
      this.format(slug) === "measurement";
    const props_attribute = this.props.attribute ?? "value";
    return supportPropsAttribute ? props_attribute : "value";
  }

  editSelectedDataValue(
    slug,
    newValue,
    fetus = null,
    overwrittenSource = null
  ) {
    const selectedDatum = this.selectedDatum(slug, fetus);
    const allData = data(
      this.apiVersion,
      this.placeholders[slug],
      selectedDatum?.examination_fetus_id
    );
    const value = this.newValue(slug, newValue, fetus);
    const source = overwrittenSource ?? selectedDatum?.source ?? "user";
    const graph_visible =
      newValue.graph_visible ?? selectedDatum?.graph_visible;
    const otherUnselectedData = (allData || [])
      .filter((d) => d.source !== source)
      .map((d) => {
        const { value: _, ...datum } = d;
        return { ...datum, selected: false };
      });

    return [
      {
        slug,
        value,
        source,
        graph_visible,
        selected: true,
        examination_fetus_id: selectedDatum?.examination_fetus_id,
      },
      ...otherUnselectedData,
    ];
  }

  newValue(slug, newValue, fetus = null) {
    const selectedDatum = this.selectedDatum(slug, fetus);
    const oldValue = this.selectedValue(slug, fetus);
    const derivation =
      this.format(slug) === "measurement" ? { derivation: "edit" } : {};
    const valueToApply = { ...oldValue, ...derivation, ...newValue };
    if (this.multiSelectValue) {
      return {
        ...selectedDatum?.value,
        value: {
          ...selectedDatum?.value?.value,
          [this.multiSelectValue]: valueToApply,
        },
      };
    } else {
      return valueToApply;
    }
  }

  format(slug) {
    if (this.isDynamicDropdown(slug)) return "multi-select";
    if (this.props?.multiple === "true") return "multi-select";
    return this.placeholders[slug]?.format ?? this.props?.format ?? "string";
  }

  editable(slug) {
    return this.placeholders[slug]?.editable !== false;
  }

  normalizeUnit(value) {
    // so far, in Directus, "No Unit" is stored as "null" (the string)
    // we anticipate a migration to a "no_unit" value, which makes more sense
    if (value === "null") {
      return "";
    }

    if (value === "no_unit") {
      return "";
    }

    return value ?? "";
  }

  displayedUnit(slug, fetus = null, source = null) {
    let value = this.props.unit ?? this.props.units;

    if (this.format(slug) === "measurement") {
      // "measurement.<measurement>.<body_structure>/<laterality>"
      const column = slug.split(".")[1].split("/")[0];
      value =
        value ??
        this.selectedDatum(slug, fetus, source)?.value?.display_units ??
        this.reportDataOptions?.labels?.measurement?.[column]?.units;
    } else {
      value =
        value ??
        this.placeholders[slug].unit ??
        this.selectedDatum(slug, fetus, source)?.value?.unit;
    }

    return this.normalizeUnit(value);
  }

  defaultUnit(slug, fetus = null, source = null) {
    let type = this.selectedDatum(slug, fetus, source)?.value?.type;
    if (this.format(slug) === "measurement") {
      // "measurement.<measurement>.<body_structure>/<laterality>"
      const column = slug.split(".")[1].split("/")[0];
      type =
        type ?? this.reportDataOptions?.labels?.measurement?.[column]?.type;
    }
    return MeasurementDefaultUnits[type];
  }

  /*
   * For the moment we do not handle multi source data
   * But if required we can pass the source as second argument
   */
  currentUnit(slug, fetus = null, source = null) {
    let value;

    if (this.format(slug) === "measurement") {
      value =
        this.selectedDatum(slug, fetus, source)?.value?.unit ??
        this.defaultUnit(slug, fetus, source);
    } else {
      value =
        this.placeholders[slug].unit ?? this.value(slug, fetus, source)?.unit;
    }

    return this.normalizeUnit(value);
  }

  /*
   * Return the display template of the placeholder
   * @param {string} slug - The placeholder slug
   * @param {number} fetus - The fetus number
   * @param {string} source - The source of the data
   * @return {Array} - The display template of the placeholder
   *
   * Example:
   * displayedValue("medical-history.medicalexam.mother.height", 1, "user");
   * [{value: "5"}, "ft", {value: "5"}, "in"]
   */
  displayedValue(slug, fetus = null, source = null, printMode = false) {
    const format = this.format(slug);
    const attribute = this.attribute(slug);
    const value = this.value(slug, fetus, source)?.[attribute] ?? "";

    switch (format) {
      case "multi-select":
      case "multiple":
        // We consider multiple to only be table of strings for the moment
        // We can improve it in the future to better manage multiple formats
        if (!this.multiSelectValue) {
          console.error(
            "can not display multiple via content. Please use <dropdown> or <table> to display it"
          );
          return ["Not implemented yet"];
        }
      // falls through
      case "string":
        if (this.props.unit) {
          return [{ value }, this.normalizeUnit(this.props.unit)];
        }
        return [{ value }];
      case "measurement":
      case "number":
        return numberToTemplate(
          value,
          this.currentUnit(slug, fetus, source),
          this.displayedUnit(slug, fetus, source),
          this.props.decimals,
          printMode
        );

      case "date":
        if (!this.editable(slug))
          return [
            formatYYYYMMDDDate(
              this.value(slug, fetus, source)?.[attribute] ?? "",
              this.appPreferences.date_format
            ),
          ];
        else
          return [
            {
              value: formatYYYYMMDDDate(
                this.value(slug, fetus, source)?.[attribute] ?? "",
                this.appPreferences.date_format
              ),
            },
          ];
      case "select":
        /* We consider that select fields provided to value or content components are always not editable */
        return [
          labelToString(
            this.options(slug)?.find(
              ({ value }) => value === this.selectedValue(slug)?.value
            )?.label,
            this.appPreferences.lang
          ),
        ];

      default:
        // TODO implement for custom placejholders
        console.error("Unknown format: for placeholder " + slug, { format });
        return [{ value }];
    }
  }

  /*
   * Return the original value of the placeholder from the display template
   * @param {string} slug - The placeholder slug
   * @param {Array} displayedValue - The updated display template of the placeholder
   * @param {number} fetus - The fetus number
   * @param {string} source - The source of the data
   * @return {Array} - The display template of the placeholder
   *
   * Example:
   * displayedValue("medical-history.medicalexam.mother.height", 1, "user");
   * [{value: "5"}, "ft", {value: "5"}, "in"]
   */
  templateToValue(slug, displayedValue, fetus = null, source = null) {
    switch (this.format(slug)) {
      case "multi-select":
      case "multiple":
        // We consider multiple to only be table of strings for the moment
        // We can improve it in the future to better manage multiple formats
        if (!this.multiSelectValue) {
          console.error(
            "can not display multiple via content. Please use <dropdown> or <table> to display it"
          );
          return null;
        }
      // falls through
      case "string":
        return displayedValue[0].value;
      case "measurement":
      case "number":
        return templateToNumber(
          displayedValue,
          this.displayedValue(slug, fetus, source),
          this.displayedUnit(slug, fetus, source),
          this.currentUnit(slug, fetus, source)
        );

      case "date":
        return displayedValue[0].value;
      case "select":
        return null;

      default:
        // TODO implement for custom placejholders
        console.error("Unknown format: for placeholder " + slug, {
          format: this.format(slug),
        });
        return [];
    }
  }

  /*
   * @param {string} slug - The placeholder slug
   * @return {boolean} - Whether the placeholder is a dynamic dropdown or not
   */
  isDynamicDropdown(slug) {
    const nested_collections =
      !!slug.startsWith("examination.") &&
      this.reportDataOptions?.nested_collections?.[slug.slice(12)]?.full_tree;
    const dynamic_dropdown = !!this.reportDataOptions?.report_dropdowns?.[slug];
    return dynamic_dropdown || nested_collections;
  }

  isMultiple(slug) {
    if (this.multiSelectValue) return false;
    const multipleFormats = ["multiple", "multi-select"];
    return (
      this.isDynamicDropdown(slug) ||
      this.props?.multiple === "true" ||
      multipleFormats.includes(this.format(slug))
    );
  }

  /*
   * @param {string} slug - The placeholder slug
   * @return {Array} - The options of the dropdown
   */
  options(slug) {
    if (this.isDynamicDropdown(slug)) {
      const fallback = slug.startsWith("examination.")
        ? this.reportDataOptions.nested_collections?.[slug.slice(12)]
            ?.full_tree ?? []
        : [];
      return this.reportDataOptions.report_dropdowns?.[slug]?.tree ?? fallback;
    }
    if (this.placeholders[slug]) return this.placeholders[slug].options ?? [];
  }

  /*
   * @param {string} slug - The placeholder slug
   * @returns {boolean} - Whether the curve is visible or not
   */
  curveVisible(slug) {
    return (
      this.selectedDatum(slug)?.graph_visible ?? this.props.visible ?? false
    );
  }

  /*
   * @param {string} slug - The placeholder slug
   * @returns {Array} - the carried forward values
   */
  carryForwardValues(slug) {
    let forwarded = this.placeholders[slug]?.forwarded ?? [];

    forwarded = forwarded.filter(
      (el) => el.examination_fetus_id === this.examinationFetusId(this.fetus)
    );

    const output = {
      value: forwarded.reduce(
        (acc, curr) => [...acc, ...(Object.values(curr?.value?.value) || [])],
        []
      ),
      data: this.props?.data,
      custom: this.props?.custom,
    };
    return output;
  }
}

/*
 * Get the fetus id for a placeholder from the fetus order (report fetus index) (0 for the mother, 1 to n for the fetuses)
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders["fetus.order"])
 * @param {number} order - The order of the fetus - 0 for the mother, 1 to n for the fetuses
 */
export function getFetusIdFromOrder(apiVersion, placeholder, order) {
  if (apiVersion === "2.0") {
    return getFetusIdFromOrderV2(placeholder, order);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

/*
 * Get the fetus id from the provided DICOM ID
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders["fetus.number"])
 * @param {string} dicomId - The DICOM ID of the fetus
 * @returns {number} - The examination fetus ID
 */
export function getFetusIdFromDicomId(apiVersion, placeholder, dicomId) {
  if (apiVersion === "2.0") {
    return getFetusIdFromDicomIdV2(placeholder, dicomId);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

/*
 * Get the value of a placeholder
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders[placeholderId])
 * @param {object} fetusId - The examination fetus ID
 *
 * Example:
 *  const placeholder = placeholders["fetus.number"];
 *  const value = getPlaceholderValue(apiVersion, placeholder, getFetusIdFromOrder(apiVersion, placeholders["fetus.order"], 1));
 */
export function getPlaceholderValue(apiVersion, placeholder, fetusId) {
  if (apiVersion === "2.0") {
    return getPlaceholderValueV2(placeholder, fetusId);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

/*
 * Get the placeholder object for a fetus
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders[placeholderId])
 * @param {object} fetusId - The examination fetus ID
 *
 * Example:
 *  const placeholder = placeholders["ga.lmp"];
 *  const value = getPlaceholder(apiVersion, placeholder, getFetusIdFromOrder(apiVersion, placeholders["fetus.order"], 1));
 */
export function getPlaceholder(apiVersion, placeholder, fetusId) {
  if (apiVersion === "2.0") {
    return getPlaceholderV2(placeholder, fetusId);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

/*
 * Get the number of fetuses from the "fetus.order" placeholder
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders["fetus.order"])
 */
export function getNumberOfFetuses(apiVersion, placeholder) {
  if (apiVersion === "2.0") {
    return placeholder.data.length - 1;
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

/*
 * Return the fetus ids ordered by report order
 * @param {string} apiVersion - The format version of placeholder
 * @param {object} placeholder - The placeholder object (result of placeholders["fetus.order"])
 * @returns {array} - The fetus ids ordered by report order
 *
 * Example:
 * allOrderedFetuses(apiVersion, placeholders["fetus.order"]);
 * // [null, 51, 55, 79]
 */
export function allOrderedFetuses(apiVersion, placeholder) {
  if (apiVersion === "2.0") {
    return placeholder.data.map((d) => d.examination_fetus_id);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

export function data(apiVersion, placeholder, fetusId) {
  if (apiVersion === "2.0") {
    return dataV2(placeholder, fetusId);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

export function selectedDatum(apiVersion, placeholder, fetusId) {
  if (apiVersion === "2.0") {
    return selectedDatumV2(placeholder, fetusId);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

export function datum(apiVersion, placeholder, fetusId, source) {
  if (apiVersion === "2.0") {
    return datumV2(placeholder, fetusId, source);
  } else {
    throw new Error("Unsupported API version " + apiVersion);
  }
}

function getPlaceholderValueV2(placeholder, fetusId) {
  return getPlaceholderV2(placeholder, fetusId)?.value;
}

function getPlaceholderV2(placeholder, fetusId) {
  return placeholder.data.find(
    (d) => d.examination_fetus_id === fetusId && d.selected
  )?.value;
}

function getFetusIdFromOrderV2(placeholder, order) {
  return placeholder?.data[order]?.examination_fetus_id;
}

function getFetusIdFromDicomIdV2(placeholder, dicomId) {
  return placeholder.data
    .filter((d) => d.selected)
    .find((d) => d.value.value === dicomId)?.examination_fetus_id;
}

function dataV2(placeholder, fetusId) {
  return placeholder?.data?.filter((d) => d.examination_fetus_id === fetusId);
}

function selectedDatumV2(placeholder, fetusId) {
  const eligibleData = dataV2(placeholder, fetusId);
  return (
    eligibleData?.find((d) => d.selected) ??
    eligibleData?.find((d) => d.source === "user") ??
    eligibleData?.find((d) => d.source === "default") ??
    null
  );
}

function datumV2(placeholder, fetusId, source) {
  return (
    placeholder.data.find(
      (d) => d.examination_fetus_id === fetusId && d.source === source
    ) ?? null
  );
}

function labelToString(label, currentLanguage) {
  if (typeof label === "string") {
    return label;
  } else if (
    typeof label === "object" &&
    Object.prototype.hasOwnProperty.call(label, currentLanguage)
  ) {
    return label[currentLanguage] || label.en;
  } else {
    console.error("Can not translate label", { label, currentLanguage });
    return "";
  }
}

/* TODO converted units needs to be translated */
function numberToTemplate(
  value,
  currentUnit,
  displayedUnit,
  decimals,
  printMode = false
) {
  const convertedValue = convertValueToSelectedUnit(
    value ?? "",
    currentUnit,
    displayedUnit,
    decimals
  );
  if (displayedUnit.includes(".")) {
    const units = displayedUnit.split(".");
    const values = units.map((u, i) => {
      return [{ value: getMultiUnitTemplateValue(convertedValue, i) }, u];
    });

    if (printMode) {
      const filtered = values.filter((v) => v[0].value > 0).flat();
      return filtered.length ? filtered : [values[0]];
    } else {
      return values.flat();
    }
  }
  return [{ value: convertedValue }, displayedUnit];
}

function getMultiUnitTemplateValue(value, index) {
  if (Array.isArray(value)) {
    return value[index];
  }
  if (index === 0) {
    return value;
  }
  return "";
}

function templateToNumber(
  template,
  previousTemplate,
  displayedUnit,
  currentUnit
) {
  const receivedValues = template
    .filter((i) => Object.prototype.hasOwnProperty.call(i, "value"))
    .map((i) => i.value);
  const previousValues = previousTemplate
    .filter((i) => Object.prototype.hasOwnProperty.call(i, "value"))
    .map((i) => i.value);
  const modifiedValue = receivedValues.find(
    (value, i) => value !== previousValues[i]
  );
  const conversion = convertValueToSelectedUnit(
    receivedValues,
    displayedUnit,
    currentUnit,
    // We want to store value with high precision
    8
  );

  if (conversion === 0 && modifiedValue === "") {
    return "";
  }
  return conversion;
}
