import { renderToStaticMarkup } from 'react-dom/server';
import { useContext, useMemo, useCallback } from 'react';
import { useCollector } from '../hooks/useCollector';

/* Contexts */
import { MeasurementsContext } from "../context-providers/Measurements";
import { useXMLTemplate } from "../context-providers/XMLTemplate";
import { useExamination } from "../context-providers/Examination";
import useAuth from "../context-providers/Auth";
import { AppContext } from "../context-providers/App";

import XMLDocument_v1_0 from './XMLDocument/v1.0';
import XMLDocument_v1_1 from './XMLDocument/v1.1';
import XMLDocument_v2_0 from './XMLDocument/v2.0';
import { placeholderIdFromProps } from './XMLDocument/utils';

import PrintTemplate from './XMLDocument/PrintTemplate';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function whoIsRequiringPlaceholder(placeholder_id, requiredPlaceholders) {
  return Object.keys(requiredPlaceholders)
    .filter((key) => {
      return requiredPlaceholders[key].includes(placeholder_id);
    })
}

const specificPropsFromContext_v1_0 = (xmlTemplateContext) => {
  const {
    BIContext,
    getPlaceholderWithProps,
    getHighligthedWithProps,
    getCarryForwardWithProps,
    customPlaceholders,
    setCustomPlaceholders,
    onEndEditing,
    onEndEditingChecklist,
    onEndEditingDynamicDropdown,
    onEndEditingDating,
    componentChecklistAssoc,
    updateComponentChecklistAssoc,
    loadDynamicDropdownFullTree,
    startEditingField,
    startEditing,
    editingFieldId,
    checkCondition,
    placeholders,
    setPlaceholders,
    setAssignedGa,
    revertAssignedGa,
    updateEpisode,
    reportData,
    reportDataOptions,
    updateAutogeneratedChecklistComments,
    apiVersion
  } = xmlTemplateContext;

  return {
    BIContext,
    getPlaceholderWithProps,
    getHighligthedWithProps,
    getCarryForwardWithProps,
    customPlaceholders,
    setCustomPlaceholders,
    onEndEditing,
    onEndEditingChecklist,
    onEndEditingDynamicDropdown,
    onEndEditingDating,
    componentChecklistAssoc,
    updateComponentChecklistAssoc,
    loadDynamicDropdownFullTree,
    startEditingField,
    startEditing,
    editingFieldId,
    checkCondition,
    placeholders,
    setPlaceholders,
    setAssignedGa,
    revertAssignedGa,
    updateEpisode,
    reportData,
    reportDataOptions,
    updateAutogeneratedChecklistComments,
    apiVersion
  };
}

const specificPropsFromContext_v1_1 = (xmlTemplateContext) => {
  const {
    // Add specific props for version 1.1
    BIContext,
    getHighligthedWithProps,
    getCarryForwardWithProps,
    customPlaceholders,
    setCustomPlaceholders,
    onEndEditing,
    applyChanges,
    onEndEditingChecklist,
    onEndEditingDynamicDropdown,
    onEndEditingDating,

    componentChecklistAssoc,
    updateComponentChecklistAssoc,

    loadDynamicDropdownFullTree,

    startEditingField,
    startEditing,
    editingFieldId,

    /* Deprecated */
    placeholders,
    setAssignedGa,
    revertAssignedGa,
    updateEpisode,
    updateAutogeneratedChecklistComments,
    /* New API */
    requirePlaceholders,
    requiredPlaceholdersFromProps,
    reportDataOptions,
    apiVersion,
    stable
  } = xmlTemplateContext;

  return {
    // Add specific props for version 1.1

    BIContext,
    getHighligthedWithProps,
    getCarryForwardWithProps,
    customPlaceholders,
    setCustomPlaceholders,
    onEndEditing,
    applyChanges,
    onEndEditingChecklist,
    onEndEditingDynamicDropdown,
    onEndEditingDating,

    componentChecklistAssoc,
    updateComponentChecklistAssoc,

    loadDynamicDropdownFullTree,

    startEditingField,
    startEditing,
    editingFieldId,

    /* Deprecated */
    placeholders,
    setAssignedGa,
    revertAssignedGa,
    updateEpisode,
    updateAutogeneratedChecklistComments,
    /* New API */
    requirePlaceholders,
    requiredPlaceholdersFromProps,
    reportDataOptions,
    apiVersion,
    stable
  };
}

const specificPropsFromContext = (xmlTemplateContext) => {
  switch (xmlTemplateContext.apiVersion) {
    case "1.0":
      return specificPropsFromContext_v1_0(xmlTemplateContext);
    case "1.1":
      return specificPropsFromContext_v1_1(xmlTemplateContext);
    case "2.0":
      // The new XML context is clean enough to not have to filter
      return xmlTemplateContext
    default:
      return {};
  }
}
/* This is a custom hook loading necessary information to be able to print a specific XML document */
const useProps = () => {
  const xmlTemplateContext = useXMLTemplate();

  const examinationContext = useExamination();
  const measurementsContext = useContext(MeasurementsContext)
  const { siteFlowsConnectors, user, isFeatureFlagEnabled } = useAuth();
  const appContext = useContext(AppContext);

  const commonProps = {
    examinationContext,
    measurementsContext,
    siteFlowsConnectors,
    user,
    isFeatureFlagEnabled,
    appContext
  }

  const specificProps = specificPropsFromContext(xmlTemplateContext);

  return { ...commonProps, ...specificProps };
}

const getComponent = (apiVersion) => {
  if (apiVersion === "1.0") return XMLDocument_v1_0;
  if (apiVersion === "1.1") return XMLDocument_v1_1;
  if (apiVersion === "2.0") return XMLDocument_v2_0;
  return () => `API version ${apiVersion} is not supported yet.`
}

/* 
 * @desc - Custom hook - return an async function to render the XMLDocument as a static HTML
 * @returns - async Function
 */
export const useXMLDocumentPrinter = ({page = "report"}) => {
  const contextsProps = useProps();

  const printer = async (props, requiredPlaceholders, placeholders, count) => {
    /* This mimic a useState hook inside a render loop of staticMarkup */
    // This variable tells us if at least one component was not satisfied with the placeholders provided
    // If this is the case, we need to call printer an additional time

    let placeholdersRequired = false;
    const requirePlaceholders = (props, ids) => {
      const id = requirePlaceholderId(props);
      const previouslyRequiredPlaceholders = requiredPlaceholders[id] ?? [];
      if (previouslyRequiredPlaceholders.sort().join("|") === ids.sort().join("|")) {
        return;
      }

      placeholdersRequired = true;
      const savedIds = [...requiredPlaceholders[id] ?? [], ...ids]
        .filter(onlyUnique)
        .sort();

      requiredPlaceholders[id] = savedIds;
    }

    const requiredPlaceholdersFromPropsMap = Object.fromEntries(
      /* Return an object where:
       * - The key is the id of the component requiring placeholders
       * - The value is an object where:
       *    - The key is the id of the placeholder
       *    - The value is the value of the placeholder
       */
      Object.entries(requiredPlaceholders)
        .map(([id, ids]) => [
          id,
          Object.fromEntries(ids.map(id => [id, placeholders[id]]))
        ])
    );

    const requiredPlaceholdersFromProps = (props) => {
      if (!props) return {};
      const id = requirePlaceholderId(props);
      return requiredPlaceholdersFromPropsMap[id] ?? {};
    }

    const Component = getComponent(contextsProps.apiVersion);

    const render = renderToStaticMarkup(
      <PrintTemplate>
        <Component
          templateBlueprint={props.templateBlueprint}
          base64Images={props.base64Images}
          {...contextsProps}
          placeholders={placeholders}
          requirePlaceholders={requirePlaceholders}
          requiredPlaceholdersFromProps={requiredPlaceholdersFromProps}
          reportMode="print"
          showErrors={false}
          submitClicked={false}
          setEditChecklistDialogIsOpen={() => { }}
          setManageMeasurementsIsOpen={() => { }}
          setBase64Images={() => { }}
          setRequiredAndInvalid={() => { }}
          page={page}
        />
      </PrintTemplate>
    );

    const unLoadedPlaceholders = Object.values(requiredPlaceholders)
      .flat()
      .filter((id) => !placeholders[id])
      .filter(onlyUnique);

    if (count > 10)
      throw new Error("Too many renders");

    if (placeholdersRequired) {
      if (unLoadedPlaceholders.length === 0)
        return await printer(props, requiredPlaceholders, placeholders, count + 1);
      const additionalPlaceholders = await contextsProps.requirePlaceholders(unLoadedPlaceholders);
      return await printer(props, requiredPlaceholders, { ...placeholders, ...additionalPlaceholders }, count + 1);
    }
    console.log("Rendering done in", count, "renders", placeholders);
    return [count, render];
  }
  return async (props) => {
    // count can be used as a debug information to know how heavy the rendering was
    const [_count, render] = await printer(props, {}, contextsProps.placeholders, 1);
    return render;
  }
}

const requirePlaceholderId = (props) => {
  return `${props.type}/${placeholderIdFromProps(props)}`;
}

export const XMLDocument = ({ ...props }) => {
  const { apiVersion } = useXMLTemplate();
  const additionalProps = useProps();
  const placeholders = additionalProps.placeholders || {};
  const [requiredPlaceholders, setRequiredPlaceholders] = useCollector({});
  const appliedProps = { ...props, ...additionalProps };
  const Component = useMemo(() => getComponent(apiVersion), [apiVersion]);

  /* requirePlaceholders returns a promise that resolve when the placeholder is available. */
  const requirePlaceholders = useCallback(async (props, ids) => {
    // props type is the type of component used to render the data
    //
    const id = requirePlaceholderId(props);

    setRequiredPlaceholders((requiredPlaceholders) => {
      const sortedIds = ids.sort();
      if (sortedIds.join("|") === requiredPlaceholders[id]?.join("|")) {
        return requiredPlaceholders;
      }
      const savedIds = [...requiredPlaceholders[id] ?? [], ...ids]
        .filter(onlyUnique)
        .sort();
      return {
        ...requiredPlaceholders,
        [id]: savedIds
      }
    });

    return additionalProps.requirePlaceholders(ids);
  }, [setRequiredPlaceholders, additionalProps.requirePlaceholders]);

  const requiredPlaceholdersFromPropsMap = useMemo(() => Object.fromEntries(
    /* Return an object where:
     * - The key is the id of the component requiring placeholders
     * - The value is an object where:
     *    - The key is the id of the placeholder
     *    - The value is the value of the placeholder
     */
    Object.entries(requiredPlaceholders)
      .map(([id, ids]) => [
        id,
        Object.fromEntries(ids.map(id => [id, placeholders[id]]))
      ])
  ), [requiredPlaceholders, placeholders]);

  const requiredPlaceholdersFromProps = (props) => {
    if (!props) return {};
    const id = requirePlaceholderId(props);
    return requiredPlaceholdersFromPropsMap[id] ?? {};
  }

  // TODO put this in the debug panel
  window.requiredPlaceholders = requiredPlaceholders;
  window.whoIsRequiring = (placeholder_id) => whoIsRequiringPlaceholder(placeholder_id, requiredPlaceholders);

  return <Component {...appliedProps}
    requirePlaceholders={requirePlaceholders}
    requiredPlaceholdersFromProps={requiredPlaceholdersFromProps}
  />
}
