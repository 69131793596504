import { placeholderIdFromProps } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

function ReportTemplateValueBody(fullProps) {
  const { props } = fullProps;
  const placeholdersHelper = new PlaceholdersHelper(fullProps);

  const displayedValue = placeholdersHelper.displayedValue(
    placeholderIdFromProps(props)
  );
  if (!displayedValue) {
    console.error("No displayed value for", placeholderIdFromProps(props), {
      placeholdersHelper,
    });
    return null;
  }

  return displayedValue
    .map((obj) => {
      if (typeof obj === "string") {
        return obj;
      }
      return obj.value;
    })
    .join(" ");
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateValue({
  props,
  placeholders,
  ...otherProps
}) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateValueBody}
      placeholders={placeholders}
      requiredPlaceholders={[
        placeholderIdFromProps(props),
        "fetus.number",
        "fetus.order",
      ]}
      props={props}
      {...otherProps}
    />
  );
}
