import { isValidElement, cloneElement } from "react";

export default function ReportTemplateHeading({ children, props, reportMode }) {

  const { size = "medium", align = null, color = null, background = null } = (props ?? {});

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };
  const HeaderTag = "h" + (sizes[size] || 2);

  const style = {};
  if (align) style.textAlign = align;
  if (color && reportMode === 'print') style.color = color;
  if (background && reportMode === 'print') style.backgroundColor = background;

  return (
    <HeaderTag style={style}>{children}</HeaderTag>
  );
}

