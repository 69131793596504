import {
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
} from "react";

import Icon from "../../../../atoms/Icon/Icon";
import Button from "../../../../atoms/Button/Button";

import DragAndDropItem from "../../../../components/DragAndDropItem/DragAndDropItem";

import { searchSupportedLibraries } from "../../../../config";
import { getUniqueId } from "../../../../utils";
import { placeholderIdFromProps } from "../../utils";

import ReportTemplateDropdown from "../Dropdown";
import Search from "../Search";

import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

function doRecursivelyApplyMultiSelectValue(children, multiSelectValue) {
  if (Array.isArray(children)) {
    return children.map((child) => {
      if (isValidElement(child)) {
        return recursivelyApplyMultiSelectValue(child, multiSelectValue);
      }
      return child;
    });
  } else if (isValidElement(children)) {
    return recursivelyApplyMultiSelectValue(children, multiSelectValue);
  } else {
    return children;
  }
}

function recursivelyApplyMultiSelectValue(child, multiSelectValue) {
  return {
    ...child,
    props: {
      ...child.props,
      multiSelectValue,
      children: doRecursivelyApplyMultiSelectValue(
        child.props.children,
        multiSelectValue
      ),
    },
  };
}

function ReportTemplateTableBody(fullProps) {
  const {
    fieldId,
    props,
    user,
    children,
    loadDynamicDropdownFullTree,
    onEndEditing = () => {},
    setRequiredAndInvalid,
    showErrors,
    reportMode,
    BIContext: initialBIContext,
    canEdit,
    ...additionalProps
  } = fullProps;

  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const placeholder = placeholdersHelper.selectedValue(fieldId);

  const commonProps = {
    props,
    user,
    placeholdersHelper,
    fieldId,
    placeholder,
    children,
    onEndEditing,
    setRequiredAndInvalid,
    showErrors,
    reportMode,
    BIContext: initialBIContext,
    canEdit: canEdit,
    ...additionalProps,
  };

  const uniqueId = useMemo(() => getUniqueId(), []);
  const dragAndDropContainer = useRef(null);

  const valueObject = placeholder?.value || {};
  const valueArray = Object.entries(valueObject).sort(
    (a, b) =>
      a[1][props["sort-by"] || "order"] - b[1][props["sort-by"] || "order"]
  );
  const isEmpty = !valueArray.length;
  const isEditable = canEdit && placeholdersHelper.editable(fieldId);
  const isEditMode = !reportMode || reportMode === "edit";

  const isRequired = () => {
    return props.required === "true" ? (
      <span className="required">*</span>
    ) : (
      false
    );
  };

  useEffect(() => {
    if (props.required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        isEmpty ? prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [props.required, setRequiredAndInvalid, isEmpty]);

  useEffect(() => {
    if (!placeholdersHelper.isMultiple(fieldId)) {
      console.warn(
        `ReportTemplateTable: ${fieldId} is not a multiple or multi-select placeholder. Please use <dropdown> for such data.`
      );
      return null;
    }
  }, [placeholdersHelper.isMultiple(fieldId), fieldId]);

  const addLine = () => {
    const attribute = placeholdersHelper.attribute(fieldId);
    const selectedValue =
      placeholdersHelper.selectedValue(fieldId)?.[attribute] ?? {};
    const newKey =
      Math.max(
        Object.keys(selectedValue)
          .map((k) => Number(k))
          .filter((v) => !isNaN(v ?? NaN))
      ) + 1;
    const newValue = {
      ...selectedValue,
      [`${newKey}`]: { order: Object.keys(selectedValue).length },
    };

    const BIContext = {
      ...initialBIContext,
      component: "table",
      action: "add-line",
    };
    onEndEditing(
      fieldId,
      placeholdersHelper.editSelectedDataValue(
        fieldId,
        { [attribute]: newValue },
        null,
        "user"
      ),
      { BIContext }
    );
  };

  return (
    <div
      className={`table-wrapper uid-${uniqueId} ${
        isEditable ? "is-editable" : "not-editable"
      } ${isEmpty && !props["empty-message"] ? "is-empty" : ""} ${
        props.required === "true" && showErrors && isEmpty
          ? "required-error"
          : ""
      } ${isEmpty ? "" : "has-printable-value"}`}
    >
      {props.label && (
        <div className="table-label">
          {props.label} {isRequired()}
        </div>
      )}
      {isEmpty && !!props["empty-message"] && (
        <table>
          <tbody>
            <tr>
              <td colSpan="5" className="empty-warning">
                {props["empty-message"]}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {(!isEmpty || isEditMode) && (
        <table>
          {!props["empty-message"] && (
            <ReportTemplateTableHeaders
              children={children || false}
              props={props}
              isRequired={isRequired}
              isEditMode={isEditMode}
            />
          )}
          <tbody ref={dragAndDropContainer}>
            {valueArray.map(([slug], index) => (
              <ReportTemplateTableTr
                key={slug || index}
                uniqueId={uniqueId}
                multiSelectValue={slug}
                dragAndDropContainer={dragAndDropContainer}
                children={children}
                isEditable={isEditable}
                isEditMode={isEditMode}
                {...commonProps}
              />
            ))}
          </tbody>
          {isEditable && (
            <ReportTemplateTableFooter
              addLine={addLine}
              loadDynamicDropdownFullTree={loadDynamicDropdownFullTree}
              {...commonProps}
            />
          )}
        </table>
      )}
    </div>
  );
}

function ReportTemplateTableHeaders({
  children,
  props,
  isRequired,
  isEditMode,
}) {
  if (!children || !Array.isArray(children)) return false;

  const labels = children
    .filter((child) => child?.props?.type === "column")
    .map((child) => child.props?.props?.label);
  if (!props.label && props.required === "true")
    labels[0] = (
      <>
        {labels[0] || ""} {isRequired()}
      </>
    );
  if (labels?.every((label) => !label)) return false;

  return (
    <thead>
      <tr>
        {props.sortable === "true" && isEditMode ? (
          <th className="not-printable"></th>
        ) : (
          false
        )}
        {labels.map((label, index) => (
          <th key={index}>{label}</th>
        ))}
        {isEditMode && <th className="not-printable table_cta">&nbsp;</th>}
      </tr>
    </thead>
  );
}

function ReportTemplateTableFooterContent({ props, addLine, ...commonProps }) {
  const useSearch = searchSupportedLibraries.includes(props.source);
  if (useSearch) return <Search props={props} {...commonProps} />;
  if (props.mode === "collect") {
    return (
      <div className="inline-editing" onClick={addLine}>
        <div className="contenteditable">
          <Icon name="add" />
        </div>
      </div>
    );
  }
  return (
    <ReportTemplateDropdown
      props={{
        ...props,
        compact: true,
        fullwidth: true,
        label: <Icon name="add" />,
      }}
      showOptions={false}
      {...commonProps}
    />
  );
}

function ReportTemplateTableFooter({ props, ...commonProps }) {
  return (
    <tfoot className="not-printable">
      <tr>
        <td colSpan="999">
          <ReportTemplateTableFooterContent props={props} {...commonProps} />
        </td>
      </tr>
    </tfoot>
  );
}

function ReportTemplateTableTr({
  uniqueId,
  placeholdersHelper,
  fieldId,
  multiSelectValue,
  dragAndDropContainer,
  props,
  children,
  onEndEditing,
  isEditable,
  isEditMode,
  BIContext: initialBIContext,
  placeholders: _placeholders,
  ...additionalProps
}) {
  if (!children || !Array.isArray(children)) return false;

  const columns = useMemo(
    () =>
      children
        .filter((child) => child?.props?.type === "column")
        .map((child, index) => {
          const childrenArray =
            !!child.props.children && Array.isArray(child.props.children)
              ? child.props.children
              : [child.props.children];
          return (
            <td key={`${index}`}>
              {childrenArray.map((child, index) => {
                if (child.props?.props?.data === props?.data) {
                  // All the children are considered string for the moment
                  return recursivelyApplyMultiSelectValue(
                    cloneElement(child, {
                      ...additionalProps,
                      key: index,
                      props: { ...child.props.props, format: "string" },
                      onEndEditing,
                      BIContext: initialBIContext,
                    }),
                    multiSelectValue
                  );
                }
                return child;
              })}
            </td>
          );
        }),
    [children, multiSelectValue, props]
  );

  const removeLine = () => {
    const attribute = placeholdersHelper.attribute(fieldId);
    const newValue = placeholdersHelper.selectedValue(fieldId)?.[attribute];

    const { codes, label, order } = newValue[multiSelectValue];
    const line = { codes, value: label, index: order };

    delete newValue[multiSelectValue];

    const BIContext = {
      ...initialBIContext,
      line,
      component: "table",
      action: "delete-line",
    };
    onEndEditing(
      fieldId,
      placeholdersHelper.editSelectedDataValue(
        fieldId,
        { [attribute]: newValue },
        null,
        "user"
      ),
      { BIContext }
    );
  };

  const updateOrder = () => {
    const attribute = placeholdersHelper.attribute(fieldId);
    const newValue = placeholdersHelper.selectedValue(fieldId)?.[attribute];
    let index = 0;
    for (const tr of dragAndDropContainer.current.querySelectorAll(
      ".table_tr"
    )) {
      const id = tr.dataset.id;
      newValue[id].order = index++;
    }

    const { codes, label, order } = newValue[multiSelectValue];
    const line = { codes, value: label, index: order };
    const BIContext = {
      ...initialBIContext,
      component: "table",
      line,
      action: "reorder-line",
      new_index: index,
    };
    onEndEditing(
      fieldId,
      placeholdersHelper.editSelectedDataValue(
        fieldId,
        { [attribute]: newValue },
        null,
        "user"
      ),
      { BIContext }
    );
  };

  return (
    <tr className="table_tr" data-id={multiSelectValue}>
      {props.sortable === "true" && isEditMode ? (
        <td className="draggable not-printable">
          <DragAndDropItem
            isDraggable={true}
            direction="vertical"
            container={dragAndDropContainer.current}
            targetSelector={`.table-wrapper.uid-${uniqueId} .table_tr`}
            createGhost={(draggedElement) => {
              const dragPlaceholder = draggedElement?.cloneNode(true);
              return dragPlaceholder;
            }}
            onDragStart={(_e, attr) => {
              attr.draggedElement?.classList.add("dragged-line");
              if (document.querySelector(".exam-report-content"))
                document.querySelector(".exam-report-content").style.overflow =
                  "hidden";
            }}
            onDragHover={(_e, attr) => {
              if (attr?.hoveredElement) {
                if (attr.mouseOffset.x <= 50) {
                  attr.hoveredElement.parentNode.insertBefore(
                    attr.draggedElement,
                    attr.hoveredElement
                  );
                } else {
                  attr.hoveredElement.parentNode.insertBefore(
                    attr.draggedElement,
                    attr.hoveredElement.nextSibling
                  );
                }
              }
            }}
            onDrop={async (_e, attr) => {
              updateOrder();
              attr.draggedElement?.classList.remove("dragged-line");
              if (document.querySelector(".exam-report-content"))
                document.querySelector(".exam-report-content").style.overflow =
                  "";
              return true;
            }}
          >
            <div />
            <Icon name="drag" />
          </DragAndDropItem>
        </td>
      ) : (
        false
      )}
      {columns}
      {isEditMode && (
        <td className="not-printable table_cta">
          {isEditable && (
            <Button
              icon="trash"
              variant="link"
              size="small"
              onClick={removeLine}
            />
          )}
        </td>
      )}
    </tr>
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateTable({
  props,
  placeholders,
  requirePlaceholders,
  reportMode,
  ...otherProps
}) {
  const fieldId = placeholderIdFromProps(props);
  const requiredPlaceholders = [fieldId, "fetus.order"];

  return (
    <PlaceholderLoader
      Component={ReportTemplateTableBody}
      placeholders={placeholders}
      requirePlaceholders={requirePlaceholders}
      requiredPlaceholders={requiredPlaceholders}
      fieldId={fieldId}
      props={props}
      reportMode={reportMode}
      {...otherProps}
    />
  );
}
