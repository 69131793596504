import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import ResourceApi from "../../../../services/resource";

import Table from "../Table";
import Column from "../Empty";
import Value from "../Value";

import Button from "../../../../atoms/Button/Button";
import Icon from "../../../../atoms/Icon/Icon";

import { placeholderIdFromProps } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";
import {
  toFindingsSuggestions,
  toIndicationsSuggestions,
  getQuestionAdditionalStatement,
  uuidv4,
} from "./utils";

const CodingSection = withTranslation()((fullProps) => {
  const {
    t: __,
    props,
    coding_system,
    examinationAttribute,
    source: _source,
    showErrors,
    addCode,
    update: _update,
    suggestions = [],
    user,
    canEdit,
    ...otherProps
  } = fullProps;
  /* Take returns of the Table component and makes it a code understandable data
   */
  const fieldId = placeholderIdFromProps(props);

  return (
    <div className="exam-report-coding-section">
      <div className="exam-report-coding-section_title">
        <h2>{__("reportCoding.system." + coding_system)}</h2>
      </div>
      <div className="exam-report-coding-section_table">
        <Table
          {...otherProps}
          props={props}
          canEdit={canEdit}
          user={user}
          showErrors={showErrors}
        >
          {[
            <Column
              key="col1"
              props={{ label: __("reportCoding.table.code"), width: "10%" }}
              type="column"
            >
              {[
                <Value
                  key="value1"
                  props={{ data: fieldId, attribute: "code" }}
                  {...otherProps}
                />,
              ]}
            </Column>,
            <Column
              key="col2"
              props={{ label: __("reportCoding.table.description") }}
              type="column"
            >
              {[
                <Value
                  key="value2"
                  props={{ data: fieldId, attribute: "description" }}
                  {...otherProps}
                />,
              ]}
            </Column>,
          ]}
        </Table>
        {canEdit && (
          <SuggestionsSection
            suggestions={suggestions}
            addCode={addCode}
            examinationAttribute={examinationAttribute}
            coding_system={coding_system}
          />
        )}
      </div>
    </div>
  );
});

const SuggestionsSection = withTranslation()(
  ({ t: __, suggestions, addCode, examinationAttribute, coding_system }) => {
    return (
      !!suggestions?.length && (
        <div className="exam-report-coding-section_suggestions">
          <h6 className="section-title">
            {__("reportCoding.suggestionSentence." + coding_system)}
          </h6>
          {suggestions?.map((suggestion, i) => (
            <div
              key={i}
              onClick={() => addCode(examinationAttribute, suggestion)}
              className="exam-report-coding-section_suggestion"
            >
              <Icon name="add" />
              <span className="code">{suggestion.code}</span>
              <span className="meta">
                <span className="description">{suggestion.description}</span>
                {!!suggestion.origin_value &&
                  suggestion.origin_value !== suggestion.description && (
                    <span className="suggested">{suggestion.origin_value}</span>
                  )}
              </span>
            </div>
          ))}
        </div>
      )
    );
  }
);

const CodingBody = (fullProps) => {
  const {
    t: __,
    showErrors,
    user,
    canEdit,
    props: _props,
    placeholders,
    hijackedRequirePlaceholders,
    onEndEditing,
    applyChanges,
    BIContext,
    reportDataOptions,
    isFeatureFlagEnabled,
    ...otherProps
  } = fullProps;
  const [defaultProceduresCodes, setDefaultProceduresCodes] = useState([]);
  const [diagnosticSuggestions, setDiagnosticSuggestions] = useState([]);

  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const examination_id =
    placeholdersHelper.selectedValue("examination.id")?.value;
  const preset_id = placeholdersHelper.selectedValue(
    "examination.preset_id"
  )?.value;
  const trimester = placeholdersHelper.selectedValue(
    "examination.trimester"
  )?.value;
  const findings =
    placeholdersHelper.selectedValue("examination.finding")?.value || {};
  const indications =
    placeholdersHelper.selectedValue("examination.indication")?.value || {};
  const methods =
    placeholdersHelper.selectedValue("examination.method")?.value || {};
  const diagnoses_codes_map =
    placeholdersHelper.selectedValue("examination.diagnoses_codes")?.value ||
    {};
  const diagnoses_codes = Object.values(diagnoses_codes_map);
  const procedures_codes_map =
    placeholdersHelper.selectedValue("examination.procedures_codes")?.value ||
    {};
  const mandatory =
    placeholdersHelper.selectedValue("examination.coding.mandatory")?.value ||
    false;
  const procedures_codes = Object.values(procedures_codes_map);

  useEffect(() => {
    if (!methods) return;
    ResourceApi.getDefaultProceduresCodes(examination_id)
      .then((resp) => setDefaultProceduresCodes([{ codes: resp.data.data }]))
      .catch((e) => console.error(e));
  }, [preset_id, Object.keys(methods).sort().join("|")]);

  useEffect(() => {
    const findingsSuggestions = toFindingsSuggestions(findings);
    const indicationsSuggestions = toIndicationsSuggestions(indications);

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(indications),
      dropdownToList(findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(trimester)
    )
      .then(({ data }) =>
        setDiagnosticSuggestions(
          data.indications
            .concat(indicationsSuggestions)
            .concat(data.findings)
            .concat(findingsSuggestions)
        )
      )
      .catch((e) => console.error(e));
    /* TODO how do we show the user something wrong is going on with Sonio or IMO */
  }, [
    Object.keys(findings).sort().join("|"),
    Object.keys(indications).sort().join("|"),
  ]);

  const filterDiagSuggestions = (suggestions, codes) => {
    const selectedCodes = codes.map(({ code }) => code);
    return (
      Object.values(
        Object.groupBy(
          suggestions.flatMap((suggestion) =>
            suggestion.codes.map((c) => ({
              id: uuidv4(),
              ...c,
              origin_value: suggestion.description,
            }))
          ),
          ({ code }) => code
        )
      )
        // Only unique code for diagnostic codes
        .map((codes) => {
          const origin_value = codes
            .filter(({ origin_value }) => origin_value)
            .map(({ origin_value }) => origin_value)
            .join(", ");
          return {
            ...codes[0],
            origin_value,
          };
        })
        // Remove the code if already in the dropdown
        .filter(({ code }) => !selectedCodes.includes(code))
    );
  };

  const filterProceduresSuggestions = (suggestions, codes) => {
    const selectedCodes = Object.fromEntries(
      Object.values(Object.groupBy(codes, ({ code }) => code)).map((codes) => [
        codes[0].code,
        codes.length,
      ])
    );
    return (
      Object.values(
        Object.groupBy(
          suggestions.flatMap((suggestion) =>
            suggestion.codes.map((c) => ({
              id: uuidv4(),
              ...c,
              origin_value: suggestion.description,
            }))
          ),
          ({ code }) => code
        )
      )
        // Remove code if already present in the table by the number of already selected codes
        // If rules suggest 3 76830 CPT and 1 76830 is already present in the table
        // We must only display 2 76830 CPT
        .flatMap((codes) => codes.slice(selectedCodes[codes[0].code] || 0))
    );
  };

  const diagnosticSuggestionsFiltered = filterDiagSuggestions(
    diagnosticSuggestions,
    diagnoses_codes
  );
  const defaultProceduresCodesFiltered = filterProceduresSuggestions(
    defaultProceduresCodes,
    procedures_codes
  );

  const addAllSuggestedCodes = async () => {
    const newProceduresCodes = {
      ...defaultProceduresCodesFiltered,
      ...procedures_codes_map,
    };
    const newDiagnosesCodes = {
      ...diagnosticSuggestionsFiltered,
      ...diagnoses_codes_map,
    };
    applyChanges(
      {
        "examination.diagnoses_codes": placeholdersHelper.editSelectedDataValue(
          "examination.diagnoses_codes",
          { value: newDiagnosesCodes },
          null,
          "user"
        ),
        "examination.procedures_codes":
          placeholdersHelper.editSelectedDataValue(
            "examination.procedures_codes",
            { value: newProceduresCodes },
            null,
            "user"
          ),
      },
      { BIContext }
    );
  };

  const addCode = (examinationAttribute, code) => {
    switch (examinationAttribute) {
      case "diagnoses_codes":
        return onEndEditing(
          "examination.diagnoses_codes",
          placeholdersHelper.editSelectedDataValue(
            "examination.diagnoses_codes",
            { value: { ...diagnoses_codes_map, [code.id]: code } },
            null,
            "user"
          ),
          { BIContext }
        );
      case "procedures_codes":
        return onEndEditing(
          "examination.procedures_codes",
          placeholdersHelper.editSelectedDataValue(
            "examination.procedures_codes",
            { value: { ...procedures_codes_map, [code.id]: code } },
            null,
            "user"
          ),
          { BIContext }
        );
    }
  };

  const resetDiagnosesCodes = async () => {
    const findingsSuggestions = toFindingsSuggestions(findings);
    const indicationsSuggestions = toIndicationsSuggestions(indications);

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(placeholder.indications),
      dropdownToList(placeholder.findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(trimester)
    )
      .then(({ data }) => {
        const diagnosesCodes = data.indications
          .concat(indicationsSuggestions)
          .concat(data.findings)
          .concat(findingsSuggestions);

        const manuallyAddedDiagCodes = diagnoses_codes.filter(
          (code) => code.origin === "manual"
        );

        const newDiagnosesCodes = Object.fromEntries(
          [
            ...filterDiagSuggestions(diagnosesCodes, []),
            ...manuallyAddedDiagCodes,
          ].map((code, idx) => [code.id, { ...code, order: idx }])
        );

        const newProceduresCodes = Object.fromEntries(
          [...defaultProceduresCodesFiltered, ...procedures_codes].map(
            (code) => [code.id, code]
          )
        );

        applyChanges(
          {
            "examination.diagnoses_codes":
              placeholdersHelper.editSelectedDataValue(
                "examination.diagnoses_codes",
                { value: newDiagnosesCodes },
                null,
                "user"
              ),
            "examination.procedures_codes":
              placeholdersHelper.editSelectedDataValue(
                "examination.procedures_codes",
                { value: newProceduresCodes },
                null,
                "user"
              ),
          },
          { BIContext }
        );
      })
      .catch((e) => console.error(e));
  };

  const numberOfSuggestions =
    diagnosticSuggestionsFiltered.length +
    defaultProceduresCodesFiltered.length;

  return (
    <div className="exam-report-coding">
      <div className="exam-report-coding_heading">
        <h1>{__("reportCoding.title")}</h1>
        {isFeatureFlagEnabled("sonio.coding.reorder_diagnostic_codes_btn") ? (
          <Button
            hint={__("reportCoding.system.resetHint")}
            onClick={resetDiagnosesCodes}
            label={__("reportCoding.system.reset")}
            icon="reload"
            size="small"
          />
        ) : (
          !!numberOfSuggestions &&
          canEdit && (
            <Button
              onClick={addAllSuggestedCodes}
              label={__("reportCoding.addAllSuggestedCodes", {
                count: numberOfSuggestions,
              })}
              icon="add"
              size="small"
            />
          )
        )}
      </div>
      <CodingSection
        props={{
          data: "examination.diagnoses_codes",
          sortable: "true",
          source: "ICD10",
          required: `${mandatory}`,
          "empty-message": __("reportCoding.nothingToShow"),
          "search-placeholder": __("reportCoding.searchPlaceholder.diagnostic"),
        }}
        reportDataOptions={reportDataOptions}
        addCode={addCode}
        examinationAttribute="diagnoses_codes"
        onEndEditing={onEndEditing}
        coding_system="diagnostic"
        source="ICD10"
        showErrors={showErrors}
        suggestions={diagnosticSuggestionsFiltered}
        user={user}
        canEdit={canEdit}
        placeholders={placeholders}
        BIContext={BIContext}
        {...otherProps}
        requirePlaceholders={hijackedRequirePlaceholders}
      />
      <CodingSection
        props={{
          data: "examination.procedures_codes",
          sortable: "true",
          source: "CPT",
          required: `${mandatory}`,
          "empty-message": __("reportCoding.nothingToShow"),
          "search-placeholder": __("reportCoding.searchPlaceholder.procedure"),
        }}
        reportDataOptions={reportDataOptions}
        addCode={addCode}
        examinationAttribute="procedures_codes"
        onEndEditing={onEndEditing}
        coding_system="procedure"
        showErrors={showErrors}
        suggestions={defaultProceduresCodesFiltered}
        user={user}
        canEdit={canEdit}
        placeholders={placeholders}
        BIContext={BIContext}
        {...otherProps}
        requirePlaceholders={hijackedRequirePlaceholders}
      />
    </div>
  );
};

const WithTranslationCodingBody = withTranslation()(CodingBody);

const defaultRequiredPlaceholdersIds = [
  "examination.finding",
  "examination.indication",
  "examination.trimester",
  "examination.preset_id",
  "examination.id",
  "examination.method",
  "examination.diagnoses_codes",
  "examination.procedures_codes",
  "examination.coding.mandatory",
  "fetus.order",
];
export default function Coding({
  props,
  placeholders,
  requirePlaceholders,
  ...otherProps
}) {
  const [requiredPlaceholders, setRequiredPlaceholders] = useState(
    defaultRequiredPlaceholdersIds
  );

  const hijackedRequirePlaceholders = (_props, ids) => {
    /* We don't need to check if ids are uniq as PlaceholderLoader take care of it */
    setRequiredPlaceholders((previouslyRequiredPlaceholders) => [
      ...ids,
      ...previouslyRequiredPlaceholders,
    ]);
  };

  return (
    <PlaceholderLoader
      Component={WithTranslationCodingBody}
      requirePlaceholders={requirePlaceholders}
      hijackedRequirePlaceholders={hijackedRequirePlaceholders}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      props={props}
      {...otherProps}
    />
  );
}
