import { withTranslation } from "react-i18next";
import PlaceholderLoader from "../../PlaceholderLoader";

const ReportTemplateMedicalHistoryBody = ({ t: __, props, displayRisks, medicalHistory = [] }) => {

    return <pre style={{ fontSize: 10, lineHeight: "1.1em" }}>ReportTemplateMedicalHistoryBody</pre>

    const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

    const { status = null } = (props ?? {});
    const showNormal = (status !== "not-normal");

    if (medicalHistory?.teratogenic_risks && !Array.isArray(medicalHistory?.teratogenic_risks)) medicalHistory.teratogenic_risks = Object.values(medicalHistory?.teratogenic_risks);

    return (
        <div className="medical-history">
            {medicalHistory?.risk_factors?.filter(item => status === null || showNormal !== item.is_risky).map((item, index) => <div key={index} className={`medical-history-item ${displayRisks && item.is_risky ? 'at-risk' : ''}`}>
                <div className="label">{item.label[currentLanguage]}</div>
                <span className="medical-history-content">{item.value?.[currentLanguage]}</span>
            </div>)}
            {medicalHistory?.teratogenic_risks?.filter(item => status === null || showNormal !== !!item.risk_factor_id).map(item => <div key={item.id} className={`medical-history-item ${item.risk_factor_id ? 'at-risk' : ''}`}>
                <div className="label">{__("report.drug")}</div>
                <span className="medical-history-content">{item.label[currentLanguage]} {item.risk_factor_id ? <small className="teratogenic">{__("report.isTeratogenic")}</small> : ''}</span>
            </div>)}
        </div>
    );
}

const WithTranslationReportTemplateMedicalHistoryBody = withTranslation()(ReportTemplateMedicalHistoryBody);

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateMedicalHistory({
    props,
    placeholders,
    ...otherProps
}) {
    const fieldId = ['deprecated.medical-history'];

    return (
        <PlaceholderLoader
            Component={WithTranslationReportTemplateMedicalHistoryBody}
            placeholders={placeholders}
            requiredPlaceholders={[fieldId]}
            medicalHistory={placeholders[fieldId]}
            props={props}
            {...otherProps}
        />
    );
};

