import { withTranslation } from "react-i18next";
import { getNiceGestionalAgeFromDays } from "../../../../services/examination";
import { getPercentileValueString } from "../../../../services/measurements";
import { formatYYYYMMDDDate } from "../../../../utils";
import Icon from "../../../../atoms/Icon/Icon";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

import { measurementCruveSlug, placeholderIdFromProps } from "../../utils";

const defaultMeasurements = ["bpd", "hc", "ac", "fl", "hl", "efw"];

const ReportFetalGrowthTableBody = (fullProps) => {
  const {
    t: __,
    props,
    placeholders,
    appPreferences,
    reportMode,
    onEndEditing = () => {},
    columnsLabelsToDisplay,
    measurementsToDisplay,
    reportDataOptions,
    apiVersion,
  } = fullProps;

  const previousExams = reportDataOptions?.previous_exams || {};
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const placeholder = placeholdersHelper.selectedValue("fetal_growth")?.value;

  const curveSlugs = [...new Array(placeholdersHelper.numberOfFetuses)]
    .map((_, idx) => idx + 1)
    .reduce((acc, fetus) => {
      acc[fetus] = {};
      for (const measurement of measurementsToDisplay) {
        const [slug, _] = measurement?.split(".");
        const curveSlug = measurementCruveSlug(
          { data: slug },
          placeholders,
          apiVersion
        );
        acc[fetus][slug] = curveSlug;
      }
      return acc;
    }, {});

  const examinationDate = new Date(
    placeholdersHelper.selectedValue("examination.date", 0)?.value
  );

  const defaultCurveSlug = (percentiles) => {
    const available_cruve_slugs = Object.keys(percentiles || {});

    return (
      available_cruve_slugs.find((slug) => slug.includes("hadlock")) ??
      available_cruve_slugs[0]
    );
  };

  const getDataFromPreviousExams = (fetus) => {
    return Object.values(previousExams || {})
      .filter(
        ({ examination_date }) =>
          new Date(examination_date?.split("T")?.[0]) <= examinationDate
      )
      .sort(
        (a, b) => new Date(a.examination_date) - new Date(b.examination_date)
      )
      .map(({ examination_date, measurements, dating }) => {
        const data = measurements?.measurements?.fetus?.[`${fetus}`];
        const vals = measurementsToDisplay.map((measurement) => {
          const [slug, _] = measurement.split(".");
          const { y: value, percentiles } = data?.[slug]?.selected_value || {};
          const curveSlug =
            curveSlugs?.[`${fetus}`]?.[slug] ??
            defaultCurveSlug(percentiles, slug);
          const percentile = percentiles?.[curveSlug]?.percentile;
          return { value, percentile, slug, curveSlugs };
        });
        return [
          { value: examination_date, slug: "examination_date" },
          { value: dating?.value, slug: "ga" },
          ...vals,
        ];
      });
  };

  const getDataFromCurrentExam = (fetus) => {
    const data = measurementsToDisplay.map((measurement) => {
      const [slug, _] = measurement?.split(".");
      const placeholder = placeholdersHelper.selectedValue(
        `measurement.${slug}`,
        fetus
      );

      const curveSlug = curveSlugs?.[fetus]?.[slug];
      return {
        slug,
        percentile: placeholder?.percentiles?.[curveSlug]?.percentile,
        value: placeholder?.value,
      };
    });

    const examinationDatePlaceholder = placeholdersHelper.selectedValue(
      "examination.date",
      0
    );
    const assignedGAPlaceholder = placeholdersHelper.selectedValue(
      "ga.assigned.value",
      0
    );
    return [
      { ...examinationDatePlaceholder, slug: "examination_date" },
      // TODO instead of using the GA value we should use the translated GA value "ga.assigned"
      { ...assignedGAPlaceholder, slug: "ga" },
      ...data,
    ];
  };

  const getDataFromAllExams = () => {
    const data = {};
    for (let fetus = 1; fetus <= placeholdersHelper.numberOfFetuses; fetus++) {
      data[fetus] = [
        ...getDataFromPreviousExams(fetus),
        getDataFromCurrentExam(fetus),
      ];
    }
    return data;
  };

  const dataFromAllExams = getDataFromAllExams();

  const createDefaultTableMetadata = () => {
    const data = {};
    const numExams = Object.keys(previousExams).length + 1;
    for (
      let fetus = 1;
      fetus <= (placeholdersHelper.numberOfFetuses || 1);
      fetus++
    ) {
      data[`fetus-${fetus}`] = new Array(numExams).fill().map((_, i) => {
        const visible = (dataFromAllExams?.[fetus]?.[i] || [])
          .filter((elem) =>
            measurementsToDisplay.some((measurement) =>
              measurement.startsWith(elem.slug)
            )
          )
          .some((elem) => elem.value);
        return { visible };
      });
    }
    return data;
  };

  const defaultMetadataTable = createDefaultTableMetadata();
  const tableMetadata = Object.entries({
    ...defaultMetadataTable,
    ...placeholder,
  })
    .filter(([key, _value]) => key.startsWith("fetus-"))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  // If no table is visible hide the Title
  const titleVisible = Object.values(tableMetadata || {}).some((rows) =>
    rows?.some((row) => row.visible)
  );

  const onClickVisible = (id) => async () => {
    const data = JSON.parse(JSON.stringify(tableMetadata));
    if (id.startsWith("row")) {
      const [_, fetus, row] = id.split("-");
      const fetusId = `fetus-${fetus}`;
      data[fetusId][row].visible = !data[fetusId][row].visible;
    } else {
      const newVisibleValue = !data[id].some((row) => row.visible);
      data[id] = data[id].map((_) => ({ visible: newVisibleValue }));
    }
    await onEndEditing("fetal_growth", [
      { value: { value: data }, source: "user", selected: true },
    ]);
  };

  return (
    <div className="fetal-growth-table-container">
      <h4
        className={`fetal-growth-title ${
          titleVisible ? "is-visible is-printable" : "not-visible not-printable"
        }`}
        style={{}}
      >
        {props.title}
      </h4>
      {Array(placeholdersHelper.numberOfFetuses)
        .fill()
        .map((_, idx) => {
          const fetus = idx + 1;
          const tableId = `fetus-${fetus}`;
          const tableVisible = tableMetadata?.[tableId]?.some(
            (row) => row.visible
          );

          return (
            dataFromAllExams[fetus].some((d) => d.some((dd) => dd.value)) && (
              <div
                className={`fetal-growth ${
                  tableVisible ? "is-visible" : "not-visible"
                }`}
                key={fetus}
              >
                <div className="fetal-growth-report-options-container">
                  <div>
                    {placeholdersHelper.numberOfFetuses > 1 && (
                      <div className="fetal-growth-fetus-label">
                        {__("report.fetusName", {
                          label: placeholdersHelper.selectedValue(
                            "fetus.name",
                            fetus
                          )?.value,
                        })}
                      </div>
                    )}
                  </div>
                  {tableVisible && reportMode === "edit" && (
                    <div className="content-options exam-report-editing-options">
                      <div onClick={onClickVisible(tableId)}>
                        <Icon name={tableVisible ? "eye" : "eye-off"} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="fetal-growth-table">
                  <div className={`fetal-growth-item column-heading`}>
                    {columnsLabelsToDisplay.map((label, index) => {
                      return <div key={index}>{label || <>&nbsp;</>}</div>;
                    })}
                  </div>
                  {dataFromAllExams[fetus].map((data, index) => {
                    return (
                      <FetalGrowthItem
                        key={index}
                        defaultKey={index}
                        data={data}
                        rowMetadata={tableMetadata?.[tableId] || []}
                        appPreferences={appPreferences}
                        onClickVisible={onClickVisible}
                        fetus={fetus}
                      />
                    );
                  })}
                </div>
                {!tableVisible && reportMode === "edit" && (
                  <NotVisibleOverlay onClick={onClickVisible(tableId)} />
                )}
              </div>
            )
          );
        })}
    </div>
  );
};

const FetalGrowthCell = withTranslation()(({ t: __, data, appPreferences }) => {
  let { value, percentile, slug } = data || {};
  switch (slug) {
    case "ga":
      value = getNiceGestionalAgeFromDays(__, Math.floor(value));
      percentile = false;
      break;
    case "examination_date":
      // TODO remove this ugly fix
      if (!value) break;
      value = formatYYYYMMDDDate(value, appPreferences?.date_format);
      percentile = false;
      break;
    case "efw":
      value = (isNaN(value) ? value : value.toFixed(0)) ?? "-";
      percentile = getPercentileValueString(percentile, __);
      break;
    default:
      value = (isNaN(value) ? value : value.toFixed(1)) ?? "-";
      percentile = getPercentileValueString(percentile, __);
      break;
  }

  return (
    <div className="fetal-growth-cell">
      <span className="fetal-growth-cell-value">{value}</span>
      {percentile && <span className="fetal-growth-cell-seperator">|</span>}
      <span className="fetal-growth-cell-percentile">{percentile}</span>
    </div>
  );
});

const FetalGrowthItem = withTranslation()(
  ({
    t: __,
    data: allData,
    appPreferences,
    rowMetadata,
    defaultKey = "",
    onClickVisible,
    fetus,
  }) => {
    // Don't show row if all values are empty
    if (
      !allData.some(
        (d) => !["examination_date", "ga"].includes(d.slug) && d.value
      )
    )
      return null;

    const rowId = `row-${fetus}-${defaultKey}`;

    return (
      <div
        key={defaultKey}
        className={`fetal-growth-item ${
          rowMetadata?.[defaultKey]?.visible ? "is-visible" : "not-visible"
        }`}
      >
        {allData.map((data, index) => {
          return (
            <FetalGrowthCell
              data={data}
              key={index}
              appPreferences={appPreferences}
            />
          );
        })}
        <div className="content-options exam-report-editing-options">
          <div onClick={onClickVisible(rowId)}>
            <Icon
              name={rowMetadata?.[defaultKey]?.visible ? "eye" : "eye-off"}
            />
          </div>
        </div>
      </div>
    );
  }
);

const WithTranslationReportFetalGrowthTableBody = withTranslation()(
  ReportFetalGrowthTableBody
);

/* This is just a squelton to ensure placeholders are loaded */
const ReportTemplateFetalGrowthTable = ({
  props,
  placeholders,
  t: __,
  ...otherProps
}) => {
  const fieldId = placeholderIdFromProps(props);
  const userMeasurements = props.measurements?.split("|") ?? [];
  const measurementsToDisplay =
    userMeasurements.length !== 0 ? userMeasurements : defaultMeasurements;
  const measurementsRequiredPlacehodlers = measurementsToDisplay.map(
    (measurement) => `measurement.${measurement?.split(".")[0]}`
  );
  const requiredPlaceholders = [
    fieldId,
    "fetus.name",
    "fetal_growth",
    "fetus.order",
    "examination.date",
    "ga.assigned.value",
    ...measurementsRequiredPlacehodlers,
  ];

  const defaultColumnLabels = [
    __("report.fetalGrowth.examDate"),
    __("report.fetalGrowth.ga"),
    __("report.fetalGrowth.bpd"),
    __("report.fetalGrowth.hc"),
    __("report.fetalGrowth.ac"),
    __("report.fetalGrowth.fl"),
    __("report.fetalGrowth.hl"),
    __("report.fetalGrowth.efw"),
  ];

  const columnLabelsArray = props["column-labels"]?.split("|") || [];
  const columnsLabelsToDisplay =
    columnLabelsArray.length === 0 ? defaultColumnLabels : columnLabelsArray;

  return (
    <PlaceholderLoader
      Component={WithTranslationReportFetalGrowthTableBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      measurementsToDisplay={measurementsToDisplay}
      columnsLabelsToDisplay={columnsLabelsToDisplay}
      props={props}
      {...otherProps}
    />
  );
};

export default withTranslation()(ReportTemplateFetalGrowthTable);
