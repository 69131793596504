import { useState, useContext } from "react";
import Button from "../../atoms/Button/Button";
import SharingPreviewPopup from "./SharingPreviewPopup";
import { ExaminationContext } from "../../context-providers/Examination";
import "./InstantQrCode.css";
import ResourceApi from "../../services/resource";
import { trackEvent } from "../ExaminationSharingFlow/examinationTracking";

const InstantQrCode = ({ variant }) => {
  const examinationContext = useContext(ExaminationContext);
  const [qrCodePopupOpen, setQrCodePopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openQrCodePopup = async () => {
    if (loading || qrCodePopupOpen) return;
    setLoading(true);
    try {
      if (!examinationContext.share?.instant_share_link) {
        await examinationContext.shareExamination();
      }

      const sharedInstances = await ResourceApi.sharingList(
        examinationContext.examination.id
      ).then((resp) => resp.data.data);
      if (sharedInstances?.length) {
        setQrCodePopupOpen(true);

        trackEvent("user_shares_with_the_QR_button", examinationContext);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <Button
          icon="qrcode"
          onClick={openQrCodePopup}
          variant={loading || qrCodePopupOpen ? "contained-shadow" : variant}
        />
      </div>
      {qrCodePopupOpen && (
        <SharingPreviewPopup onClose={() => setQrCodePopupOpen(false)} />
      )}
    </>
  );
};

export default InstantQrCode;
