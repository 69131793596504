import { useContext, useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { WindowContext } from "../../context-providers/Window";
import { ExaminationContext } from "../../context-providers/Examination";

import Button from "../../atoms/Button/Button";

import { XMLDocument } from "../../components/XMLDocument";
import PatientName from "../../components/PatientName/PatientName";

import ResourceApi from "../../services/resource";

import "./ExaminationCustomMedicalHistoryView.css";

const ExaminationCustomMedicalHistoryView = ({
  t: __,
  isFeatureFlagEnabled,
  reportDataOptions,
  appContext,
}) => {
  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const templateBlueprint =
    examinationContext.debugTemplate ||
    reportDataOptions?.medical_history_template?.blueprint;

  const { examId } = useParams();
  const history = useHistory();

  const [frozenMedicalHistory, setFrozenMedicalHistory] = useState({
    state: "not_loaded",
  });

  const readFrozenMedicalHistoryHtml = useCallback(
    async (mode) => {
      return ResourceApi.readDocumentHtml(
        examinationContext.examination.id,
        mode,
        "medical_history"
      )
        .then((response) => response)
        .catch(({ response }) => response);
    },
    [examinationContext.examination.id, examinationContext.examination.canEdit]
  );

  const readAllFrozenMedicalHistoryHtml = useCallback(async () => {
    const edit = await readFrozenMedicalHistoryHtml("edit");
    const print = await readFrozenMedicalHistoryHtml("print");
    return { edit, print };
  }, [readFrozenMedicalHistoryHtml]);

  useEffect(() => {
    if (examinationContext.examination.status !== "report_signed") return;
    readAllFrozenMedicalHistoryHtml().then(({ edit, print }) => {
      if (edit.status === 200 && print.status === 200) {
        setFrozenMedicalHistory({
          state: "loaded",
          edit: edit.data,
          print: print.data,
        });
      } else {
        setFrozenMedicalHistory({ state: "error" });
      }
    });
    return () => setFrozenMedicalHistory({ state: "not_loaded" });
  }, [
    readFrozenMedicalHistoryHtml,
    examinationContext.examination.status === "report_signed",
  ]);

  const backToExamination = (url = false) => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (url === false) url = `/exam/${examId}`;

    if (
      url.match(new RegExp(`^/exam/${Number(examId)}$`)) &&
      isFeatureFlagEnabled("soniopedia")
    )
      url += "/dx";

    history.push(url);
  };

  const backToDashboard = () => {
    history.push("/");
  };

  return (
    <div className="custom-medical-history-container">
      {!!templateBlueprint && (
        <>
          <div className="custom-medical-history-header">
            <PatientName
              patient={examinationContext.patient}
              episode={examinationContext.episode}
              examinationDate={examinationContext.examination.examination_date}
            />
          </div>
          <div className="exam-report-content">
            {!examinationContext.canEdit && (
              <div className="exam-report-frozen-warning">
                <div />
              </div>
            )}
            {(examinationContext.canEdit ||
              frozenMedicalHistory.state === "error") && (
              <XMLDocument
                templateBlueprint={templateBlueprint}
                page="medical-history"
                reportMode="edit"
              />
            )}
            {!examinationContext.canEdit &&
              frozenMedicalHistory.state === "loaded" && (
                <XMLDocument
                  templateBlueprint={templateBlueprint}
                  page="medical-history"
                  reportMode="edit"
                  frozenHTML={frozenMedicalHistory.edit}
                />
              )}
          </div>
        </>
      )}

      {!windowContext.isDetached && (
        <div className="examination-medical-history-cta-bar">
          {appContext.referer.match(
            new RegExp(`^/exam/${Number(examId)}/dx$`)
          ) ? (
            <Button
              label={__("examination.backToExamination")}
              iconAfter="right-big"
              onClick={() => backToExamination(appContext.referer)}
            />
          ) : appContext.referer.match(
              new RegExp(`^/exam/${Number(examId)}$`)
            ) ? (
            <Button
              label={__("examination.backToExamination")}
              iconAfter="right-big"
              onClick={() => backToExamination(appContext.referer)}
            />
          ) : examinationContext.examination.status === "draft" ? (
            <>
              <Button
                label={__("examination.backToTheDashboard")}
                icon="home"
                onClick={() => backToDashboard()}
              />
              <Button
                label={__("examination.startTheExamination")}
                variant="outline"
                iconAfter="right-big"
                onClick={() => backToExamination()}
              />
            </>
          ) : (
            <Button
              label={
                examinationContext.examination.status !== "draft"
                  ? __("examination.backToExamination")
                  : __("examination.startTheExamination")
              }
              iconAfter="right-big"
              onClick={() => backToExamination()}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ExaminationCustomMedicalHistoryView);
