

/*
 * @description Normalizes the tree for a dynamic dropdown to be compatible with nested collection
 * @param {Object} data
 * @returns {Object} normalized data
 */
export function normalizeDropdown(data) {
  const tree = data?.tree?.map(node => {
    return { ...normalizeDropdown(node) };
  });
  return { ...data, tree, id: `${data.slug}` };
}

/*
 * @description Normalizes the tree for all dynamic dropdowns to be compatible with nested collection
 * @param {Object} data
 * @returns {Object} normalized data
 */
export function normalizeAllDropdown(data) {
  return Object.fromEntries(
    Object.entries(data)
      .map(([key, value]) => [key, { ...normalizeDropdown(value), id: value.id }])
  );
}
