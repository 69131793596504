import { useState } from "react";
import { withTranslation } from 'react-i18next';

const SimpleSearchEngine = ({ t: __, children, onSearch, filters, onSelect, opts, "search-placeholder": searchPlaceholder, quickFilters }) => {
  /* Destructring opts a first time to get default values */
  const {
    searchDebounceDelay = 500,
    minLength = 3
  } = (opts || {});

  const warning = {
    label: __("signSearchBar.typeAtLeastNChars", {amount: minLength}),
    value: false,
    type: 'warning',
    icon: 'warning'
  };

  /* Destructuring opts a second time to get defaultResult that is a dependant of minLength value */
  const {
    defaultResult = [warning]
  } = (opts || {});

  const [searchState, setSearchState] = useState({timeout: null, searchKey: ""});
  const [previousSearchedKey, setPreviousSearchedKey] = useState("");
  const [results, setResults] = useState(null);

  const { searchKey } = searchState;

  const setSearchKey = (searchKey) => {
    clearTimeout(searchState.timeout)
    if(previousSearchedKey == searchKey) {
      return setSearchState({searchKey, timeout: null});
    }

    if(searchKey.length < minLength) {
      setResults(null);
      setPreviousSearchedKey(searchKey);
       return setSearchState({searchKey, timeout: null});
    }

    const timeout = setTimeout(async () => {
      const newResults = await onSearch(searchKey);
      setResults(newResults);
      setPreviousSearchedKey(searchKey);
    }, searchDebounceDelay);

    setSearchState({searchKey, timeout});
  };

  const setSelecting = (selecting) => {
    if(!selecting) return;
    onSelect(selecting);

    setSearchState(s => ({
      ...s,
      searchKey: "",
    }));
    setResults(null);
  };

  const onChange = async (searchKey) => {
    if (searchKey && searchKey === previousSearchedKey) {
      const newResults = await onSearch(searchKey);
      setResults(newResults);
    }
    else setSearchKey(searchKey);
  }

  const localOnSelect = (result) => {
    if (["heading", "warning"].includes(result.type)) return false;
    setSelecting(result);
    return true;
  }

  if(typeof onSearch !== 'function')
    throw "search parameters should be a function";

  const childrenProps = {
    searchKey,
    filters,
    placeholder: searchPlaceholder,
    onChange,
    onSelect: localOnSelect,
    results: !!results?.length ? results : defaultResult,
    loading: searchKey !== "" && previousSearchedKey != searchKey,
    quickFilters,
  };

  return children ? (
    Array.isArray(children)
    ? children.map(c => ({...c, props: childrenProps}))
    : {...children, props: childrenProps}
  ) : false;
}

export default withTranslation()(SimpleSearchEngine);
