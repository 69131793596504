import { compare } from "semver"

export default class ChecklistItemsHelper {
  constructor({ apiVersion, currentLanguage, items, currentFetus, currentFetusId }) {
    this.apiVersion = apiVersion
    this.currentLanguage = currentLanguage
    this.currentFetus = currentFetus
    this.currentFetusId = currentFetusId
    this.items = items
    this.normalizedItems = this.#normalizeItems()
  }

  checklistItemsToDisplay(placeholders) {
    if (this.apiVersion == "2.0") {
      return this.#checklistItemsToDisplayV2(placeholders)
    }

    return this.#checklistItemsToDisplayV1(placeholders)
  }

  #checklistItemsToDisplayV1(placeholders) {
    return Object.entries(placeholders)
      .filter(([key, _]) => key.startsWith("checklist.item."))
      .filter(([_, value]) => Array.isArray(value) && value.some(valuePerFetus => valuePerFetus.active))
      .map(item => item[1])
  }

  #checklistItemsToDisplayV2(placeholders) {
    return Object.entries(placeholders)
      .filter(([key, _]) => key.startsWith("checklist.item."))
      .filter(([_, value]) => Array.isArray(value.data) && value.data.some(valuePerFetus => valuePerFetus.value.active))
      .map(item => item[1].data?.map(
        (d) => {
          return {
            ...d,
            ...d.value,
            id: d.value?.item_id,
            placeholder: placeholders[item[0]],
            assoc_checklist_item_checklist_item_group: {
              checklist_item_group_id: d.value?.group_id,
              checklist_item_id: d.value?.item_id,
            },
            visible: d.visible === null ? true : d.visible,
            originalSlug: item[0],
            slug: item[0].replace("checklist.item.", "")
          }
        })
      )
  }

  /* Not used --- Maybe worth it to use it in the future
  fetuses(examinationFetuses, orderPlaceholder) {
    if (this.apiVersion == '2.0') {
      return [{ id: null }].concat([...examinationFetuses].sort((a, b) => {
        const indexA = orderPlaceholder.find((el) => el.examination_fetus_id == a.fetus_id).value.value
        const indexB = orderPlaceholder.find((el) => el.examination_fetus_id == b.fetus_id).value.value
        return indexA - indexB
      }))
    }

    return [{ id: null }].concat([...examinationFetuses])
  }
  */

  sortedItems() {
    return this.normalizedItems
      .sort(this.#sortItems.bind(this));
  }

  #sortItems(a, b) {
    if (a.order && b.order) return a.order - b.order;
    return a.label?.[this.currentLanguage] < b.label?.[this.currentLanguage] ? -1 : 1
  }

  #normalizeItems() {
    //if (this.apiVersion == '2.0') {
    return this.items
      .map(item => item.find((i) => i.examination_fetus_id == this.currentFetusId))
      .filter((item) => item)
      .map(item => ({
        ...item,
        fetus: this.currentFetus,
        children: [],
      }))
    //}

    return this.items.map(item => ({
      ...item[this.currentFetus],
      fetus: this.currentFetus,
      children: [],
    }))
  }
}
