import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { ExaminationContext } from "../../../../context-providers/Examination";

import ResourceApi from "../../../../services/resource";
import { isNullOrUndefined } from "../../../../utils";

import Table from "../Table";
import Column from "../Empty";
import Value from "../Value";

import Button from "../../../../atoms/Button/Button";
import Icon from "../../../../atoms/Icon/Icon";

import PlaceholderLoader from '../../PlaceholderLoader';

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function getQuestionAdditionalStatement({ trimester }) {
  /* This is a quick dirty fix for taking in count the GA in IMO search
   * We must improve this in the future with a more specified search tags
   */
  switch (trimester) {
    case "T1":
      return "first trimester"
    case "T2":
      return "second trimester"
    case "T3":
      return "third trimester"
    default:
      return ""
  }
}


const CodingSection = withTranslation()(({
  t: __,
  coding_system,
  examinationAttribute,
  source,
  codes = [],
  showErrors,
  mandatory,
  addCode,
  update,
  suggestions = [],
  examinationContext,
  user,
  canEdit,
  placeholders,
  ...otherProps
}) => {

  const placeholder = {
    value: Object.fromEntries(codes?.filter(code => code).map((code, order) => [code.id || code.code + '_' + code.description, {
      value: true,
      code: code.code,
      label: code.description,
      description: code.description,
      order,
      source: code.origin,
      originalItem: code,
    }])),
  }

  /* Take returns of the Table component and makes it a code understandable data
  */
  const itemsToCodes = (items) => {
    return Object.values(items?.value || {})
      .sort((a, b) => a.order - b.order)
      .flatMap((item, itemIndex) => {
        if (!isNullOrUndefined(item.originalItem)) {
          return [item.originalItem]
        }
        if (!isNullOrUndefined(item.codes_object)) {
          return item.codes_object.map((code) => ({ ...code, id: uuidv4() }))
        }
      })
  }

  const onEndEditing = (data, items) => {
    update({ [examinationAttribute]: itemsToCodes(items) })
  }

  const placeholderId = `coding.${coding_system}`;

  return (
    <div className="exam-report-coding-section">
      <div className="exam-report-coding-section_title">
        <h2>{__("reportCoding.system." + coding_system)}</h2>
      </div>
      <div className="exam-report-coding-section_table">
        <Table
          {...otherProps}
          props={{ data: placeholderId, sortable: "true", source, examinationContext, required: `${mandatory}`, "empty-message": __("reportCoding.nothingToShow"), "search-placeholder": __("reportCoding.searchPlaceholder." + coding_system) }}
          canEdit={canEdit}
          onEndEditing={onEndEditing}
          user={user}
          placeholder={placeholder}
          placeholders={{...placeholders, [placeholderId]: placeholder}}
          examinationContext={examinationContext}
          showErrors={showErrors}
        >
          {[
            <Column key="col1" props={{ label: __("reportCoding.table.code"), width: "10%" }} type="column">
              {[
                <Value key="value1" props={{ data: placeholderId, attribute: "code" }} />,
              ]}
            </Column>,
            <Column key="col2" props={{ label: __("reportCoding.table.description") }} type="column">
              {[
                <Value key="value2" props={{ data: placeholderId, attribute: "label" }} />,
              ]}
            </Column>,
          ]}
        </Table>
        <SuggestionsSection
          suggestions={suggestions}
          addCode={addCode}
          examinationAttribute={examinationAttribute}
          coding_system={coding_system}
        />
      </div>
    </div>
  )
});

const SuggestionsSection = withTranslation()(({
  t: __,
  suggestions,
  addCode,
  examinationAttribute,
  coding_system
}) => {
  return (
    !!suggestions?.length && (
      <div className="exam-report-coding-section_suggestions">
        <h6 className="section-title">{__("reportCoding.suggestionSentence." + coding_system)}</h6>
        {suggestions?.map((suggestion, i) => (
          <div
            key={i}
            onClick={() => addCode(examinationAttribute, suggestion)}
            className="exam-report-coding-section_suggestion"
          >
            <Icon name="add" />
            <span className="code">{suggestion.code}</span>
            <span className="meta">
              <span className="description">{suggestion.description}</span>
              {!!suggestion.origin_value && suggestion.origin_value !== suggestion.description && (
                <span className="suggested">{suggestion.origin_value}</span>
              )}
            </span>
          </div>
        ))}
      </div>
    )
  );
});

const CodingBody = ({
  t: __,
  showErrors,
  examinationContext,
  user,
  canEdit,
  props,
  placeholders,
  hijackedRequirePlaceholders,
  ...otherProps
}) => {
  const { examination, updateExamination } = useContext(ExaminationContext);
  const [defaultProceduresCodes, setDefaultProceduresCodes] = useState([]);
  const [diagnosticSuggestions, setDiagnosticSuggestions] = useState([]);
  const [requiringPlacehodlers, setRequiringPlaceholders] = useState(false);

  const {
    "examination.finding": findings,
    "examination.indication": indications,
    //"ga.assigned.value": assigned_ga,
    "examination.method": methods
  } = placeholders;

  useEffect(() => {
    if (!methods) return;
    ResourceApi.getDefaultProceduresCodes(examination.id)
      .then((resp) => setDefaultProceduresCodes([{ codes: resp.data.data }]))
      .catch((e) => console.error(e))
  }, [examination.preset_id, JSON.stringify(methods?.value)])

  useEffect(() => {
    const indicationsSuggestions = Object.values({ ...(indications?.value || {}) })
      .filter(item => Array.isArray(item.codes_object))
      .map(({ codes_object, label }) => ({
        codes: (Array.isArray(codes_object) ? codes_object : Object.entries(codes_object || {}))?.map(({ code, description, origin_value }) => ({
          code,
          description,
          origin_value,
          origin: "indication"
        })),
        description: label
      }));

    const findingsSuggestions = Object.values({ ...(findings?.value || {}) })
      .filter(item => Array.isArray(item.codes_object))
      .map(({ codes_object, label }) => ({
        codes: codes_object.map(({ code, description, origin_value }) => ({
          code,
          description,
          origin_value,
          origin: "finding"
        })),
        description: label
      }));

    ResourceApi.suggestCode(
      "diagnostic",
      /* TODO This does not support the real suggestions
      dropdownToList(indications),
      dropdownToList(findings),
      */
      [],
      [],
      getQuestionAdditionalStatement(examination)
    )
      .then(({ data }) => setDiagnosticSuggestions(
        data.indications
          .concat(data.findings)
          .concat(findingsSuggestions)
          .concat(indicationsSuggestions)
      ))
      .catch((e) => console.error(e))
    /* TODO how do we show the user something wrong is going on with Sonio or IMO */
  }, [JSON.stringify({ indications: indications?.value, findings: findings?.value })])

  const update = async (attributes) => {
    if (!canEdit)
      return
    return await updateExamination({
      ...attributes,
      id: examination.id,
      status: (examination.status === "draft" || examination.status === "inprogress") ? "inprogress" : "completed"
    })
  }

  const filterDiagSuggestions = (suggestions, codes) => {
    const selectedCodes = codes.map(({ code }) => code);
    return Object.values(
      Object.groupBy(
        suggestions.flatMap(suggestion => suggestion.codes.map(c => ({ id: uuidv4(), ...c, origin_value: suggestion.description }))),
        ({ code }) => code
      ))
      // Only unique code for diagnostic codes
      .map(codes => {
        const origin_value = codes.filter(({ origin_value }) => origin_value).map(({ origin_value }) => origin_value).join(", ");
        return ({
          ...codes[0],
          origin_value
        })
      })
      // Remove the code if already in the dropdown
      .filter(({ code }) => !selectedCodes.includes(code));
  }

  const filterProceduresSuggestions = (suggestions, codes) => {
    const selectedCodes = Object.fromEntries(
      Object.values(
        Object.groupBy(codes, ({ code }) => code)
      )
        .map(codes => [codes[0].code, codes.length])
    );
    return Object.values(
      Object.groupBy(
        suggestions.flatMap(suggestion => suggestion.codes.map(c => ({ id: uuidv4(), ...c, origin_value: suggestion.description }))),
        ({ code }) => code
      ))
      // Remove code if already present in the table by the number of already selected codes
      // If rules suggest 3 76830 CPT and 1 76830 is already present in the table
      // We must only display 2 76830 CPT
      .flatMap((codes) => codes.slice(selectedCodes[codes[0].code] || 0));
  }

  const diagnoses_codes = examinationContext?.examination?.["diagnoses_codes"] || [];
  const procedures_codes = examinationContext?.examination?.["procedures_codes"] || [];
  const diagnosticSuggestionsFiltered = filterDiagSuggestions(diagnosticSuggestions, diagnoses_codes);
  const defaultProceduresCodesFiltered = filterProceduresSuggestions(defaultProceduresCodes, procedures_codes);

  const addAllSuggestedCodes = async () => {
    return await update({
      diagnoses_codes: [...examinationContext.examination?.diagnoses_codes, ...diagnosticSuggestionsFiltered],
      procedures_codes: [...examinationContext.examination?.procedures_codes, ...defaultProceduresCodesFiltered]
    })
  }

  const addCode = async (examinationAttribute, item) => {
    await update({ [examinationAttribute]: [...examinationContext.examination?.[examinationAttribute], item] });
  }

  const numberOfSuggestions = diagnosticSuggestionsFiltered.length + defaultProceduresCodesFiltered.length;


  return (
    <div className="exam-report-coding">
      <div className="exam-report-coding_heading">
        <h1>{__("reportCoding.title")}</h1>
        {!!numberOfSuggestions && (
          <Button onClick={addAllSuggestedCodes} label={__("reportCoding.addAllSuggestedCodes", { count: numberOfSuggestions })} icon="add" size="small" />
        )}
      </div>
      {examination && (
        <>
          <CodingSection
            coding_system="diagnostic"
            examinationAttribute="diagnoses_codes"
            source="ICD10"
            codes={examinationContext?.examination?.["diagnoses_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={diagnosticSuggestionsFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            placeholders={placeholders}
            {...otherProps}
            requirePlaceholders={hijackedRequirePlaceholders}
          />
          <CodingSection
            coding_system="procedure"
            examinationAttribute="procedures_codes"
            source="CPT"
            codes={examinationContext?.examination?.["procedures_codes"]}
            showErrors={showErrors}
            mandatory={examination?.preset?.is_coding_mandatory}
            suggestions={defaultProceduresCodesFiltered}
            examinationContext={examinationContext}
            user={user}
            addCode={addCode}
            update={update}
            canEdit={canEdit}
            placeholders={placeholders}
            {...otherProps}
            requirePlaceholders={hijackedRequirePlaceholders}
          />
        </>
      )}
    </div>
  )
}


const defaultRequiredPlaceholdersIds = [
  "examination.finding",
  "examination.indication",
  //"ga.assigned.value",
  "examination.method"
]
export default function Coding({
  props,
  placeholders,
  requirePlaceholders,
  ...otherProps
}) {
  const [requiredPlaceholders, setRequiredPlaceholders] = useState(defaultRequiredPlaceholdersIds);

  const hijackedRequirePlaceholders = (_props, ids) => {
    /* We don't need to check if ids are uniq as PlaceholderLoader take care of it */
    setRequiredPlaceholders((previouslyRequiredPlaceholders) => [...ids, ...previouslyRequiredPlaceholders])
  }

  return (
    <PlaceholderLoader
      Component={withTranslation()(CodingBody)}
      requirePlaceholders={requirePlaceholders}
      hijackedRequirePlaceholders={hijackedRequirePlaceholders}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      props={props}
      {...otherProps}
    />
  );
};
