import { renderToStaticMarkup } from "react-dom/server";

export default function ReportTemplateBlock({ children, props, reportMode }) {
  const {
    layout,
    width = null,
    align = null,
    color = null,
    background = null,
    size = "",
    outlined = null,
    "avoid-break": avoidBreak,
    "auto-hide": autoHide,
  } = props ?? {};

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };

  // TODO this is super ugly. We need to find a better way to check if a block is printable
  // Example of solution is to bring modularity like for measurement-curve and graphs components
  const notPrintable =
    reportMode === "print" &&
    autoHide &&
    !renderToStaticMarkup(children).includes("has-printable-value");

  const style = {
    minWidth: width ? `min(${width}, 100%)` : "auto",
    maxWidth: width ? `min(${width}, 100%)` : "100%",
  };

  if (align) style.textAlign = align;
  if (color && reportMode === "print") style.color = color;
  if (background && reportMode === "print") style.backgroundColor = background;

  if (notPrintable) {
    style.display = "none";
  }

  return (
    <div
      className={`block size-${sizes[size] || "default"} ${
        outlined ? "is-outlined" : ""
      } ${avoidBreak ? "avoid-break" : ""} ${
        notPrintable ? "not-printable" : ""
      } ${background ? "has-background" : ""}`}
      style={style}
      data-layout={layout}
    >
      {children}
    </div>
  );
}
