import { withTranslation } from "react-i18next";
import {
  getNiceGestionalAgeFromDays,
  computeEstimatedDeliveryDate,
} from "../../../../../services/examination";
import InlineInput from "../../../../../atoms/InlineEditing/InlineInput/InlineInput";
import Icon from "../../../../../atoms/Icon/Icon";
import LoaderInline from "../../../../../atoms/LoaderInline/LoaderInline";
import InlineDropdown from "../../../../../atoms/InlineEditing/InlineDropdown/InlineDropdown";
import NotVisibleOverlay from "../../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

const NONE = "none";

const GaItem = withTranslation()(
  ({
    t: __,
    data,
    placeholdersHelper,
    reportDataOptions,
    gaItemProps,
    reportMode,
    timezone,
    pregnancyLengthInDays,
  }) => {
    const {
      currentExamDate,
      methodsWithStandards,
      methodsDropdownOptions,
      updateRow,
      fetusDropdownOptions,
      removeRow,
      updatingAssignedGa,
      setUpdatingAssignedGa,
      showFetusDropDown,
      splitSlug,
      makeSlug,
      defaultFetusFromSlug,
      appPreferences,
      print,
      currentLanguage,
      datingMethod,
      canEdit,
    } = gaItemProps;

    const preferredDateFormat = appPreferences?.date_format;

    if (!data) return false;
    const { slug, fetus } = data;
    const { method, standard } = splitSlug(slug);

    const getDefaultLabel = () => {
      return method === NONE
        ? ""
        : __("report.gatable.defaultLabel", {
            method: datingMethod(slug)?.label?.[currentLanguage] || slug,
            standard:
              reportDataOptions?.dating_standards?.[standard]?.[
                currentLanguage
              ] || false,
          });
    };

    const getPlaceholderData = () => {
      const label = data.label || getDefaultLabel();
      const patientValue = datingMethod(slug)?.patient_value;
      const placeholder = placeholdersHelper.selectedValue(slug, data.fetus);
      if (!placeholder) {
        return { ...data, label, patientValue };
      } else {
        return {
          ...placeholder,
          ...data,
          label,
          patientValue,
        };
      }
    };

    const {
      is_assigned: isAssigned = false,
      value = false,
      date_obtained: dateObtained = undefined,
      visible = false,
      rowId,
      label,
      patientValue,
    } = getPlaceholderData();

    if (print && !value) return false;

    const onChange = (valueToUpdate) => (value) => {
      updateRow({ [valueToUpdate]: value }, rowId);
    };

    const onChangeMethod = (newMethod) => {
      const newStandard = methodsWithStandards[newMethod][0].value;
      const newSlug = makeSlug(newMethod, newStandard);
      const newFetus = defaultFetusFromSlug(newSlug, fetus);

      updateRow({ slug: newSlug, label: false, fetus: newFetus }, rowId);
    };

    const onChangeStandard = (newStandard) => {
      const newSlug = makeSlug(method, newStandard);
      const newFetus = defaultFetusFromSlug(newSlug, fetus);
      updateRow({ slug: newSlug, fetus: newFetus, label: false }, rowId);
    };

    const onClickAssigned = async () => {
      /* TODO reimplement the transition */
      setUpdatingAssignedGa(slug, data.fetus);
    };

    const showAsAssigned = () => isAssigned && !updatingAssignedGa;

    return (
      <div
        className={`ga-item ${visible ? "is-visible" : "not-visible"} ${
          showFetusDropDown ? "show-fetus" : ""
        }`}
      >
        {/* Fetus */}
        {showFetusDropDown && (
          <div>
            <span className="ga-item-fetus-number">
              <InlineDropdown
                value={fetus}
                options={
                  patientValue
                    ? fetusDropdownOptions.slice(0, 1)
                    : fetusDropdownOptions.slice(1)
                }
                printable={true}
                onChange={onChange("fetus")}
              />
            </span>
          </div>
        )}

        {/* Custom Label */}
        <div>
          <InlineInput
            value={label}
            format={"string"}
            printable={visible}
            onChange={onChange("label")}
            appPreferences={appPreferences}
            active={canEdit}
          />
        </div>

        {/* Scan Date */}
        <div className="ga-item-center">
          <InlineInput
            active={false}
            value={dateObtained}
            format="date"
            appPreferences={appPreferences}
          />
        </div>

        <>
          {/* Method Dropdown */}
          <div>
            {method === "none" && (
              <div className="ga-item-hint-balloon not-printable">
                {__("report.gatable.selectMethod")}
              </div>
            )}
            <InlineDropdown
              value={method}
              options={methodsDropdownOptions}
              printable={visible}
              onChange={onChangeMethod}
              active={canEdit}
            />
          </div>

          {/* Standard Dropdown  */}
          <div>
            {!print && (
              <InlineDropdown
                value={standard}
                options={methodsWithStandards[method]}
                printable={visible}
                onChange={onChangeStandard}
                active={canEdit}
              />
            )}
          </div>
        </>

        {/* Current GA */}
        <div className="ga-item-center">
          {value
            ? getNiceGestionalAgeFromDays(__, Math.floor(value))
            : methodsWithStandards[method]
            ? ""
            : __("report.gatable.unavailable")}
        </div>

        {/* EDD */}
        <div className="ga-item-center">
          {computeEstimatedDeliveryDate(
            value,
            currentExamDate,
            preferredDateFormat,
            timezone,
            pregnancyLengthInDays
          )}
        </div>

        {/* Assigned */}
        {!print && (
          <div
            className="ga-item-assign ga-item-center"
            onClick={onClickAssigned}
          >
            {updatingAssignedGa ? (
              <LoaderInline />
            ) : (
              <input type="radio" readOnly checked={showAsAssigned()} />
            )}
          </div>
        )}
        {/* Editing options */}
        {visible && reportMode === "edit" && (
          <div className="exam-report-editing-options">
            <div onClick={() => onChange("visible")(!visible)}>
              <Icon name={visible ? "eye" : "eye-off"} />
            </div>
            <div className="ga-item-remove" onClick={() => removeRow(rowId)}>
              <Icon name="trash" />
            </div>
          </div>
        )}
        {!visible && reportMode === "edit" && (
          <NotVisibleOverlay onClick={() => onChange("visible")(!visible)} />
        )}
      </div>
    );
  }
);

export default GaItem;
