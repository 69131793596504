import { useState } from "react";
import Icon from "../../../../atoms/Icon/Icon";
import InlineTextarea from "../../../../atoms/InlineEditing/InlineTextarea/InlineTextarea";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import { placeholderIdFromProps, propsToTag } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";
import { PlaceholdersHelper } from "../../placeholders";

function ReportTemplateTextBlockBody(fullProps) {
  const {
    props,
    children,
    showErrors,
    onEndEditing = () => {},
    showOptions: parentShowOptions = null,
    reportMode = "edit",
    placeholders,
    canEdit = false,
    highlight = false,
    BIContext: InitialBIContext,
  } = fullProps;
  const [editing, setEditing] = useState(false);
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const fieldId = placeholderIdFromProps(props);
  const selectedDatum = placeholdersHelper.selectedDatum(fieldId);
  const visible = highlight ? true : selectedDatum?.visible ?? true;

  const format = placeholdersHelper.format(fieldId);

  const BIContext = {
    ...InitialBIContext,
    component: "text-block",
    format: format,
  };

  const attribute = placeholdersHelper?.attribute(fieldId);
  if (format !== "string" && attribute === "value") {
    // TODO move it to the debug pannel
    console.error(
      `text-block is only allowed for strings, received ${format} for ${propsToTag(
        props
      )}`
    );
  }
  const value =
    placeholdersHelper.selectedValue(fieldId)?.[attribute] ??
    (typeof children === "string" ? children : "");
  const showOptions =
    parentShowOptions || placeholders[fieldId].showOptions !== false;

  const invalidValue = () => {
    return !value;
  };

  const getClassName = () => {
    return `
      textblock-wrapper exam-report-editing-field
      ${visible ? "is-visible" : "not-visible"}
      ${
        value?.trim()?.length > 0
          ? visible
            ? "has-printable-value"
            : ""
          : "not-printable"
      }
      ${
        props.required === "true" && invalidValue() && showErrors
          ? "required-error"
          : ""
      }
      ${props.label ? "is-block" : "is-inline"}
      ${props.data ? "is-editable" : ""}
      ${editing ? "is-editing" : ""}
    `;
  };

  const saveChange = (value) => {
    setTimeout(setEditing(false), 200);
    if (!props.data) return false;

    onEndEditing(
      fieldId,
      placeholdersHelper.editSelectedDataValue(
        fieldId,
        { [attribute]: value },
        null,
        "user"
      ),
      { BIContext }
    );
  };

  const changeVisibility = (visible) => {
    const { value: _, ...datum } = selectedDatum;
    datum.visible = visible;
    onEndEditing(fieldId, [datum], { BIContext });
  };

  const isRequired = () => {
    return props.required === "true" ? (
      <span className="required">*</span>
    ) : (
      false
    );
  };

  const extractFirstSentence = (content) => {
    let separator = "";
    let separatorIndex = 0;
    const contentStr = `${content}`;

    for (const s of [".\n", "\n", ". "]) {
      separator = s;
      separatorIndex = contentStr.indexOf(s);
      if (separatorIndex > 0) break;
    }

    if (separatorIndex <= 0) return contentStr;
    return contentStr.substring(0, separatorIndex + separator.length);
  };

  const printableVersion = () => {
    // this has been done to keep the first sentence attached with the previous title / label when printing on different pages
    const content = `${value ?? ""}`;
    const firstSentence = extractFirstSentence(content);
    const rest = content.substring(firstSentence.length).trim();
    return (
      <>
        {!!firstSentence && (
          <div
            className={getClassName()}
            dangerouslySetInnerHTML={{
              __html: firstSentence.replace("\n", "<br />"),
            }}
          />
        )}
        {!!rest && (
          <div
            className={getClassName()}
            dangerouslySetInnerHTML={{ __html: rest.replace("\n", "<br />") }}
          />
        )}
      </>
    );
  };

  const labelStyle = props["label-width"]
    ? { width: props["label-width"], minWidth: props["label-width"] }
    : {};

  return reportMode === "edit" ? (
    <div className={getClassName()}>
      {!!props.label && (
        <div className="label" style={labelStyle}>
          {props.label} {isRequired()}
        </div>
      )}
      <span className={`${highlight ? "highlight-field" : ""}`}>
        <div
          className="textblock-text exam-report-editing-value"
          data-editing={editing === "value"}
        >
          {props.required === "true" && <span className="required">*</span>}
          <InlineTextarea
            value={value}
            printable={visible}
            active={canEdit}
            onStartEditing={() => setEditing("value")}
            onChange={(value) => saveChange(value, 0)}
          />
          {!props.label && isRequired()}
        </div>
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ""}>
            <Icon name={highlight.icon} />
          </span>
        )}
        {!highlight &&
          visible &&
          canEdit &&
          reportMode === "edit" &&
          showOptions && (
            <div className="content-options exam-report-editing-options">
              <div onClick={() => changeVisibility(!visible)}>
                <Icon name={visible ? "eye" : "eye-off"} />
              </div>
            </div>
          )}
      </span>
      {!visible && reportMode === "edit" && (
        <NotVisibleOverlay onClick={() => changeVisibility(true)} />
      )}
    </div>
  ) : (
    printableVersion()
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateTextBlock({
  props,
  placeholders,
  ...otherProps
}) {
  const fieldId = placeholderIdFromProps(props);

  return (
    <PlaceholderLoader
      Component={ReportTemplateTextBlockBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, "fetus.order"]}
      props={props}
      {...otherProps}
    />
  );
}
