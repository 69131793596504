import { useEffect, useMemo, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";

/* Utils */
import { ChecklistItemStatus } from "../../../config";

/* Contexts */
import { useXMLTemplate } from "../../../context-providers/XMLTemplate";

/* Components */
import ChecklistItemsHelper from "../../../components/XMLDocument/ChecklistItemsHelper";
import { PlaceholdersHelper } from "../../../components/XMLDocument/placeholders";
import PlaceholderLoader from "../../../components/XMLDocument/PlaceholderLoader";

/* Atoms */
import ButtonGroup from "../../../atoms/ButtonGroup/ButtonGroup";
import ButtonBack from "../../../atoms/ButtonBack/ButtonBack";
import Tabs from "../../../atoms/Tabs/Tabs";
import SelectInput from "../../../atoms/SelectInput/SelectInput";
import Button from "../../../atoms/Button/Button";
import NotVisibleOverlay from "../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import TextInput from "../../../atoms/TextInput/TextInput";
import InlineInput from "../../../atoms/InlineEditing/InlineInput/InlineInput";
import Icon from "../../../atoms/Icon/Icon";

/* CSS */
import "../SubmitExamDialog.css";
import "./EditChecklistItems.css";


const EditChecklistItems = ({
  t: __,
  i18n: { language: currentLanguage },
  fetus = 1,
  componentUID,
  groupCommentsSlugPrefix,
  getGroupComment,
  editGroupComment,
  items = [],
  initialGroup = false,
  groups,
  close = () => { } },
) => {
  const {
    onEndEditChecklistStatus,
    onEndEditing,
    onEndEditingChecklistData,
    fetuses,
    apiVersion,
  } = useXMLTemplate();

  const [currentGroup, setCurrentGroup] = useState(groups.find(group => group.slug === initialGroup));
  const [currentFetus, setCurrentFetus] = useState(fetus);
  const [searchKey, setSearchKey] = useState("");
  const [statusChangeQueue, setStatusChangeQueue] = useState({});
  const [showNotes, setShowNotes] = useState(false);

  const currentFetusId = fetuses[currentFetus].id;
  /* TODO make this useMemo more optimized by creating a UID for items list */
  const checklistItemsHelper = useMemo(() => new ChecklistItemsHelper({ apiVersion, currentLanguage, items, currentFetus, currentFetusId }), [apiVersion, currentLanguage, items, currentFetus, currentFetusId]);
  const itemsByFetus = useMemo(() => checklistItemsHelper.sortedItems(), [checklistItemsHelper]);

  const fetusOptions = useMemo(() => fetuses.map((fetus, fetusNumber) => ({
    label: fetusNumber === 0 ? __("checklistItems.mother") : __("checklistItems.fetus", { name: fetus.label }),
    value: fetusNumber,
  })).filter(f => f.value !== 0), [fetuses]);


  let itemsWithChildren = structuredClone(itemsByFetus);
  itemsByFetus.forEach(item => {
    item.parents?.forEach(parent => {
      const index = itemsByFetus.findIndex(i => i.id === parent.id);
      if (index > -1) {
        itemsWithChildren[index].children.push(item);
      }
    });
  });
  itemsWithChildren = itemsWithChildren.filter(item => !item.parent && item.label?.[currentLanguage]?.toLowerCase().includes(searchKey.toLowerCase()));

  const itemsByGroup = itemsWithChildren.filter(item => currentGroup?.id === 0 || currentGroup?.id === item.assoc_checklist_item_checklist_item_group?.checklist_item_group_id);

  const groupsByFetus = useMemo(() => {
    const allGroups = groups.filter(group => (currentFetus > 0 && group.type === 'fetal_anatomy_zone') || (currentFetus === 0 && group.type !== 'fetal_anatomy_zone'))
      .map(group => ({
        ...group,
        counter: itemsByFetus.filter(item => group.id === item.assoc_checklist_item_checklist_item_group?.checklist_item_group_id && !["not_applicable", "usual"].includes(item.status)).length || null,
        items_with_status: itemsByFetus.filter(item => group.id === item.assoc_checklist_item_checklist_item_group?.checklist_item_group_id && item.status !== "not_applicable").length
      }));
    return [
      {
        name: __("checklistItems.all"),
        id: 0,
        slug: "all",
        counter: allGroups.reduce((count, group) => count += group.counter, 0),
        items_with_status: allGroups.reduce((count, group) => count += group.items_with_status, 0),
      },
      ...allGroups,
    ];
  }, [groups, currentFetus, itemsByFetus]);

  useEffect(() => {
    setCurrentGroup(currentGroup => groupsByFetus.find(group => group.slug === currentGroup?.slug) || groupsByFetus.find(group => group.id));
  }, [currentFetus]);

  const changeStatus = (grouped) => {
    const examination_fetus_id = fetuses[currentFetus].id;
    const operations = Object.entries(grouped)
      .map(([status, slugs]) => {
        return slugs.map((slug) => ({ slug, operation: "status", value: status, fetus: currentFetus, examination_fetus_id }));
      })
      .flat();
    return onEndEditChecklistStatus(operations);
  }

  const changeQueueStatus = async () => {
    const grouped = Object.entries(statusChangeQueue).reduce((grouped, [slug, newStatus]) => ({
      ...grouped,
      [newStatus]: [
        ...(grouped[newStatus] || []),
        slug
      ],
    }), {});
    return changeStatus(grouped).finally(() => {
      setStatusChangeQueue({});
    });
  };

  requiredPlaceholders = Object.keys(ChecklistItemStatus).map(status => {
    return groups.concat([{ slug: 'all' }]).map((group) => {
      return `${groupCommentsSlugPrefix}${group.slug}.${status}`
    });
  }).flat();

  /* quick selection with one click */
  const [recordingMouseHovering, setRecordingMouveHovering] = useState(false);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMoveHandler);
    window.addEventListener('mouseup', onMouseUpHandler);
    return () => {
      window.removeEventListener('mousemove', onMouseMoveHandler);
      window.removeEventListener('mouseup', onMouseUpHandler);
    }
  }, [recordingMouseHovering, statusChangeQueue]);

  let lastHoveredButton = null;
  const onMouseDownHandler = (e) => {
    setRecordingMouveHovering(true);
    lastHoveredButton = null;
  }
  const onMouseUpHandler = (e) => {
    setRecordingMouveHovering(false);
    changeQueueStatus();
  }

  const onMouseMoveHandler = (e) => {
    if (!recordingMouseHovering) return;

    const button = e.target.closest('.button-group-options .option');
    const line = button?.closest('.edit-checklist_group_item');
    if (button && lastHoveredButton !== button) {
      setStatusChangeQueue(queue => ({
        ...queue,
        [line?.dataset.slug]: button?.dataset.value,
      }));
    }
    lastHoveredButton = button;
  }

  return (
    <>
      <div className="modal-background" onClick={close} />
      <div className="exam-report-dialog edit-checklist-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          <ButtonBack onClick={close} />
          <div className="exam-report-dialog-header_title">
            <h2>{__("examinationReview.checklistItems")}</h2>
            {fetusOptions.length > 1 && (
              <SelectInput
                theme="grey"
                options={fetusOptions}
                onChange={fetusNumber => setCurrentFetus(fetusNumber)}
                value={currentFetus}
              />
            )}
            <Tabs
              options={groupsByFetus.map(group => ({
                value: group.id,
                label: group.name,
                counter: group.counter,
                irrelevant: !group.items_with_status,
              }))}
              value={currentGroup?.id}
              onChange={(value) => setCurrentGroup(groupsByFetus.find(g => g.id === value))}
            />
            <div className="edit-checklist_batch-actions">
              <SelectInput
                placeholder={{ icon: "flash", label: "" }}
                theme="grey"
                options={[
                  {
                    value: "usual",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.usual`) }),
                  },
                  {
                    value: "unusual",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.unusual`) }),
                  },
                  {
                    value: "non_visualized",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.non_visualized`) }),
                  },
                  {
                    value: "suboptimal",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.suboptimal`) }),
                  },
                  {
                    value: "previously_documented",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.previously_documented`) }),
                  },
                  {
                    value: "not_applicable",
                    label: __("report.markAllAs", { status: __(`checklistItem.status.not_applicable`) }),
                  },
                ]}
                onChange={(value) => changeStatus({ [value]: itemsByFetus.filter(item => item.status !== "not_applicable").map(item => item.slug) })}
                showSearchBar={false}
                showMostUsed={false}
                showRecent={false}
              />
            </div>
          </div>
          <ButtonBack icon="close" onClick={close} />
        </div>

        <div className="exam-report-dialog-body column-direction">
          <div className="edit-checklist-inner">
            <div className="edit-checklist_group" key={currentGroup?.id}>
              <div className="edit-checklist_group_item header">
                <div>
                  <TextInput icon="search" fullwidth="true" value={searchKey} onChange={(value) => setSearchKey(value)} />
                </div>
                <div className="quick_actions">
                  <label>
                    <Icon name="flash" />
                    {__("report.markGroupAs", { group: searchKey ? "" : currentGroup?.name })}
                  </label>
                  <ButtonGroup
                    size="compact"
                    variant="outline"
                    disabled={!itemsByGroup.some(item => item.status !== "not_applicable")}
                    options={[
                      {
                        value: "usual",
                        label: __(`checklistItem.status.usual.short`),
                      },
                      {
                        value: "unusual",
                        label: __(`checklistItem.status.unusual.short`),
                      },
                      {
                        value: "non_visualized",
                        label: __(`checklistItem.status.non_visualized.short`),
                      },
                      {
                        value: "suboptimal",
                        label: __(`checklistItem.status.suboptimal.short`),
                      },
                      {
                        value: "previously_documented",
                        label: __(`checklistItem.status.previously_documented.short`),
                      },
                      {
                        value: "not_applicable",
                        label: __(`checklistItem.status.not_applicable.short`),
                      },
                    ]}
                    onChange={(value) => changeStatus({ [value]: itemsByGroup.filter(item => item.status !== "not_applicable").map(item => item.slug) })}
                    showSearchBar={false}
                    showMostUsed={false}
                    showRecent={false}
                  />
                </div>
                <div></div>
              </div>
              {!itemsByGroup.length ? (
                <div className="no-items">
                  {__("checklistItems.noItems")}
                  {!!searchKey && (<a onClick={() => setSearchKey("")}>{__("checklistItems.noItems.search", { searchKey })}</a>)}
                </div>
              ) : (
                itemsByGroup.map(item => (
                  <EditChecklistItemsItemLine
                    key={item.id}
                    item={{
                      ...item,
                      status: statusChangeQueue[item.slug] || item.status,
                    }}
                    currentFetus={currentFetus}
                    changeStatus={changeStatus}
                    onEndEditing={onEndEditing}
                    onEndEditingChecklistData={onEndEditingChecklistData}
                    onMouseDownHandler={onMouseDownHandler}
                  />
                ))
              )}
            </div>
          </div>
          {!!componentUID && (
            <div className="exam-report-dialog-comment column-direction">
              <h2 className="section-title" onClick={() => setShowNotes(show => !show)}>
                {__("checklistItems.notes")}
                <Icon name={showNotes ? "down" : "up"} />
              </h2>
              {showNotes && Object.keys(ChecklistItemStatus).map((status => (
                <div className="edit-checklist_note" key={status}>
                  <label>{__(`checklistItem.status.${status}`)}</label>
                  <TextInput
                    fullwidth="true"
                    onBlur={(value) => editGroupComment(currentGroup, status, currentFetus, value)}
                    value={getGroupComment(currentGroup, status, currentFetus)}
                  />
                </div>
              )))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};


const EditChecklistItemsItemLine = withTranslation()(({ t: __, item, currentFetus, changeStatus, onEndEditing, onEndEditingChecklistData, onMouseDownHandler }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const toggleVisibility = () => {
    /* V2 vs V1 compatibility */
    if (typeof onEndEditingChecklistData === "function") {
      onEndEditingChecklistData(item, { visible: !item.visible }, { fetus: currentFetus });
    } else {
      let fetusUpdates = [];
      fetusUpdates[currentFetus] = { visible: !item.visible };
      onEndEditing(`checklist.item.${item.slug}`, fetusUpdates);
    }
  };

  const editComment = (newValue) => {
    /* V2 vs V1 compatibility */
    if (typeof onEndEditingChecklistData === "function") {
      onEndEditingChecklistData(item, { comment: newValue }, { fetus: currentFetus });
    } else {
      let fetusUpdates = [];
      fetusUpdates[currentFetus] = { comment: newValue };
      onEndEditing(`checklist.item.${item.slug}`, fetusUpdates);
    }
  };

  return <div className="edit-checklist_group_item" data-slug={item.slug} data-status={item.status} key={item.id}>
    {/* <div className="edit-checklist_group_item_expand"><Icon name={item.children.length ? "right" : "empty"} /></div> */}
    <div className="edit-checklist_group_item_name">
      <span>{item.label[currentLanguage]}</span>
      <InlineInput value={item.comment || ""} onChange={editComment} />
    </div>
    <div className="edit-checklist_group_item_button" onMouseDown={onMouseDownHandler}>
      <ButtonGroup
        options={Object.values(ChecklistItemStatus).map(option => ({
          value: option.id,
          label: __(`checklistItem.status.${option.id}.short`)
        }))}
        value={item.status}
        size="compact"
        theme="grey"
        color={item.status === "unusual" ? "ocra" : ""}
        onChange={status => changeStatus({ [status]: [item.slug] })}
      />
    </div>
    <div className="edit-checklist_group_item_comment">
      <Button
        icon={item.visible ? "eye" : "eye-off"}
        size="input"
        color="grey"
        onClick={toggleVisibility}
      />
    </div>
    {!item.visible && <NotVisibleOverlay onClick={toggleVisibility} />}
  </div>
});

const EditChecklistItemsWrapper = (props) => {
  const xmlTemplate = useXMLTemplate();
  const {
    apiVersion,
    // V1
    onEndEditing,
    getPlaceholderWithProps,
    // V2
    reportDataOptions,
    placeholders,
    requirePlaceholders,
    BIContext: initialBIContext,
  } = xmlTemplate;

  const { componentUID } = props;
  const groups = reportDataOptions.checklist_item_groups || [];
  const groupCommentsSlugPrefix = `checklist.${componentUID === "fetal_anatomy" ? "" : componentUID + "." // for backward compatibility we keep fetal_anatomy empty
    }group.`;

  if (apiVersion === '1.0' || apiVersion === '1.1') {

    const getGroupComment = useCallback((currentGroup, status, currentFetus) => {
      return getPlaceholderWithProps({ data: `${groupCommentsSlugPrefix}${currentGroup.slug}.${status}` })?.[currentFetus]?.comment
    }, [getPlaceholderWithProps, groupCommentsSlugPrefix]);

    const editGroupComment = async (currentGroup, status, currentFetus, newValue) => {
      const fetusUpdates = [];
      fetusUpdates[currentFetus] = { comment: newValue };
      return onEndEditing(`${groupCommentsSlugPrefix}${currentGroup.slug}.${status}`, fetusUpdates);
    };

    return <EditChecklistItems
      {...props}
      editGroupComment={editGroupComment}
      getGroupComment={getGroupComment}
      groups={groups}
      groupCommentsSlugPrefix={groupCommentsSlugPrefix}
    />;
  } else if (apiVersion === '2.0') {

    const requiredPlaceholders = Object.keys(ChecklistItemStatus).map(status => {
      return groups.concat([{ slug: 'all' }]).map((group) => {
        return `${groupCommentsSlugPrefix}${group.slug}.${status}`
      });
    }).flat();

    const localRequirePlaceholders = useCallback((_, ids) => {
      return requirePlaceholders(ids);
    }, [requirePlaceholders]);

    const placeholdersHelper = new PlaceholdersHelper(xmlTemplate);

    const getGroupComment = (currentGroup, status, currentFetus) => {
      const fieldId = `${groupCommentsSlugPrefix}${currentGroup.slug}.${status}`;
      return placeholdersHelper.selectedDatum(fieldId, currentFetus)?.comment;
    }

    const editGroupComment = async (currentGroup, status, currentFetus, comment) => {
      const fieldId = `${groupCommentsSlugPrefix}${currentGroup.slug}.${status}`;

      const { value: _, ...datum } = placeholdersHelper.selectedDatum(fieldId, currentFetus);
      datum.comment = comment;
      datum.source = "user";
      return onEndEditing(fieldId, [datum], { BIContext: {...initialBIContext, page: "checklist-items"} });
    };

    return <PlaceholderLoader
      Component={EditChecklistItems}
      groups={groups}
      groupCommentsSlugPrefix={groupCommentsSlugPrefix}
      getGroupComment={getGroupComment}
      editGroupComment={editGroupComment}
      placeholders={placeholders}
      props={{ data: 'checklist-item-ui' }}
      requiredPlaceholders={requiredPlaceholders}
      requirePlaceholders={localRequirePlaceholders}
      reportDataOptions={reportDataOptions}
      apiVersion={apiVersion}
      {...props}
    />
  }
}

export default withTranslation()(EditChecklistItemsWrapper);
