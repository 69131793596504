/* React */
import XMLToReact from "xml-to-react";
import { useState, useEffect, cloneElement } from "react";

/* Components */
import ReportTemplateBlock from "./template/Block";
import ReportTemplateChecklist from "./template/Checklist";
import ReportTemplateContent from "./template/Content";
import ReportTemplateDocument from "./template/Document";
import ReportTemplateHeader from "./template/Header";
import ReportTemplateHeading from "./template/Heading";
import ReportTemplateMeasurementCurve from "./template/MeasurementCurve";
import ReportTemplateMeasurementCurveLegend from "./template/MeasurementCurveLegend";
import ReportTemplateGaTable from "./template/GaTable";
import ReportTemplateBiometryTable from "./template/BiometryTable";
import ReportTemplateBiometryItem from "./template/BiometryItem";
import ReportTemplatePageBreak from "./template/PageBreak";
import ReportTemplateSpacer from "./template/Spacer";
import ReportTemplatePageFooter from "./template/PageFooter";
import ReportTemplatePageHeader from "./template/PageHeader";
import ReportTemplateLabel from "./template/Label";
import ReportTemplateLogo from "./template/Logo";
import ReportTemplateTextBlock from "./template/TextBlock";
import ReportTemplateMedicalHistory from "./template/MedicalHistory";
import ReportTemplateValue from "./template/Value";
import ReportTemplateCondition from "./template/Condition";
import ReportTemplateIf from "./template/If";
import ReportTemplateElse from "./template/Else";
import ReportTemplateImage from "./template/Image";
import ReportTemplateImages from "./template/Images";
import ReportTemplatePrivate from "./template/Private";
import ReportTemplateDropdown from "./template/Dropdown";
import ReportTemplateOption from "./template/Option";
import ReportTemplateFetus from "./template/Fetus";
import ReportTemplateTable from "./template/Table";
import ReportTemplateGraphs from "./template/Graphs";
import ReportTemplateFetalGrowthTable from "./template/FetalGrowthTable";
import ReportTemplateEmpty from "./template/Empty";
import ReportTemplateSearch from "./template/Search";
import IdentifierBiometry from "./template/IdentifierBiometry";

const applyProps = (tree, templateTypeToProps, everyOneProps) => {
  if (Array.isArray(tree))
    return tree.map((subTree) =>
      applyProps(subTree, templateTypeToProps, everyOneProps)
    );
  if (tree === null) return null;
  if (tree === undefined) return undefined;
  if (tree.$$typeof) {
    if (!templateTypeToProps[tree.props.type]) {
      console.warn("Unknown report element", tree.props.type);
      return tree;
    }
    const { children: treeChildren, ...treeProps } = tree.props;
    const props = {
      ...templateTypeToProps[tree.props.type](treeProps),
      ...everyOneProps,
    };
    const children = applyProps(
      treeChildren,
      templateTypeToProps,
      everyOneProps
    );
    if (Array.isArray(children)) return cloneElement(tree, props, ...children);
    if (children === null || children === undefined)
      return cloneElement(tree, props);
    return cloneElement(tree, props, children);
  }
  if (typeof tree === "string") return tree;
  console.error("Unexpect tree element", tree);
  return tree;
};

const applyType = (tree, templateTagToType) => {
  if (Array.isArray(tree))
    return tree.map((subTree) => applyType(subTree, templateTagToType));
  if (tree === null) return null;
  if (tree === undefined) return undefined;
  if (tree.$$typeof) {
    const { children: treeChildren } = tree.props;
    const children = applyType(treeChildren, templateTagToType);
    if (!templateTagToType[tree.type]) {
      console.warn("Unkown report element", tree.type);
      return { ...tree, props: { ...tree.props, children, type: tree.type } };
    } else {
      return {
        ...tree,
        type: templateTagToType[tree.type],
        props: { ...tree.props, children, type: tree.type },
      };
    }
  }
  if (typeof tree === "string") return tree;
  console.error("Unexpect tree element", tree);
  return tree;
};

const XMLDocument = ({
  page = "report",
  showErrors = false,
  submitClicked = false,
  setEditChecklistDialogIsOpen = () => {},
  reportMode,
  templateBlueprint,
  setManageMeasurementsIsOpen = () => {},
  base64Images = [],
  setBase64Images = () => {},
  requiredAndInvalid = null,
  setRequiredAndInvalid = () => {},
  /* contexts passed by custom hook */
  examinationContext,
  measurementsContext,
  siteFlowsConnectors,
  user,
  isFeatureFlagEnabled,
  appContext,
  BIContext,
  getPlaceholderWithProps,
  getHighligthedWithProps,
  getCarryForwardWithProps,
  customPlaceholders,
  setCustomPlaceholders,
  onEndEditing,
  onEndEditingChecklist,
  onEndEditingDynamicDropdown,
  onEndEditingDating,

  componentChecklistAssoc,
  updateComponentChecklistAssoc,

  loadDynamicDropdownFullTree,

  startEditingField,
  startEditing,
  editingFieldId,

  checkCondition,
  frozenHTML,

  /* Deprecated */
  placeholders,
  setPlaceholders,
  setAssignedGa,
  revertAssignedGa,
  updateEpisode,
  reportData,
  updateAutogeneratedChecklistComments,
  apiVersion,
}) => {
  /* TODO change fetusNumber and see why it has been defined */
  const fetusNumber = 1;

  const everyOneProps = {
    reportMode,
    apiVersion,
  };

  const commonProps = {
    user,
    page,
    reportData,
    checkCondition,
    getPlaceholderWithProps,
    appPreferences: appContext?.preferences,
    examinationContext,
    canEdit: examinationContext.canEdit,
    requiredAndInvalid,
    setRequiredAndInvalid,
    showErrors,
    submitClicked,
    onEndEditing,
    fetus: fetusNumber,
    measurementsContext,
    customPlaceholders,
    setCustomPlaceholders,
    BIContext: { ...BIContext, page: page },
  };

  /* TODO move this directly inside Coding section */
  const getCodingPlaceholders = (codingFlowsConnector) => {
    let placeholder = {};
    if (codingFlowsConnector) {
      placeholder = {
        findings: getPlaceholderWithProps({ data: "examination.finding" }),
        indications: getPlaceholderWithProps({
          data: "examination.indication",
        }),
        assigned_ga: getPlaceholderWithProps({ data: "ga.assigned.value" })
          ?.value,
        methods: getPlaceholderWithProps({ data: "examination.method" }),
      };
    }
    return placeholder;
  };

  const [plainTemplateReactTree, setTemplateReactTree] = useState(null);

  const supportedTags = [
    "snippet",
    "section",
    "block",
    "checklist",
    "column",
    "content",
    "labelled-content",
    "document",
    "dropdown",
    "fetus",
    "option",
    "condition",
    "if",
    "else",
    "image",
    "images",
    "label",
    "logo",
    "graphs",
    "group",
    "measurement-curve",
    "measurement-curve-legend",
    "ga-table",
    "biometry-table",
    "biometry-item",
    "fetal-growth-table",
    "header",
    "heading",
    "page-break",
    "private",
    "search",
    "spacer",
    "table",
    "textblock",
    "medical-history",
    "value",
    "page-footer",
    "page-header",
    "identifier-biometry",
  ];

  const templateTagToType = {
    snippet: ReportTemplateEmpty,
    section: ReportTemplateEmpty,
    block: ReportTemplateBlock,
    checklist: ReportTemplateChecklist,
    column: ReportTemplateEmpty,
    content: ReportTemplateContent,
    "labelled-content": ReportTemplateContent,
    document: ReportTemplateDocument,
    dropdown: ReportTemplateDropdown,
    fetus: ReportTemplateFetus,
    option: ReportTemplateOption,
    condition: ReportTemplateCondition,
    if: ReportTemplateIf,
    else: ReportTemplateElse,
    image: ReportTemplateImage,
    images: ReportTemplateImages,
    label: ReportTemplateLabel,
    logo: ReportTemplateLogo,
    graphs: ReportTemplateGraphs,
    group: ReportTemplateEmpty,
    "measurement-curve": ReportTemplateMeasurementCurve,
    "measurement-curve-legend": ReportTemplateMeasurementCurveLegend,
    "ga-table": ReportTemplateGaTable,
    "biometry-table": ReportTemplateBiometryTable,
    "biometry-item": ReportTemplateBiometryItem,
    "fetal-growth-table": ReportTemplateFetalGrowthTable,
    header: ReportTemplateHeader,
    heading: ReportTemplateHeading,
    "page-break": ReportTemplatePageBreak,
    private: ReportTemplatePrivate,
    search: ReportTemplateSearch,
    spacer: ReportTemplateSpacer,
    table: ReportTemplateTable,
    textblock: ReportTemplateTextBlock,
    "medical-history": ReportTemplateMedicalHistory,
    value: ReportTemplateValue,
    "page-footer": ReportTemplatePageFooter,
    "page-header": ReportTemplatePageHeader,
    "identifier-biometry": IdentifierBiometry,
  };

  const checklistItemsToDisplay = Object.entries(placeholders)
    .filter(
      ([key, value]) =>
        key.startsWith("checklist.item.") &&
        value.some((valuePerFetus) => valuePerFetus.active)
    )
    .map((item) => item[1]);

  const checklistProps = {
    placeholders,
    groups: reportData.checklist_item_groups,
    checklistItems: checklistItemsToDisplay,
    getPlaceholderWithProps,
    setEditChecklistDialogIsOpen,
    onEndEditingChecklist,
    componentChecklistAssoc,
    updateComponentChecklistAssoc,
    updateAutogeneratedChecklistComments,
  };

  const dropdownProps = {
    loadDynamicDropdownFullTree,
    onEndEditingDynamicDropdown,
  };

  const codingProps = siteFlowsConnectors?.coding
    ? {
        placeholder: getCodingPlaceholders(siteFlowsConnectors?.coding),
        isFeatureFlagEnabled,
        ...commonProps,
        ...everyOneProps,
      }
    : null;

  const templateTypeToProps = {
    snippet: (props) => ({ props, type: "snippet", ...commonProps }),
    section: (props) => ({ props, type: "section", ...commonProps }),
    block: (props) => ({ props, ...commonProps }),
    checklist: (props) => ({ props, ...checklistProps, ...commonProps }),
    column: (props) => ({
      props,
      type: "column",
      showOptions: false,
      ...commonProps,
    }),
    content: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props, "content"),
      highlight: getHighligthedWithProps(props),
      ...commonProps,
    }),
    "labelled-content": (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props, "content"),
      ...commonProps,
    }),
    document: (props) => ({ props, startEditing, frozenHTML, codingProps, ...commonProps }),
    dropdown: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props),
      highlight: getHighligthedWithProps(props),
      ...dropdownProps,
      ...commonProps,
    }),
    fetus: (props) => ({ props, ...commonProps }),
    group: (props) => ({ props }),
    option: (props) => ({ props, type: "option", ...commonProps }),
    condition: (props) => ({ props, getPlaceholderWithProps, ...commonProps }),
    if: (props) => ({
      props,
      getPlaceholderWithProps,
      type: "if",
      ...commonProps,
    }),
    else: (props) => ({ props, type: "else", ...commonProps }),
    image: (props) => ({ props }),
    images: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props),
      base64Images,
      setBase64Images,
    }),
    label: (props) => ({ props }),
    logo: (props) => ({ props, url: placeholders["logo.url"] }),
    graphs: (props) => ({ props, ...commonProps }),
    "measurement-curve": (props) => ({
      props,
      type: "measurement-curve",
      placeholder: placeholders[`measurement.${props.data}`],
      placeholders,
      editingFieldId,
      startEditingField,
      ...commonProps,
    }),
    "measurement-curve-legend": (props) => ({
      props,
      type: "measurement-curve-legend",
      ...commonProps,
    }),
    "ga-table": (props) => ({
      props,
      placeholders,
      setPlaceholders,
      currentExamDate: reportData?.examination_data?.examination_date,
      onEndEditingDating,
      setAssignedGa,
      revertAssignedGa,
      updateEpisode,
      ...commonProps,
    }),
    "biometry-table": (props) => ({ props, ...commonProps }),
    "biometry-item": (props) => ({
      props,
      reportData,
      placeholder: placeholders[`measurement.${props.data}`],
      placeholders,
      editingFieldId,
      startEditingField,
      setManageMeasurementsIsOpen,
      ...commonProps,
    }),
    "fetal-growth-table": (props) => ({ props, placeholders, ...commonProps }),
    header: (props) => ({ props, reportData }),
    heading: (props) => ({ props }),
    "page-break": (props) => ({ props }),
    private: (props) => ({ props }),
    search: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props),
      carryForward: getCarryForwardWithProps(props),
      ...commonProps,
    }),
    spacer: (props) => ({ props }),
    table: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props, "table"),
      carryForward: getCarryForwardWithProps(props, "table"),
      showOptions: false,
      ...dropdownProps,
      ...commonProps,
    }),
    textblock: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props, "textarea"),
      highlight: getHighligthedWithProps(props),
      ...commonProps,
    }),
    "medical-history": (props) => ({
      props,
      medicalHistory: reportData.medical_history,
      displayRisks: isFeatureFlagEnabled("sonio.risk"),
    }),
    value: (props) => ({
      props,
      placeholder: getPlaceholderWithProps(props),
      ...commonProps,
    }),
    "page-footer": (props) => ({ props }),
    "page-header": (props) => ({ props }),
    "identifier-biometry": (props) => ({ props, placeholders, ...commonProps }),
  };

  const calculateReactTree = () => {
    const xmlToReact = new XMLToReact(
      /* transform the list of supported tags to an object as:
       * {
       *    tag: (props) => ({type: tag, props})
       * }
       */
      supportedTags.reduce(
        (acc, tag) => ({
          ...acc,
          [tag]: (props) => ({ type: tag, props }),
        }),
        {}
      )
    );
    return applyType(xmlToReact.convert(templateBlueprint), templateTagToType);
  };

  useEffect(() => {
    if (!templateBlueprint) return;

    setTemplateReactTree(calculateReactTree());
  }, [templateBlueprint]);

  if (reportMode === "edit") {
    const templateReactTree = applyProps(
      plainTemplateReactTree,
      templateTypeToProps,
      everyOneProps
    );
    return templateReactTree;
  }
  if (reportMode === "print") {
    const templateReactTree = applyProps(
      calculateReactTree(),
      templateTypeToProps,
      everyOneProps
    );
    return templateReactTree;
  }
};

export default XMLDocument;
